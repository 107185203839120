import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { InputText } from 'primereact/inputtext'
import { classNames } from 'primereact/utils'
import SkipButton from '../buttons/SkipButton'
import config from '../../../../config'

const initialStateError = { status: false, type: null }

async function getNicknameAvailability(nickname) {
  try {
    let query = '?nickname=' + nickname
    let response = await fetch(`${config.env[config.env.mode].url}/api/master-club-subscribers/check-nickname${query}`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
    response = await response.json()
    return response.message
  } catch (e) {
    console.log(e)
    return e.message
  }
}

async function isNickNameAvailable(nickname, setError) {
  let message = await getNicknameAvailability(nickname)

  if (message !== 'Nickname disponibile') {
    setError({ status: true, type: 'Il nickname non è disponibile' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  setError(initialStateError)
  return true
}

function isAValidInput(nickname, setError) {
  if (nickname === '' || nickname === null || nickname === undefined) {
    setError({ status: true, type: 'Devi inserire un nickname oppure puoi selezionare "salta"' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!/^([\w.-]){3,30}$/.test(nickname)) {
    setError({ status: true, type: 'Il nickname non è valido, deve avere da 3 a 30 caratteri e può contenere lettere, numeri, underscore, trattino e punto' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  return true
}
async function nextPage({ setStep, setData, possibleNickName, setError, isLoading, setIsLoading }) {
  if (isLoading) return
  setIsLoading(true)
  if (isAValidInput(possibleNickName, setError) && (await isNickNameAvailable(possibleNickName, setError))) {
    setData(data => {
      return { ...data, nickname: possibleNickName }
    })
    setStep('WeAreDonePage')
  }
  setIsLoading(false)
}

function skipPage({ setStep }) {
  setStep('WeAreDonePage')
}

export default function NickNamePage({ setStep, setData }) {
  const [isLoading, setIsLoading] = useState(false)
  const [possibleNickName, setPossibleNickName] = useState('')
  const [error, setError] = useState(initialStateError)

  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  var inputClass = classNames('w-9 mx-auto', { 'p-invalid': error.status })

  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'WantToInstertAPicturePage'} />
      <div className="text-4xl my-8 w-9 mx-auto text-center">Vuoi utilizzare un nome di fantasia?</div>
      <InputText className={inputClass} autoComplete="off" id="nickName" type="text" value={possibleNickName} onChange={e => setPossibleNickName(e.target.value)} />
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <SkipButton onClickFunction={skipPage} onCLickData={{ setStep }} />
      <NextButton
        canSkip={true}
        onClickFunction={nextPage}
        onCLickData={{
          setStep,
          setData,
          possibleNickName,
          setError,
          isLoading,
          setIsLoading
        }}
      />
    </div>
  )
}
