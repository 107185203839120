import React, { useState } from 'react'
import avatars from '../../../../assets/images/avatars'
import { Button } from 'primereact/button'
import config from '../../../../config'

const initialStateError = { type: null, message: null }

function isAValidInput(image, setError) {
  if (image === undefined || image === null || image === '') {
    setError({ type: 'imageEmpty', message: 'devi selezionare un avatar' })
    return false
  }
  let [avatar, number] = image.split('-')
  number = +number
  if (avatar !== 'avatar' || number < 1 || number > 16) {
    setError({ type: 'imageEmpty', message: 'devi selezionare un avatar valido' })
    return false
  }
  setError(initialStateError)
  return true
}

async function updateAvatar(avatarSelected, setError, setIsModalOpen) {
  if (!isAValidInput(avatarSelected, setError)) return

  let user = JSON.parse(localStorage.getItem('adoc_p')).user
  let userBody = {
    name: user.name ?? '',
    surname: user.surname ?? '',
    telephone: user.telephone ?? '',
    avatar: `${avatarSelected}.jpg`
  }

  let response = await fetch(`${config.env[config.env.mode].url}/api/profilo-utenti`, {
    method: 'POST',
    body: JSON.stringify(userBody),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
    }
  })
  response = await response.json()
  if (response.message !== 'Succefully updated') throw new Error(response.message)

  let adoc_p = JSON.parse(localStorage.getItem('adoc_p'))
  adoc_p.user = { ...adoc_p.user, avatar: userBody.avatar }
  localStorage.setItem('adoc_p', JSON.stringify(adoc_p))

  setIsModalOpen(false)
}

export default function UpdateUserAvatar({ setIsModalOpen }) {
  const [avatarSelected, setAvatarSelected] = useState(null)
  const [error, setError] = useState(initialStateError)

  return (
    <div className="flex-1 flex-column min-h-65rem flex">
      <section className="grid w-11 mx-auto">
        {avatars?.map(avatar => {
          let styles = { width: '100px', height: '100px', objectFit: 'cover', borderRadius: '100%', cursor: 'pointer' }
          let name = avatar.slice(14, 23)
          if (name === avatarSelected) styles = { ...styles, borderColor: 'var(--pink-500)', borderWidth: '5px', borderStyle: 'solid' }
          return <img key={name} alt={name} src={avatar} className="mx-auto mt-3 mb-2" style={styles} onClick={() => setAvatarSelected(name)} />
        })}
      </section>
      <label className="mx-auto text-center mt-3 h-1rem">{error.message}</label>
      <Button
        rounded
        className="bg-cyan-500 border-cyan-500"
        label="Carica"
        onClick={() => {
          updateAvatar(avatarSelected, setError, setIsModalOpen)
        }}
      />
    </div>
  )
}
