import React, { useState } from 'react'
import NextButton from '../buttons/NextButton'
import BackButton from '../buttons/BackButton'

import { classNames } from 'primereact/utils'
import { Calendar } from 'primereact/calendar'

import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

const initialStateError = { status: false, type: null }

function isAValidInput(birthDate, setError) {
  if (birthDate === null || birthDate === undefined) {
    setError({ status: true, type: 'è necessario inserire la tua data di nascita' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  let eighteenYearsBeforeNow = dayjs().utc(true).subtract(18, 'year').toISOString().slice(0, 10)
  if (birthDate > eighteenYearsBeforeNow) {
    setError({ status: true, type: 'devi avere almeno 18 anni' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, birthDate, setError }) {
  if (isAValidInput(birthDate, setError)) {
    setStep('ImpedimentsPhysicalPage')
  }
}

export default function BirthDatePage({ setStep, birthDate, setData }) {
  const [error, setError] = useState(initialStateError)
  const inputClass = classNames('align-items-center mx-auto w-8 inputNumberActualWeight', { 'p-invalid': error.status })
  const pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage="ActualWeightPage" />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Quale è la tua data di nascita?</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Utilizzeremo questo dato per stabilire con esattezza il tuo peso forma</div>
      <Calendar
        className={inputClass}
        value={birthDate ? new Date(birthDate) : null}
        onChange={e => {
          setData(data => ({
            ...data,
            birth_date: e.value ? dayjs(e.value).utc(true).toISOString().slice(0, 10) : null
          }))
        }}
        id="birthdate"
        dateFormat="dd/mm/yy"
        showIcon
      />
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, birthDate, setError }} />
    </div>
  )
}
