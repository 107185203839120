import React from 'react'

import { Icon } from '@iconify/react'
import { Card } from 'primereact/card'
import { Chart } from 'primereact/chart'

import dayjs from 'dayjs'

const balanceOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.6,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: '#495057'
      }
    }
  },
  scales: {
    x: {
      ticks: {
        color: '#495057'
      },
      grid: {
        color: '#ebedef'
      }
    },
    y: {
      ticks: {
        color: '#495057'
      },
      grid: {
        color: '#ebedef'
      }
    }
  }
}

export default function BalancePanel({ balanceData }) {
  return balanceData.map((v, i) => {
    return (
      <Card
        className="overflow-y-auto w-full mb-3 pt-5"
        key={i}
        title={
          <>
            <Icon
              icon={v.icon}
              width="30"
              style={{
                marginLeft: '-5px',
                marginTop: '-35px'
              }}
              className={'absolute text-' + v.color + '-500'}
            />
            <span
              className="text-xl absolute"
              style={{
                marginTop: '-35px',
                marginLeft: '30px',
                textTransform: 'initial'
              }}>
              {v.datasets[0].label}
            </span>
          </>
        }
        subTitle={
          <span
            className="absolute"
            style={{
              marginTop: '-10px'
            }}>
            {v.labels.length === 0 ? (
              ''
            ) : v.labels.length === 1 ? (
              ''
            ) : (
              <div>
                {(v.datasets[0].data[0] > v.datasets[0].data[v.datasets[0].data.length - 1] ? 'abbasato un ' : 'alzato un ') +
                  ((100 * v.datasets[0].data[v.datasets[0].data.length - 1]) / v.datasets[0].data[0]).toFixed(2) +
                  '% Dal ' +
                  dayjs(v.labels[0]).format('DD/MM/YYYY') +
                  ' al ' +
                  dayjs(v.labels[v.labels.length - 1]).format('DD/MM/YYYY')}
              </div>
            )}
          </span>
        }>
        {v.labels.length === 0 ? (
          <div>Ancora non abbiamo dati disponibili</div>
        ) : v.labels.length === 1 ? (
          <div>{dayjs(v.labels[0]).format('DD/MM/YYYY') + ' : ' + v.datasets[0].data[0] + ' ' + (v.datasets[0].sufix ?? 'cm')}</div>
        ) : (
          <Chart className="min-w-full mt-4" type="line" data={{ ...v, labels: v.labels.map(date => dayjs(date).format('DD/MM/YYYY')) }} options={balanceOptions} />
        )}
      </Card>
    )
  })
}
