import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import BackButton from '../buttons/BackButton'

const initialStateError = { status: false, type: null }
function isAValidInput(userName, setError) {
  if (userName === '' || userName === null || userName === undefined) {
    setError({ status: true, type: 'è necessario inserire un nome' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (!/^(([a-zA-Z]|[À-ÖØ-öø-ÿ Ÿœ])+){3,30}$/.test(userName)) {
    setError({ status: true, type: 'il nome inserito non è valido' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, userName, setError }) {
  if (isAValidInput(userName, setError)) {
    setStep('GenderPage')
  }
}

export default function UserNamePage({ setStep, userName, setData }) {
  const [error, setError] = useState(initialStateError)
  var inputClass = classNames('p-inputtext-lg mx-auto w-8', {
    'p-invalid': error.status
  })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'UserLastNamePage'} />
      <div className=" text-4xl my-8 text-center">Qual è il tuo nome?</div>
      <InputText
        className={inputClass}
        autoComplete="off"
        id="userName"
        type="text"
        value={userName}
        onChange={e =>
          setData(data => {
            return { ...data, name: e.target.value }
          })
        }
      />
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, userName, setError }} />
    </div>
  )
}
