import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { InputNumber } from 'primereact/inputnumber'
import config from '../../../../config'

import countries from '../../../../assets/Countries/CountriesPrefix.json'
import '../../../../assets/Countries/flags.css'

import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { formatPhoneNumberIntl } from 'react-phone-number-input'
import { Dropdown } from 'primereact/dropdown'

const initialStateError = { status: false, type: null }
let setInitialPrefix = phoneNumber => {
  if (phoneNumber === '') return '+39'
  let numberFormated = formatPhoneNumberIntl(phoneNumber)
  let a = numberFormated.split(' ', 1)[0]
  return a
}
let setInitialPhone = phoneNumber => {
  let prefix = setInitialPrefix(phoneNumber)
  if (prefix === '') return null
  let a = phoneNumber.slice(prefix.length)
  return a
}
const selectedCountryTemplate = (option, props) => {
  if (option) {
    return (
      <div className="flex align-items-center">
        <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
        <div>{option.value}</div>
      </div>
    )
  }
  return <span>{props.placeholder}</span>
}
const countryOptionTemplate = option => {
  return (
    <div className="flex align-items-center">
      <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
      <div>{option.value + ' ' + option.name}</div>
    </div>
  )
}

async function sendOTP(possiblePhone) {
  try {
    await fetch(`${config.env[config.env.mode].url}/api/master-club-otp`, {
      method: 'POST',
      body: JSON.stringify({ telephone: possiblePhone }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
  } catch {}
}

function isAValidInput(possiblePhone, setError) {
  if (possiblePhone === '' || possiblePhone === null || possiblePhone === undefined) {
    setError({ status: true, type: 'È richiesto il numero di telefono' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!isPossiblePhoneNumber(possiblePhone)) {
    setError({ status: true, type: 'Il numero di telefono inserito non è valido' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, setError, setData, prefix, nationalPhone }) {
  let possiblePhone = prefix + (nationalPhone ?? '')
  if (isAValidInput(possiblePhone, setError)) {
    sendOTP(possiblePhone)
    setData(data => {
      return { ...data, telephone: possiblePhone }
    })
    setStep('CheckOTPPage')
  }
}

export default function PhonePage({ setStep, telephone, setData, freeRouteStart }) {
  const [prefix, setPrefix] = useState(setInitialPrefix(telephone))
  const [nationalPhone, setNationalPhone] = useState(setInitialPhone(telephone))

  const [error, setError] = useState(initialStateError)
  var inputClass = classNames('w-10', { 'p-invalid': error.status })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={freeRouteStart === undefined || freeRouteStart === '' ? 'FreeOrPaidPage' : 'SelectRoutePage'} />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Inserisci il tuo numero di cellulare</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">
        {freeRouteStart ? 'darà più sicurezza al tuo account' : 'Sarà utilizzato per cercare i tuoi dati di abbonato o paziente aDòc e caricarli nella web app'}
      </div>
      <div className="flex justify-content-center align-items-center">
        <div className="w-2 block justify-content-center pl-0">
          <Dropdown
            inputId="prefix phone number"
            value={prefix}
            onChange={e => setPrefix(e.value)}
            options={countries}
            optionLabel="name"
            filter
            valueTemplate={selectedCountryTemplate}
            itemTemplate={countryOptionTemplate}
            className={inputClass}
            style={{ height: '2.75rem' }}
          />
        </div>
        <div className="pr-0">
          <div className="w-4 flex align-content-center">
            <InputNumber id="national phone number" useGrouping={false} value={nationalPhone} onValueChange={e => setNationalPhone(e.value)} type="text" className={inputClass} />
          </div>
        </div>
      </div>
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, setError, setData, prefix, nationalPhone }} />
    </div>
  )
}
