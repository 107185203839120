module.exports = {
	env: {
		mode: 'stage',
		stage: {
			url: 'https://stage.dietasocial.it',
			node: 'https://api.dietasocial.it:3001',
		},
		local: {
			url: '',
			node: '',
		},
		prod: {
			url: '',
			node: '',
		},
	},
}
