import React from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'

function nextPage({ setStep }) {
  setStep('TypeOfDietPage')
}

export default function WeAreAlmostTherePage({ setStep }) {
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'IntestinalFuncionalityPage'} />
      <div className=" text-4xl mt-8 text-center w-9 mx-auto">{'Ci siamo quasi :)'}</div>
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep }} />
    </div>
  )
}
