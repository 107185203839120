import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

export default function LoggedRoutes() {
  const user = useSelector(state => state.user.user)
  const adoc_t = useSelector(state => state.user.adoc_t)
  const isLogged = () => {
    return adoc_t && user
  }
  if (!isLogged()) {
    return <Navigate to={'/'} replace />
  }

  return <Outlet />
}
