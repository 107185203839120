import { initializeApp } from 'firebase/app'

const firebaseConfig = {
	apiKey: 'AIzaSyDfaCL53O1HLKrP7UVJIxgMTJurelYhtls',
	authDomain: 'adoc-webapp.firebaseapp.com',
	projectId: 'adoc-webapp',
	storageBucket: 'adoc-webapp.appspot.com',
	messagingSenderId: '976024566777',
	appId: '1:976024566777:web:9741790e056c4322215b28',
	measurementId: 'G-Q9CP9FE3Y1',
}

// Initialize Firebase
const firebase = initializeApp(firebaseConfig)
export default firebase
