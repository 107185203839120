import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { Checkbox } from 'primereact/checkbox'

let options = ['Nessuno', 'Dolore alla schiena', 'Dolore alle ginocchia', 'Dolore agli arti superiori (braccia)', 'Dolore agli arti inferiori (gambe)', 'Mobilità molto ridotta']
const initialStateError = { status: false, type: null }

function isAValidInput(physicalImpediments, setError) {
  if (physicalImpediments.length === 0 || physicalImpediments === null || physicalImpediments === undefined) {
    setError({ status: true, type: "Devi selezionare almeno un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (!physicalImpediments.every(option => options.includes(option))) {
    setError({ status: true, type: 'almeno una delle opzioni selezionate non è valida' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, physicalImpediments, setError }) {
  if (isAValidInput(physicalImpediments, setError)) {
    setStep('SleepDurationPage')
  }
}

function setInput(physicalImpediments, setData, e) {
  let _physicalImpediments = [...physicalImpediments]
  if (e.checked) {
    if (e.value === 'Nessuno') {
      _physicalImpediments = ['Nessuno']
    } else {
      _physicalImpediments.push(e.value)
      if (_physicalImpediments.includes('Nessuno')) {
        _physicalImpediments.splice(_physicalImpediments.indexOf('Nessuno'), 1)
      }
    }
  } else {
    _physicalImpediments.splice(_physicalImpediments.indexOf(e.value), 1)
  }
  setData(data => {
    return {
      ...data,
      extras: { ...data.extras, physical_impediments: _physicalImpediments }
    }
  })
}
export default function ImpedimentsPhysicalPage({ setStep, physicalImpediments, setData }) {
  const [error, setError] = useState(initialStateError)
  var inputClass = classNames({ 'p-invalid': error.status })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'BirthDatePage'} />
      <div className=" text-4xl my-8 text-center w-9 mx-auto">Hai impedimenti fisici?</div>
      {options.map((option, index) => {
        return (
          <div key={index} className="my-2 flex align-items-center ml-6 text-2xl">
            <Checkbox
              className={inputClass}
              inputId={'input' + option}
              name={option}
              value={option}
              onChange={e => setInput(physicalImpediments, setData, e)}
              checked={error.status ? false : physicalImpediments.includes(option)}
            />
            <label htmlFor={'input' + option} className="ml-2 capitalizeFirstLetter">
              {option}
            </label>
          </div>
        )
      })}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, physicalImpediments, setError }} />
    </div>
  )
}
