import React from 'react'
import { classNames } from 'primereact/utils'
import BackButton from '../buttons/BackButton'
import { Button } from 'primereact/button'

export default function FreeOrPaidPage({ setStep }) {
  const pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage="WeAreDonePage" />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Cosa vuoi fare?</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Scegli per accedere ai piani alimentari e alle funzioni della nostra web app</div>
      <Button rounded onClick={() => setStep('SelectRoutePage')} className="bg-cyan-500 border-cyan-500 mt-auto mb-6 mx-auto text-3xl w-7 text-center" label="Vorrei provare gratuitamente" />
      <Button rounded onClick={() => setStep('PhonePage')} className="bg-cyan-500 border-cyan-500 mb-auto mt-6 mx-auto text-3xl w-7 text-center" label="Sono un abbonato / paziente aDòc" />
    </div>
  )
}
