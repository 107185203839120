import React from 'react'

import { ProgressSpinner } from 'primereact/progressspinner'

export default function LoadingSPinner({ loading }) {
  if (!loading) return

  return (
    <div className="bg-blue-100 opacity-30 w-screen h-screen fixed top-0 bottom-0 right-0 left-0 z-5 flex align-items-center justify-content-center">
      <ProgressSpinner />
    </div>
  )
}
