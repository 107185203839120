import React, { useState, useEffect } from 'react'

import BalanceDatePickerPage from './BalanceDatePickerPage'
import BalanceOverlay from './BalanceOverlay'

import config from '../../config'

import dayjs from 'dayjs'

function getFetchOptions(dates) {
  let backUrl = config.env[config.env.mode].url
  let body = {
    start: dayjs(dates[0]).utc(true).toISOString().slice(0, 10),
    end: dayjs(dates[1]).utc(true).toISOString().slice(0, 10)
  }
  body = '?' + new URLSearchParams(body)
  let headers = {
    'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
  return { backUrl, body, headers }
}
async function fetchWellnessIndexList({ body, backUrl, headers }) {
  let response = await fetch(`${backUrl}/api/master-club-wellness-index/list${body}`, {
    headers
  })
  response = await response.json()
  return response
}
async function fetchMeasuresList({ body, backUrl, headers }) {
  let response = await fetch(`${backUrl}/api/master-club-measure-diary/list${body}`, {
    headers
  })
  response = await response.json()
  return response
}
async function getBalanceData(balanceDates, setOverlayOpened, setBalanceData) {
  const measures = await fetchMeasuresList(getFetchOptions(balanceDates))
  const balances = [
    {
      icon: 'healthicons:weight',
      value: 'weight',
      labels: [],
      datasets: [
        {
          sufix: 'kg',
          label: 'Peso corporeo',
          data: [],
          fill: false,
          borderColor: '#6366f1',
          tension: 0.4
        }
      ]
    },
    {
      icon: 'game-icons:necklace-display',
      value: 'neck_circumference',
      labels: [],
      datasets: [
        {
          label: 'Circonferenza collo',
          data: [],
          fill: false,
          borderColor: '#ec4899',
          tension: 0.4
        }
      ]
    },
    {
      icon: 'mingcute:body-line',
      value: 'umbilical_circumference',
      labels: [],
      datasets: [
        {
          label: 'Circonferenza ombellicale',
          data: [],
          fill: false,
          borderColor: '#6366f1',
          tension: 0.4
        }
      ]
    },
    {
      icon: 'mingcute:body-fill',
      value: 'middle_thigh_circumference',
      labels: [],
      datasets: [
        {
          label: 'Circonferenza fianchi',
          data: [],
          fill: false,
          borderColor: '#ec4899',
          tension: 0.4
        }
      ]
    },
    {
      icon: 'game-icons:leg',
      value: 'hips_circumference',
      labels: [],
      datasets: [
        {
          label: 'Circonferenza coscia',
          data: [],
          fill: false,
          borderColor: '#6366f1',
          tension: 0.4
        }
      ]
    },
    {
      icon: 'healthicons:arm',
      value: 'arm_circumference',
      labels: [],
      datasets: [
        {
          label: 'Circonferenza braccio',
          data: [],
          fill: false,
          borderColor: '#ec4899',
          tension: 0.4
        }
      ]
    }
  ]

  measures.forEach(measure => {
    for (let property in measure) {
      if (measure[property] !== '0.00') {
        for (let balance of balances) {
          if (balance.value === property) {
            balance.datasets[0].data.push(measure[property])
            balance.labels.push(measure.date)
          }
        }
      }
    }
  })

  setBalanceData(balances)
  setOverlayOpened(true)
}
async function getWellnessData(balanceDates, setWellnessData) {
  const wellnessResponse = await fetchWellnessIndexList(getFetchOptions(balanceDates))

  const wellnessData = [0, 0, 0, 0, 0]
  wellnessResponse.forEach(w => {
    wellnessData[w.index - 1]++
  })

  setWellnessData(wellnessData)
}
function BalancePage() {
  const [overlayOpened, setOverlayOpened] = useState(false)
  const [balanceDates, setBalanceDates] = useState([])
  const [wellnessData, setWellnessData] = useState([])
  const [balanceData, setBalanceData] = useState([])

  useEffect(() => {
    if (!balanceDates[0] || !balanceDates[1]) return
    getBalanceData(balanceDates, setOverlayOpened, setBalanceData)
    getWellnessData(balanceDates, setWellnessData)
  }, [balanceDates])

  if (!overlayOpened) return <BalanceDatePickerPage setBalanceDates={setBalanceDates} balanceDates={balanceDates} />

  const fromTo = 'Dal ' + dayjs(balanceDates[0]).format('DD/MM/YYYY') + ' al ' + dayjs(balanceDates[1]).format('DD/MM/YYYY')

  return <BalanceOverlay fromTo={fromTo} setOverlayOpened={setOverlayOpened} balanceData={balanceData} wellnessData={wellnessData} />
}

export { BalancePage }
