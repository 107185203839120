import { Icon } from '@iconify/react'
import { Inplace, InplaceContent, InplaceDisplay } from 'primereact/inplace'
import { InputNumber } from 'primereact/inputnumber'
import React, { useEffect, useState } from 'react'
import './styles.css'

function isToday(date) {
  return date.toDateString() === new Date().toDateString()
}

export default function Measure({ setMeasuresData, measure, measuresData, date }) {
  return (
    <div className={measure.measuresDiaryClass}>
      <div className="m-3">
        <div className="flex justify-content-between mb-3">
          <div className="mr-4">
            <span className="block text-500 font-medium mb-3">{measure.title}</span>
            <Inplace closable={isToday(date)} active={!isToday(date) || measuresData[measure.state_name] > 0}>
              <InplaceDisplay className={'p-button bg-' + measure.color + '-500 border-' + measure.color + '-500'}>Aggiungi</InplaceDisplay>
              <InplaceContent>
                <InputNumber
                  disabled={!isToday(date)}
                  min={0}
                  allowEmpty={false}
                  className="inputNumberMeasureDiary"
                  inputId="locale-german"
                  value={measuresData[measure.state_name]}
                  onValueChange={e =>
                    setMeasuresData(oldData => {
                      return { ...oldData, [measure.state_name]: e.value }
                    })
                  }
                  suffix={' ' + measure.measure}
                  mode="decimal"
                  locale="de-DE"
                  minFractionDigits={2}
                />
              </InplaceContent>
            </Inplace>
          </div>
          <div className={'flex align-items-center justify-content-center border-round w-6rem h-6rem bg-' + measure.color + '-100'}>
            <Icon icon={measure.icon} width="60" className={'text-' + measure.color + '-500'} />
          </div>
        </div>
      </div>
    </div>
  )
}
