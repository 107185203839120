import React, { useEffect, useState } from 'react'
import { Divider } from 'primereact/divider'
import { Subscription } from './subscription'
import config from '../../../config'

function SubscriptionsPage() {
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    const dietaSocialId = JSON.parse(localStorage.getItem('adoc_p')).master_club_subscriber.dieta_social_id
    if (dietaSocialId) {
      fetch(`${config.env[config.env.mode].node}/order/${dietaSocialId}`, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(resp => resp.json())
        .then(json => setSubscriptions(json))
    }
  }, [])

  return (
    <>
      <div className="text-900 font-semibold text-lg">Abbonamenti</div>
      <Divider></Divider>
      {subscriptions?.map((subscription, index) => {
        return (
          <div key={index}>
            <Subscription subscription={subscription} />
            <Divider />
          </div>
        )
      })}
    </>
  )
}

export { SubscriptionsPage }
