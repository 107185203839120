import React, { useEffect } from 'react'
import logo from '../../../assets/images/logo-dieta-social.svg'
import { useParams } from 'react-router-dom'
import config from '../../../config'
import { useDispatch } from 'react-redux'
import { setAdoc_p, setAdoc_t } from '../../../features/redux/reducer/userSlice'

function GetTokenPage() {
  const dispatch = useDispatch()
  let { token } = useParams()
  try {
    token = window.atob(token.includes('&') ? token.split('&')[0] : token)
  } catch {
    token = null
    window.location.href = '/'
  }

  useEffect(() => {
    if (token) {
      fetch(`${config.env[config.env.mode].url}/api/profilo-utenti/1/fetch `, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
        .then(resp => resp.json())
        .then(json => {
          if (json.message === 'Unauthenticated.') throw new Error(json.message)
          localStorage.setItem('adoc_t', token)
          localStorage.setItem('adoc_p', JSON.stringify(json))
          dispatch(setAdoc_p(json))
          dispatch(setAdoc_t(token))
          return json
        })
        .then(json => {
          window.location.href = json?.master_club_subscriber.last_login ? '/#/home' : '/#/impostazioni-iniziali'
        })
        .catch(_ => (window.location.href = '/'))
    }
  }, [dispatch, token])

  return (
    <>
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6" style={{ height: '40vh' }}>
          <div className="text-center mb-5">
            <img src={logo} alt="hyper" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">accedendo al tuo account...</div>
          </div>
        </div>
      </div>
    </>
  )
}

export { GetTokenPage }
