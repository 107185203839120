import React, { useEffect, useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import config from '../../../../config'

const initialStateError = { type: null, message: null }
const maxSizeBites = 1048576

function isAValidInput(image, setError) {
  if (image === undefined || image === null) {
    setError({ type: 'imageEmpty', message: 'Devi inserire un file valido' })
    return false
  }
  return image.size <= maxSizeBites
}

async function uploadImage(image, setError, setStep, setData) {
  try {
    const body = new FormData()
    body.append('utenti', image)

    let response = await fetch(`${config.env[config.env.mode].url}/api/file-upload/utenti`, {
      body: body,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
    response = await response.json()
    if (response.code !== 200 || response.status !== 'success') throw new Error(response.message)
    setData(data => {
      return { ...data, avatar: response.filename }
    })
    setStep('NickNamePage')
  } catch (e) {
    setError({ type: 'api error', message: e.message })
  }
}

async function setPage({ image, setError, setStep, setData }) {
  if (isAValidInput(image, setError)) {
    await uploadImage(image, setError, setStep, setData)
  }
}

export default function InstertAPicturePage({ setStep, setData }) {
  const [image, setImage] = useState(null)
  const [preview, setPreview] = useState(null)
  const [error, setError] = useState(initialStateError)
  let pageClass = classNames('flex-1 flex-column min-h-full flex')

  useEffect(() => {
    if (!image) {
      setPreview(null)
      return
    }
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreview(reader.result.toString())
    }
    reader.readAsDataURL(image)
  }, [image])
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'WantToInstertAPicturePage'} />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Inserisci la tua foto</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Deciderai in un secondo momento se la vorrai rendere visibile agli altri</div>
      <label htmlFor="image_uploads" className="mx-auto p-2 p-button bg-cyan-300 border-cyan-300 p-button-rounded">
        Seleziona il file
      </label>
      <input
        style={{ opacity: 0 }}
        id="image_uploads"
        accept="image/png,image/jpeg"
        type="file"
        onChange={e => {
          if (e.target.files[0] === undefined) {
            setError(initialStateError)
            setImage(null)
            return
          }
          if (e.target.files.length > 1) {
            setError({ type: 'multipleElements', message: 'Devi caricare un solo file' })
            setImage(null)
            return
          }
          if (e.target.files[0].size > maxSizeBites) {
            setError({ type: 'maxSize', message: 'Il file supera la dimensione massima di 1 MB' })
            setImage(null)
            return
          }
          if (error) setError(initialStateError)
          setImage(e.target.files[0])
        }}
      />
      {error.type && <label className="mx-auto text-center mt-3">{error.message}</label>}
      {preview && <img alt="profileImage" src={preview} className="mx-auto mt-3 mb-2" style={{ width: '250px', height: '250px', objectFit: 'cover', borderRadius: '100%' }} />}
      <NextButton onClickFunction={setPage} onCLickData={{ image, setError, setStep, setData }} />
    </div>
  )
}
