import React from 'react'
import { Divider } from 'primereact/divider'
import { Ripple } from 'primereact/ripple'
import productImg from '../../../assets/images/prodotto_12_mesi.jpg'
import dayjs from 'dayjs'

function Subscription({ subscription }) {

    let mesi = ["gennaio","febbraio","marzo","aprile","maggio","giugno","luglio","agosto","settembre","ottobre","novembre","dicembre"]
    let subscriptionDuration =(dayjs(subscription.data_fine) - dayjs(subscription.data_inizio)) / 1000 / 60 / 60 / 24
	return (
    <div className="surface-card grid grid-nogutter border-round shadow-2">
		<div className="col-12 flex p-2 surface-100 border-round-top">
			<div className="p-2 flex-auto text-center md:text-left">
				<span className="text-600 block">Ordine numero</span>
				<span className="text-900 font-medium block mt-2">{subscription.id_abbonamento}</span>
			</div>
			<Divider
				align="center"
				layout="vertical"
				className="h-full mx-0 lg:mx-3"
			/>
			<div className="p-2 flex-auto text-center md:text-left">
				<span className="text-600 block">Data inizio</span>
				<span className="text-900 font-medium block mt-2">
                    {subscription.data_inizio.split('-').reverse().map((data, index) => index=== 1 ? mesi[data-1] : ` ${data} `)}
				</span>
			</div>
			<Divider
				align="center"
				layout="vertical"
				className="h-full mx-0 lg:mx-3"
			/>
			<div className="p-2 flex-auto text-center md:text-left">
				<span className="text-600 block">Data fine</span>
				<span className="text-900 font-medium block mt-2">
                    {subscription.data_fine.split('-').reverse().map((data, index) => index=== 1 ? mesi[data-1] : ` ${data} `)}
				</span>
			</div>
			<Divider
				align="center"
				layout="vertical"
				className="h-full mx-0 lg:mx-3"
			/>
			<div className="p-2 flex-auto text-center md:text-left">
				<span className="text-600 block">Totale pagato</span>
				<span className="text-900 font-medium block mt-2">
                    {`${subscription.pagamento? subscription.pagamento.importo : subscription.costo} $`}
                </span>
			</div>
		</div>
		<div className="col-12">
			<div className="p-2 my-4 flex flex-column lg:flex-row justify-content-between align-items-center">
				<div className="flex flex-column lg:flex-row justify-content-center align-items-center px-2">
					<img
						src={productImg}
						alt="product"
						className="w-8rem h-8rem mr-3 flex-shrink-0"
					/>
					<div className="flex flex-column my-auto text-center md:text-left">
						<span className="text-900 font-medium mb-3 mt-3 lg:mt-0">
							Abbonamento a {subscription.nome}
						</span>
						<span className="text-600 text-sm mb-3">12 mesi</span>
						<a
                            href='/'
							tabIndex="0"
							className="p-ripple p-2 cursor-pointer mx-auto lg:mx-0 border-round font-medium text-center border-1 border-primary text-primary transition-duration-150">
							Upgrade a 24 mesi <span className="font-light">| €59,99</span>
							<Ripple />
						</a>
					</div>
				</div>
				<div
					className="bg-green-50 mr-0 lg:mr-3 mt-4 lg:mt-0 p-2 flex align-items-center"
					style={{ borderRadius: '2.5rem' }}>
					<span
						className="bg-green-500 text-white flex align-items-center justify-content-center border-circle mr-2"
						style={{ minWidth: '2rem', minHeight: '2rem' }}>
						<i className="pi pi-check"></i>
					</span>
					<span className="text-green-500">
						Data acquisto 7 Gennario 2023
					</span>
				</div>
			</div>
		</div>
		<div className="col-12 p-0 flex border-top-1 surface-border">
			<a
                href='/'
				tabIndex="0"
				className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full">
				<i className="pi pi-refresh mr-2 mb-2 md:mb-0"></i>Disdettare
			</a>
			<a
                href='/'
				tabIndex="0"
				className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full">
				<i className="pi pi-file mr-2 mb-2 md:mb-0"></i>Fattura
			</a>
			<a
                href='/'
				tabIndex="0"
				className="cursor-pointer py-4 flex flex-column md:flex-row text-center justify-content-center align-items-center text-primary hover:bg-primary hover:text-0 transition-duration-150 w-full"
				style={{ borderBottomRightRadius: '6px' }}>
				<i className="pi pi-comment mr-2 mb-2 md:mb-0"></i>Segnala un
				problema
			</a>
		</div>
	</div>
	)
}

export { Subscription }
