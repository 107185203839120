import React, { useEffect, useState } from 'react'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import './styles.css'

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}

function isUsingDietaSocial() {
  if ('serviceWorker' in navigator && 'caches' in window) {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      return true
    }
  }

  return false
}

function UseDeAppInstalled() {
  const [close, setClose] = useState(false)

  if (close) return

  return (
    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 98 }} className="w-screen h-screen fixed top-0 bottom-0 right-0 left-0 flex align-items-center justify-content-center">
      <Card className="w-11 UseDeAppInstalled" title="App Installatta">
        {isMobileDevice() ? (
          <h3 className="mt-0">Per una migliore sperienza consigliamo aprire l'app</h3>
        ) : (
          <div className="flex justify-content-between">
            <h3 className="mt-0">Per una migliore sperienza consigliamo aprire l'app</h3>
            <Button
              rounded
              text
              raised
              icon="pi pi-times"
              onClick={() => {
                setClose(true)
              }}
            />
          </div>
        )}
        {isMobileDevice() && (
          <Button
            onClick={() => {
              window.open('https://pwa.dietasocial.it', '_blank')
            }}
            className="mt-auto"
            label="Aprire"
          />
        )}
      </Card>
    </div>
  )
}

function InstallingDietaSocial() {
  const [close, setClose] = useState(false)
  if (close) return

  return (
    <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 98 }} className="w-screen h-screen fixed top-0 bottom-0 right-0 left-0 flex align-items-center justify-content-center">
      <Card className="w-11 UseDeAppInstalled" title="Installando App">
        {isMobileDevice() ? (
          <h3 className="mt-0">Per una migliore sperienza consigliamo aprire l'app</h3>
        ) : (
          <div className="flex justify-content-between">
            <h3 className="mt-0">Per una migliore sperienza consigliamo aprire l'app</h3>
            <Button
              rounded
              text
              raised
              icon="pi pi-times"
              onClick={() => {
                setClose(true)
              }}
            />
          </div>
        )}
        <Button
          onClick={() => {
            window.open('https://pwa.dietasocial.it', '_blank')
          }}
          className="mt-auto"
          disabled={true}
        />
      </Card>
    </div>
  )
}

function GoToInstalled() {
  const [isInstalling, setIsInstalling] = useState(false)
  const [isUsing, setIsUsing] = useState(isUsingDietaSocial())

  useEffect(() => {
    if (isUsingDietaSocial()) return

    const handleAppInstalled = () => {
      setIsInstalling(true)
      // al escuchar el evento de que se esta instalando, se setea que se esta instalando
      const intervalCheckAppIsInstalled = setInterval(() => {
        //se crea un interval para que cada segundo verifique si la aplicacion se esta usando

        // window.open('https://pwa.dietasocial.it', '_blank')
        const newIsUsing = isUsingDietaSocial()
        if (newIsUsing) {
          setIsUsing(newIsUsing)
          setIsInstalling(false)
          clearInterval(intervalCheckAppIsInstalled)
          window.removeEventListener('appinstalled', handleAppInstalled)
        }
      }, 1000)
      // si luego de 13 segundos no ha ido a la aplicacion, la abrimos automaticamente
      setTimeout(() => {
        if (!isUsingDietaSocial()) {
          window.open('https://pwa.dietasocial.it', '_blank')
        }
      }, 13000)
    }

    window.addEventListener('appinstalled', handleAppInstalled)
  }, [])

  if (isInstalling) return <InstallingDietaSocial />

  if (!isUsing) return <UseDeAppInstalled />
}

const GoToInstalledMemorized = React.memo(GoToInstalled)

export { GoToInstalledMemorized as GoToInstalled }
