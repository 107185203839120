import React from 'react'
import { Card } from 'primereact/card'
import kiwi from '../../assets/images/kiwi.png'
import { Link } from 'react-router-dom'

let array = [0,1,2,3,4,5,6,7]
function NewsPage() {
	const header = (
		<img
			alt="Card"
			src={kiwi}
		/>
	)

	return (
		<>
			<div className="page-wrapper">
				<div className="col-12">
					<Card
						className="m-3"
						title="Magazine"></Card>

					<div className="grid m-0 p-3">
						{
							array.map(index => { return (
								<div key={index} className="md:col-3 mb-2">
									<Card
										title="Kiwi: la tua dose giornaliera di vitamina C"
										header={header}>
										<Link className="no-underline text-color" to={`${index}`} relative="path">
											Il Kiwi è una bacca molto particolare e gustosa prodotta dalla
											pianta* Actinidia deliciosa * tipica dell’Asia. Attualmente
											abbiamo...
										</Link>
									</Card>
								</div>)
							})
						}
					</div>
				</div>
			</div>
		</>
	)
}

export { NewsPage }
