import { Button } from "primereact/button"
import React from "react"

export default function BackButton({setStep, prevPage}) {
    return (
        <div className="w-full">
            <Button
            icon="pi pi-arrow-left"
            rounded text severity="secondary"
            aria-label="Bookmark"
            onClick={()=> setStep(prevPage)}
            />
        </div>
    )
}
