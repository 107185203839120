import React from 'react'
import { Icon } from '@iconify/react'

function Heading(props) {
	return (
		<>
			<div
				className="border-primary text-color bg-isometric shadow-1 px-3 sm:px-5 py-4 grid mx-0"
				style={{ borderLeft: '8px solid' }}
			>
				<Icon
					icon={props.icon}
					className="text-primary mr-1 p-0 col-2 sm:col-1"
					width="60"
				/>
				<div className="p-0 col-9 sm:col-10">
					<h2 className="m-0">
						<span className="mb-1">{props.title}</span>
					</h2>
					<p className="m-0">{props.description}</p>
				</div>
			</div>
		</>
	)
}

export { Heading }
