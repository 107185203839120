import { createSlice } from '@reduxjs/toolkit'
import { getUserInfo, postWellnessIndex, postNotificationsSubscription } from '../actions'

const adoc_p = localStorage.getItem('adoc_p')
const userState = adoc_p ? JSON.parse(adoc_p).user : null
const masterClubState = adoc_p ? JSON.parse(adoc_p).master_club_subscriber : null
const adoc_t = localStorage.getItem('adoc_t') ?? null

const initialState = {
  user: userState,
  master_club_subscriber: masterClubState,
  adoc_t,
  getUserIsLoading: false,
  getUserError: false,
  postWellnessIndex: {
    error: null,
    isLoading: null,
    completed: false
  },
  postNotificationsSubscription: {
    error: null,
    isLoading: null,
    completed: false
  }
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAdoc_t: (state, action) => {
      state.adoc_t = action.payload
    },
    setAdoc_p: (state, action) => {
      state.user = action.payload.user
      state.master_club_subscriber = action.payload.master_club_subscriber
    },
    resetPostWellnessIndexError: state => {
      state.postWellnessIndex.error = false
    },
    LogoutUser: state => {
      state.user = null
      state.master_club_subscriber = null
      state.adoc_t = null
      localStorage.removeItem('adoc_p')
      localStorage.removeItem('_i')
      localStorage.removeItem('adoc_t')
    }
  },
  extraReducers: builder => {
    builder.addCase(getUserInfo.pending, state => {
      state.getUserIsLoading = true
      state.getUserError = false
    })
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.getUserIsLoading = false
      state.getUserError = false
      state.user = action.payload.user
      state.master_club_subscriber = action.payload.master_club_subscriber
      localStorage.setItem('adoc_p', JSON.stringify(action.payload))
    })
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.getUserIsLoading = false
      state.getUserError = action.error.message
    })
    builder.addCase(postWellnessIndex.pending, state => {
      state.postWellnessIndex.isLoading = true
      state.postWellnessIndex.error = false
    })
    builder.addCase(postWellnessIndex.fulfilled, (state, action) => {
      state.postWellnessIndex.isLoading = false
      state.postWellnessIndex.error = false
      state.postWellnessIndex.completed = true
      state.master_club_subscriber.wellness_index = { date: new Date().toISOString().slice(0, 10) }
      localStorage.setItem('adoc_p', JSON.stringify({ master_club_subscriber: state.master_club_subscriber, user: state.user }))
    })
    builder.addCase(postWellnessIndex.rejected, (state, action) => {
      state.postWellnessIndex.isLoading = false
      state.postWellnessIndex.error = action.error.message
    })

    builder.addCase(postNotificationsSubscription.pending, state => {
      state.postNotificationsSubscription.isLoading = true
      state.postNotificationsSubscription.error = false
    })
    builder.addCase(postNotificationsSubscription.fulfilled, (state, action) => {
      state.postNotificationsSubscription.isLoading = false
      state.postNotificationsSubscription.error = false
      state.postNotificationsSubscription.completed = true
      const newSubscription = { subscription_key: JSON.stringify(action.meta.arg) }
      state.master_club_subscriber.push_subscriptions = [...(state.master_club_subscriber.push_subscriptions || []), newSubscription]
      localStorage.setItem('adoc_p', JSON.stringify({ master_club_subscriber: state.master_club_subscriber, user: state.user }))
    })
    builder.addCase(postNotificationsSubscription.rejected, (state, action) => {
      state.postNotificationsSubscription.isLoading = false
      state.postNotificationsSubscription.error = action.error.message
    })
  }
})

export const { setAdoc_t, setAdoc_p, resetPostWellnessIndexError, LogoutUser } = userSlice.actions
export default userSlice.reducer
