import { createSlice } from '@reduxjs/toolkit'
import { getPushSettings, pushUserSettings } from '../actions'

const initialState = {
  pushUserSettings: {
    isLoading: false,
    error: false
  },
  getPushSettings: {
    data: null,
    isLoading: false,
    error: false
  }
}

const pushSettingsSlice = createSlice({
  name: 'pushSettings',
  initialState,
  reducers: {
    resetGetPushSettingsError: state => {
      state.getPushSettings.error = false
    },
    resetPushUserSettingsError: state => {
      state.pushUserSettings.error = false
    }
  },
  extraReducers: builder => {
    builder.addCase(getPushSettings.pending, state => {
      state.getPushSettings.isLoading = true
      state.getPushSettings.error = false
    })
    builder.addCase(getPushSettings.fulfilled, (state, action) => {
      state.getPushSettings.isLoading = false
      state.getPushSettings.error = false
      state.getPushSettings.data = action.payload
    })
    builder.addCase(getPushSettings.rejected, (state, action) => {
      state.getPushSettings.isLoading = false
      state.getPushSettings.error = action.error.message
    })

    builder.addCase(pushUserSettings.pending, state => {
      state.pushUserSettings.isLoading = true
      state.pushUserSettings.error = false
    })
    builder.addCase(pushUserSettings.fulfilled, (state, action) => {
      state.pushUserSettings.isLoading = false
      state.pushUserSettings.error = false
      state.getPushSettings.data = action.payload.newSettings
    })
    builder.addCase(pushUserSettings.rejected, (state, action) => {
      console.log(action.error.message)
      state.pushUserSettings.isLoading = false
      state.pushUserSettings.error = action.error.message
    })
  }
})

export const { resetGetPushSettingsError, resetPushUserSettingsError } = pushSettingsSlice.actions
export default pushSettingsSlice.reducer
