import React, { useState } from 'react'
import { Button } from 'primereact/button'
import userAvatar from '../../../../assets/images/user-avatar.png'
import config from '../../../../config'
import ModalUpdateUserAvatar from './modalUpdateUserAvatar'

export default function ProfileUserAvatar() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const avatar = JSON.parse(localStorage.getItem('adoc_p')).user.avatar

  return (
    <div className="flex flex-column align-items-center flex-or">
      <span className="font-medium text-900 mb-2">Foto di profilo</span>
      <img
        onError={({ currentTarget }) => {
          currentTarget.onerror = null
          currentTarget.src = userAvatar
        }}
        src={avatar ? `${config.env[config.env.mode].url}/storage/utenti/${avatar}` : userAvatar}
        alt={''}
        className="h-10rem w-10rem"
        style={{ objectFit: 'cover', borderRadius: '100%' }}
      />
      <Button onClick={() => setIsModalOpen(true)} type="button" icon="pi pi-pencil" className="p-button-rounded -mt-4"></Button>
      <ModalUpdateUserAvatar isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  )
}
