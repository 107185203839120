import React from 'react'
import './styles.css'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import { format } from 'timeago.js'
dayjs.extend(customParseFormat)
dayjs.extend(relativeTime)

export default function UserNotification({ notification }) {
  return (
    <div className="col-12 lg:col-4 p-3">
      <div className="shadow-2 border-round surface-50 mb-3 h-full justify-content-between flex">
        <div className="p-4">
          {/* <small>{dayjs(notification.created_at).format('DD/MM/YYYY')}</small> */}
          <div className="text-900 my-3 text-xl font-medium">{notification.title}</div>
          <p className="mt-0 mb-2 text-700 line-height-3 notification-description" dangerouslySetInnerHTML={{ __html: notification.description }} />
          <small style={{ color: '#b1b1b1', fontStyle: 'italic', margin: '10px 0' }}>{format(notification.created_at, 'itLocale')}</small>
        </div>
      </div>
    </div>
  )
}
