import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import WellnessIndex from './wellnessIndex'

import { Button } from 'primereact/button'
import { newSubscribeUser } from '../../serviceWorkerRegistration'

function HomePage() {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      setTimeout(() => {
        newSubscribeUser()
      }, 500)
    }
  }, [])

  return (
    <>
      <WellnessIndex />
      <div className="page-wrapper flex justify-content-center">
        {/* <div className="grid grid-nogutter surface-0 text-800 h-screen"> */}
        <div className="col-12 md:col-6 p-3 sm:p-6 text-center md:text-left flex align-items-center ">
          <section>
            <span className="block text-5xl sm:text-6xl font-bold mb-1">Dimagrire insieme</span>
            <div className="text-5xl sm:text-6xl text-primary font-bold mb-3">La forza del gruppo</div>
            <p className="mt-0 mb-4 text-700 line-height-3">
              Ogni percorso Dieta Social® nasce e vive su <b>Facebook</b>, allo scopo di permettere a tutti i partecipanti di <b>scambiarsi opinioni</b>, pareri e{' '}
              <b>condividere gli stessi obiettivi</b>. Fondamentale è supportarsi a vicenda per il raggiungimento del proprio peso-forma, ma anche seguire quotidianamente gli approfondimenti degli{' '}
              <b>esperti</b> che compongono il <b>Team Dieta Social®.</b>
            </p>

            <NavLink to="/magazine">
              <Button label="Leggi il magazine" type="button" className="mr-3 p-button-raised" />
            </NavLink>
          </section>
        </div>
        <div className="hidden sm:inline col-12 md:col-6 overflow-hidden">
          <img
            src="https://www.dietasocial.it/images/gruppoNapoli.jpeg"
            alt="hero-1"
            className="md:ml-auto  md:h-full hidden sm:block"
            style={{
              clipPath: 'polygon(8% 0, 100% 0%, 100% 100%, 0 100%)'
            }}
          />
        </div>
      </div>
    </>
  )
}

export { HomePage }
