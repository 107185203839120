import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { Checkbox } from 'primereact/checkbox'

const initialStateError = { status: false, type: null }
let options = ['Dimagrire facilmente', 'Mantenermi in salute', 'Imparare a mangiare', 'Migliorare le mie analisi']

function isAValidInput(goals, setError) {
  if (goals.length === 0 || goals === null || goals === undefined) {
    setError({ status: true, type: "Devi selezionare almeno un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (!goals.every(option => options.includes(option))) {
    setError({ status: true, type: 'almeno una delle opzioni selezionate non è valida' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, goals, setError }) {
  if (isAValidInput(goals, setError)) {
    setStep('FoodPreferencePage')
  }
}
function setInput(goals, setData, e) {
  let _goals = [...goals]
  if (e.checked) {
    _goals.push(e.value)
  } else {
    _goals.splice(_goals.indexOf(e.value), 1)
  }
  setData(data => {
    return { ...data, extras: { ...data.extras, goals: _goals } }
  })
}
export default function GoalsPage({ setStep, goals, setData }) {
  const [error, setError] = useState(initialStateError)
  var inputClass = classNames({ 'p-invalid': error.status })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'GenderPage'} />
      <div className=" text-4xl my-8 text-center w-9 mx-auto">Cosa ti piacerebbe ottenere con Dieta Social?</div>
      {options.map((option, index) => {
        return (
          <div key={index} className="my-2 flex align-items-center ml-6 text-2xl">
            <Checkbox
              className={inputClass}
              inputId={'input' + option}
              name={option}
              value={option}
              onChange={e => setInput(goals, setData, e)}
              checked={error.status ? false : goals.includes(option)}
            />
            <label htmlFor={'input' + option} className="ml-2 capitalizeFirstLetter">
              {option}
            </label>
          </div>
        )
      })}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, goals, setError }} />
    </div>
  )
}
