import React, { useEffect, useState } from 'react'
import UserNamePage from './steps/UserNamePage'
import UserLastNamePage from './steps/UserLastNamePage'
import GenderPage from './steps/GenderPage'
import GoalsPage from './steps/GoalsPage'
import ActualWeightPage from './steps/ActualWeightPage'
import GoalWeightPage from './steps/GoalWeightPage'
import HeightPage from './steps/HeightPage'
import BirthDatePage from './steps/BirthDatePage'
import ImpedimentsPhysicalPage from './steps/ImpedimentsPhysicalPage'
import SleepDurationPage from './steps/SleepDurationPage'
import IntestinalFuncionalityPage from './steps/IntestinalFuncionalityPage'
import WeAreAlmostTherePage from './steps/WeAreAlmostTherePage'
import TypeOfDietPage from './steps/TypeOfDietPage'
import SelectProvincePage from './steps/SelectProvincePage'
import SelectCityPage from './steps/SelectCityPage'
import CookingPreferencesPage from './steps/CookingPreferencesPage'
import EatingOutPage from './steps/EatingOutPage'
import MotivationPage from './steps/MotivationPage'
import WantToInstertAPicturePage from './steps/WantToInstertAPicturePage'
import InstertAPicturePage from './steps/InstertAPicturePage'
import NickNamePage from './steps/NickNamePage'
import WeAreDonePage from './steps/WeAreDonePage'
import FreeOrPaidPage from './steps/FreeOrPaidPage'
import SelectRoutePage from './steps/SelectRoutePage'
import PhonePage from './steps/PhonePage'
import CheckOTPPage from './steps/CheckOTPPage'
import FoodPreferencePage from './steps/FoodPreferencePage'
import config from '../../../config'
import SelectAAvatarPage from './steps/SelectAAvatarPage'

let initialStateData = {
  birth_date: null,
  free_route_start: '',
  initial_weight: 0,
  keep_weight: false,
  gluten_free: false,
  province_id: null,
  vegetarian: false,
  city_id: null,
  diet_id: null,
  telephone: '',
  surname: '',
  avatar: '',
  height: 0,
  name: '',
  sex: '',
  extras: {
    physical_impediments: [],
    weight_goal: 0,
    eating_out: '',
    motivation: '',
    intestine: '',
    cooking: '',
    sleep: '',
    goals: []
  }
}

function removeUnnecesaryKeys(data) {
  let _data = { ...data }
  delete _data.telephone
  delete _data.name
  delete _data.surname
  delete _data.avatar
  delete _data.subscription
  if (_data.free_route_start === null || _data.free_route_start === undefined || _data.free_route_start === '') delete _data.free_route_start

  if (_data.nickname === null || _data.nickname === undefined || _data.nickname === '') delete _data.nickname

  return _data
}
async function sendProfileData(data) {
  try {
    let backUrl = config.env[config.env.mode].url
    let headers = {
      'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    let body = {
      telephone: data.telephone,
      name: data.name,
      surname: data.surname
    }
    if (data.avatar !== undefined && data.avatar !== null && data.avatar !== '') {
      body.avatar = data.avatar
    }
    body = JSON.stringify(body)

    let response = await fetch(`${backUrl}/api/profilo-utenti`, { method: 'POST', body, headers })
    response = await response.json()

    return response.message
  } catch (e) {
    return e.message
  }
}
async function sendUserData(data) {
  try {
    let backUrl = config.env[config.env.mode].url
    let headers = {
      'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    let _data = removeUnnecesaryKeys(data)
    let body = JSON.stringify(_data)

    let response = await fetch(`${backUrl}/api/master-club-subscribers/1`, {
      method: 'PATCH',
      body,
      headers
    })
    response = await response.json()
    return response.message
  } catch (e) {
    return e.message
  }
}
async function updateUser() {
  try {
    let backUrl = config.env[config.env.mode].url
    let headers = {
      'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    let response = await fetch(`${backUrl}/api/profilo-utenti/1/fetch`, { headers })
    response = await response.json()

    if (response.message === 'Unauthenticated.') {
      console.log(response.message)
      return
    }
    localStorage.setItem('adoc_p', JSON.stringify(response))
  } catch (e) {
    console.log(e.message)
  }
}
async function sendData(data, setDataCompleted) {
  try {
    let resultSendProfileData = await sendProfileData(data)
    if (resultSendProfileData !== 'Succefully updated') {
      setDataCompleted(false)
      // mandar error al toast
      return
    }

    let resultSendUserData = await sendUserData(data)
    if (resultSendUserData !== 'Succefully updated') {
      setDataCompleted(false)
      // mandar error al toast
      return
    }

    await updateUser()

    setTimeout(() => {
      window.location.href = '/#/home'
    }, 10)
  } catch (e) {
    console.log(e)
  }
}

function InitialConfigPage() {
  const [dataCompleted, setDataCompleted] = useState(false)
  const [step, setStep] = useState('UserLastNamePage')
  const [data, setData] = useState(initialStateData)

  useEffect(() => {
    if (dataCompleted) {
      sendData(data, setDataCompleted)
    }
  }, [data, dataCompleted])

  switch (step) {
    case 'UserLastNamePage':
      return <UserLastNamePage setStep={setStep} setData={setData} surname={data.surname} />
    case 'UserNamePage':
      return <UserNamePage setStep={setStep} setData={setData} userName={data.name} />
    case 'GenderPage':
      return <GenderPage setStep={setStep} setData={setData} gender={data.sex} />
    case 'GoalsPage':
      return <GoalsPage setStep={setStep} setData={setData} goals={data.extras.goals} />
    case 'FoodPreferencePage':
      return <FoodPreferencePage setStep={setStep} setData={setData} data={data} />
    case 'GoalWeightPage':
      return <GoalWeightPage setStep={setStep} setData={setData} goalWeight={data.extras.weight_goal} />
    case 'HeightPage':
      return <HeightPage setStep={setStep} setData={setData} height={data.height} />
    case 'ActualWeightPage':
      return <ActualWeightPage setStep={setStep} setData={setData} actualWeight={data.initial_weight} />
    case 'BirthDatePage':
      return <BirthDatePage setStep={setStep} setData={setData} birthDate={data.birth_date} />
    case 'ImpedimentsPhysicalPage':
      return <ImpedimentsPhysicalPage setStep={setStep} setData={setData} physicalImpediments={data.extras.physical_impediments} />
    case 'SleepDurationPage':
      return <SleepDurationPage setStep={setStep} setData={setData} sleep={data.extras.sleep} />
    case 'IntestinalFuncionalityPage':
      return <IntestinalFuncionalityPage setStep={setStep} setData={setData} intestine={data.extras.intestine} />
    case 'WeAreAlmostTherePage':
      return <WeAreAlmostTherePage setStep={setStep} />
    case 'TypeOfDietPage':
      return <TypeOfDietPage setStep={setStep} setData={setData} dietId={data.diet_id} />
    case 'SelectProvincePage':
      return <SelectProvincePage setStep={setStep} setData={setData} provinceId={data.province_id} />
    case 'SelectCityPage':
      return <SelectCityPage setStep={setStep} setData={setData} provinceId={data.province_id} cityId={data.city_id} />
    case 'CookingPreferencesPage':
      return <CookingPreferencesPage setStep={setStep} setData={setData} cooking={data.extras.cooking} />
    case 'EatingOutPage':
      return <EatingOutPage setStep={setStep} setData={setData} eatingOut={data.extras.eating_out} />
    case 'MotivationPage':
      return <MotivationPage setStep={setStep} setData={setData} motivation={data.extras.motivation} />
    case 'WantToInstertAPicturePage':
      return <WantToInstertAPicturePage setStep={setStep} setData={setData} motivation={data.extras.motivation} />
    case 'InstertAPicturePage':
      return <InstertAPicturePage setStep={setStep} setData={setData} />
    case 'SelectAAvatarPage':
      return <SelectAAvatarPage setStep={setStep} setData={setData} />
    case 'NickNamePage':
      return <NickNamePage setStep={setStep} setData={setData} nickname={data.nickname} />
    case 'WeAreDonePage':
      return <WeAreDonePage setStep={setStep} />
    case 'FreeOrPaidPage':
      return <FreeOrPaidPage setStep={setStep} />
    case 'SelectRoutePage':
      return <SelectRoutePage setStep={setStep} setData={setData} data={data} />
    case 'PhonePage':
      return <PhonePage setStep={setStep} setData={setData} telephone={data.telephone} freeRouteStart={data.free_route_start} />
    case 'CheckOTPPage':
      return <CheckOTPPage setStep={setStep} setData={setData} telephone={data.telephone} freeRouteStart={data.free_route_start} setDataCompleted={setDataCompleted} />
    //todo case "error":
    //   return <ErrorPage/>
    default:
      return <>{step}</>
  }
}

export { InitialConfigPage }
