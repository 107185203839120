import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { InputNumber } from 'primereact/inputnumber'

const initialStateError = { status: false, type: null }

function isAValidInput(height, setError) {
  if (height === null || height === undefined) {
    setError({ status: true, type: 'è necessario inserire una altezza' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (height < 30 || height > 250) {
    setError({ status: true, type: 'la altezza inserita non è valida' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, height, setError }) {
  if (isAValidInput(height, setError)) {
    setStep('ActualWeightPage')
  }
}

export default function HeightPage({ setStep, height, setData }) {
  const [error, setError] = useState(initialStateError)
  var inputClass = classNames('align-items-center mx-auto w-8', {
    'p-invalid': error.status
  })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'GoalWeightPage'} />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Qual è la tua altezza?</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">E' fondamentale per stabilire il tuo peso forma</div>
      <InputNumber
        onFocus={e => {
          setTimeout(() => {
            e.target.setSelectionRange(0, 0)
          }, 10)
        }}
        className={inputClass}
        inputId="height"
        value={height}
        onValueChange={e =>
          setData(data => {
            return { ...data, height: e.value }
          })
        }
        maxFractionDigits={0}
        suffix=" cm"
      />
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, height, setError }} />
    </div>
  )
}
