import React, { useEffect, useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { Dropdown } from 'primereact/dropdown'
import config from '../../../../config'

const initialStateError = { status: false, type: null }
function selectedCountryTemplate(option, props) {
  if (option) {
    return (
      <div className="flex align-items-center">
        <div>{option.name}</div>
      </div>
    )
  }
  return <span>{props.placeholder}</span>
}
function countryOptionTemplate(option) {
  return (
    <div className="flex align-items-center">
      <div>{option.name}</div>
    </div>
  )
}
async function getOptions(setOptions) {
  try {
    let response = await fetch(config.env[config.env.mode].url + '/api/contact/postal?service=province&method=get', {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
    response = await response.json()
    setOptions(response)
  } catch {}
}

function isAValidInput(provinceId, options, setError) {
  if (options.length === 0 || provinceId === null || provinceId === undefined) {
    setError({ status: true, type: "Devi selezionare un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!options.map(option => option.id).includes(provinceId.id)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  return true
}
function nextPage({ setStep, setData, provinceId, options, setError }) {
  if (isAValidInput(provinceId, options, setError)) {
    setData(data => {
      return { ...data, province_id: provinceId.id }
    })
    setStep('SelectCityPage')
  }
}

export default function SelectProvincePage({ setStep, provinceId, setData }) {
  const [options, setOptions] = useState([])
  const [error, setError] = useState(initialStateError)

  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  var inputClass = classNames('w-9 mx-auto', { 'p-invalid': error.status })

  useEffect(() => {
    getOptions(setOptions)
  }, [])

  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'TypeOfDietPage'} />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">In che provincia vivi?</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Questa informazione potrà esserti utile per scoprire se ci sono partecipanti ai nostri percorsi nella tua città</div>
      <Dropdown
        style={{ height: '2.75rem' }}
        value={provinceId}
        onChange={e =>
          setData(data => {
            return { ...data, province_id: e.value ?? null }
          })
        }
        options={options}
        optionLabel="name"
        placeholder=""
        filter
        showClear
        valueTemplate={(option, props) => selectedCountryTemplate(option, props)}
        itemTemplate={(option, props) => countryOptionTemplate(option, props)}
        className={inputClass}
      />
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, setData, provinceId, options, setError }} />
    </div>
  )
}
