import React from 'react'
import { NavLink } from 'react-router-dom'
import { Ripple } from 'primereact/ripple'
import { LogoutUser } from '../../../features/redux/reducer/userSlice'
import { useDispatch } from 'react-redux'

const userRoutes = [
  { link: 'profilo', to: 'profilo', icon: 'pi pi-user' },
  { link: 'abbonamenti', to: 'abbonamenti', icon: 'pi pi-wallet' },
  { link: 'impostazioni', to: 'impostazioni', icon: 'pi pi-cog' },
  { link: 'notifiche', to: 'notifiche', icon: 'pi pi-bell' },
  { link: 'imposta notifiche', to: 'impostaNotifiche', icon: 'pi pi-check-square' }
]

export default function UserConfigNavbar() {
  const dispatch = useDispatch()
  return (
    <ul className="list-none m-0 p-0 flex flex-row lg:flex-column justify-content-evenly md:justify-content-between lg:justify-content-start mb-5 lg:pr-8 lg:mb-0">
      {userRoutes.map(v => {
        return (
          <NavLink
            to={v.to}
            key={v.link}
            className={({ isActive }) =>
              (isActive ? 'bg-primary' : 'text-800 hover:surface-hover') + ' p-ripple flex align-items-center cursor-pointer p-3 border-round  transition-duration-150 transition-colors'
            }>
            <i className={v.icon + ' md:mr-2'}></i>
            <span className="font-medium hidden md:block capitalize">{v.link}</span>
            <Ripple />
          </NavLink>
        )
      })}
      <NavLink
        onClick={() => {
          dispatch(LogoutUser())
        }}
        to="/"
        key="esci"
        className="text-red-500 p-ripple flex align-items-center cursor-pointer p-3 border-round  transition-duration-150 transition-colors">
        <i className="pi pi-sign-out md:mr-2"></i>
        <span className="font-medium hidden md:block capitalize">Esci</span>
        <Ripple />
      </NavLink>
    </ul>
  )
}
