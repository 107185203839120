import React from "react"
import kiwi from '../../assets/images/kiwi.png'
import CommentSection from "./ComentSection"
import { Link } from "react-router-dom"
import { Button } from 'primereact/button'

function NewPage() {
    return (
        <>
            <div className="surface-section px-4 py-8 md:px-6 lg:px-8 text-700">
                <Link to=".." relative="path">
                    <Button icon="pi pi-arrow-left" rounded outlined severity="secondary" />
                </Link>
    <div className="flex flex-wrap">
        <div className="w-full lg:w-6 pr-0 lg:pr-5">
            {/* <span className="text-blue-700 bg-blue-50 inline-block py-2 px-3" style={{ borderRadius: '50px' }}>Long Read</span> */}
            <div className="font-normal text-2xl mt-3 mb-3 text-900">In hendrerit gravida rutrum quisque non tellus</div>
            <div className="text-600 mb-5">that is a basic subtitule by lucas</div>
            <p className="line-height-3 mt-0 mb-5">
                Nisi est sit amet facilisis. Ac odio tempor orci dapibus ultrices in iaculis nunc sed. Pellentesque pulvinar pellentesque habitant morbi tristique senectus.
                Nullam vehicula ipsum a arcu cursus vitae congue. Leo urna molestie at elementum eu facilisis. Fusce id velit ut tortor. Purus non enim praesent elementum facilisis leo vel fringilla est.
                Semper risus in hendrerit gravida rutrum quisque non tellus orci. Neque convallis a cras semper. Vitae elementum curabitur vitae nunc sed. Ornare massa eget egestas purus viverra accumsan in.
                Leo integer malesuada nunc vel risus commodo viverra maecenas accumsan. Sit amet risus nullam eget. Egestas maecenas pharetra convallis posuere morbi leo urna molestie at.
            </p>
            <p className="line-height-3 text-600 text-2xl mb-5">
                “Science is the most reliable guide for civilization, for life, for success in the world. Searching a guide other than the science is meaning carelessness, ignorance and heresy.”
            </p>
            <p className="line-height-3 mt-0 mb-5">
                Convallis tellus id interdum velit laoreet id donec ultrices. Lacus sed viverra tellus in hac habitasse platea dictumst.
                Ultricies tristique nulla aliquet enim. Nunc scelerisque viverra mauris in aliquam sem fringilla. Laculis urna id volutpat lacus laoreet.
            </p>
            <ul className="pt-0 px-5 mt-0 mb-5">
                <li className="line-height-3">Nunc sed velit dignissim sodales ut. </li>
                <li className="line-height-3">Lorem mollis aliquam ut porttitor. </li>
                <li className="line-height-3">Urna nec tincidunt praesent semper feugiat nibh sed.</li>
            </ul>
            <div className="text-3xl text-900">Massa vitae tortor condimentum lacinia</div>
            <p className="line-height-3"> Lacus viverra vitae congue eu consequat ac felis donec et. A lacus vestibulum sed arcu non. Mauris vitae ultricies leo integer malesuada nunc vel.
                Dolor sit amet consectetur adipiscing elit ut aliquam purus. Ac tincidunt vitae semper quis lectus nulla at volutpat. Enim lobortis scelerisque fermentum dui faucibus in ornare.
                In mollis nunc sed id semper. Vitae ultricies leo integer malesuada nunc vel risus commodo. </p>
        </div>
        <div className="w-full lg:w-6 pl-0 lg:pl-5 pt-5">
            <img src={kiwi} alt="Profile" className="w-full border-round" />
            <div className="text-center text-sm font-medium mt-3">Consequat nisl vel pretium lectus quam id leo in.</div>
        </div>
    </div>
</div>
<CommentSection/>
</>
    )
}

export { NewPage }