import React, { useEffect } from 'react'
import { getNotificationListUnread } from '../../features/redux/actions'
import { useSelector, useDispatch } from 'react-redux'

import EmptyNotificationsList from './emptyNotificationList'
import NotificationsList from './notificationList'
import './styles.css'

export default function FloatingNotifications() {
  const notificationsList = useSelector(state => state.notifications.getNotificationListUnread.data)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getNotificationListUnread())
    setInterval(() => {
      dispatch(getNotificationListUnread())
    }, 30000)
  }, [dispatch])

  return (
    <div id="FloatingNotifications" style={{ zIndex: 9999 }} className="hidden border-round surface-overlay p-3 shadow-2 absolute right-0 top-100 w-18rem overflow-hidden origin-top">
      <ul className="list-none p-0 m-0 max-h-18rem overflow-auto" style={{ scrollSnapType: 'y mandatory', WebkitOverflowScrolling: 'touch' }}>
        {notificationsList && notificationsList.length > 0 ? <NotificationsList notificationsList={notificationsList} /> : <EmptyNotificationsList />}
      </ul>
    </div>
  )
}
