import React from 'react'
import { TabPanel, TabView } from 'primereact/tabview'
import '../styles.css'

import DaysMenusHeader from '../DayMenus/DaysMenusHeader'
import HeaderSostituzioni from '../Sostituzioni/header'
import DaysMenus from '../DayMenus/daysMenus'
import Sostituzioni from '../Sostituzioni'

import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

dayjs.extend(utc)

export default function TabViewDiets({ canBeClosed, setOverlayOpened, setShowRecipePage, setRecipeId }) {
  const dietPlanDates = useSelector(state => state.dietPlan.dates)
  const isLoading = useSelector(state => state.dietPlan.isLoading)
  const error = useSelector(state => state.dietPlan.error)

  return (
    <div className="surface-200 fadeinleft animation-duration-200 page-wrapper">
      <div className={'flex flex-wrap px-3 py-1 shadow-1 ' + (canBeClosed ? 'justify-content-between' : 'justify-content-center')}>
        {canBeClosed && (
          <i
            className="pi pi-arrow-left cursor-pointer primary-colr-hover text-xl m-1"
            onClick={() => {
              setOverlayOpened(false)
            }}></i>
        )}
        <b className="flex text-color m-0 text-lg sm:text-xl">
          {'Dal ' +
            (!isLoading && !error ? dayjs(dietPlanDates[0]).utc(true).format('DD/MM/YYYY') : ' . . / . . / . . . . ') +
            ' al ' +
            (!isLoading && !error ? dayjs(dietPlanDates[1]).utc(true).format('DD/MM/YYYY') : ' . . / . . / . . . . ')}
        </b>
        <b className="flex text-color m-0 text-lg mt-1 sm:text-xl"></b>
      </div>
      <div className="page-wrapper">
        <TabView className="tabViewDiets">
          <TabPanel className="menu-tab" header={<DaysMenusHeader />}>
            {error ? <div className="flex align-items-center justify-content-center">{error}</div> : <DaysMenus setShowRecipePage={setShowRecipePage} setRecipeId={setRecipeId} />}
          </TabPanel>
          <TabPanel header={<HeaderSostituzioni />}>
            <Sostituzioni />
          </TabPanel>
        </TabView>
      </div>
    </div>
  )
}
