import React, { useEffect, useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { RadioButton } from 'primereact/radiobutton'
import config from '../../../../config'

const initialStateError = { status: false, type: null }
let optionsDefault = [
  {
    id: 3,
    name: 'Vegetariano',
    description: 'Non mangio nè carne nè pesce',
    group_food_id: 8,
    is_enabled: true,
    created_at: '2021-11-08'
  },
  {
    id: 1,
    name: 'Onnivoro',
    description: 'Mangio di tutto',
    group_food_id: 11,
    is_enabled: true,
    created_at: '2021-11-08'
  },
  {
    id: 2,
    name: 'Ovo-pesco vegetale',
    description: 'Mangio di tutto tranne la carne',
    group_food_id: 10,
    is_enabled: true,
    created_at: '2021-11-08'
  }
]
async function getOptions(setOptions) {
  try {
    let response = await fetch(`${config.env[config.env.mode].url}/api/diet/list`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
    response = await response.json()
    if (response.message === 'Unauthenticated.') return
    setOptions(response)
  } catch {}
}

function isAValidInput(dietId, options, setError) {
  if (options.length === 0 || dietId === null || dietId === undefined) {
    setError({ status: true, type: "Devi selezionare un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!options.map(option => option.id).includes(dietId)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  return true
}
function nextPage({ setStep, dietId, options, setError }) {
  if (isAValidInput(dietId, options, setError)) {
    setStep('SelectProvincePage')
  }
}

export default function TypeOfDietPage({ setStep, dietId, setData }) {
  const [options, setOptions] = useState(optionsDefault)
  const [error, setError] = useState(initialStateError)

  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  var inputClass = classNames({ 'p-invalid': error.status })

  useEffect(() => {
    getOptions(setOptions)
  }, [])
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'WeAreAlmostTherePage'} />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Che tipo di alimentazione segui?</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Potrai cambiare questa scelta in qualsiasi momento anche successiamente</div>
      {options?.map(option => {
        return (
          <div key={option.id} className="ml-6 my-2">
            <div className=" flex align-items-center  text-2xl">
              <RadioButton
                className={inputClass}
                inputId={'input' + option.name}
                name={option.name}
                value={option.id}
                onChange={e =>
                  setData(data => {
                    return { ...data, diet_id: e.value }
                  })
                }
                checked={error.status ? false : option.id === dietId}
              />
              <label htmlFor={'input' + option.name} className="ml-2 capitalizeFirstLetter">
                {option.name}
              </label>
            </div>
            <h6 className="m-0 capitalizeFirstLetter">{option.description ?? optionsDefault[options.indexOf(option)].description}</h6>
          </div>
        )
      })}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setData, setStep, dietId, options, setError }} />
    </div>
  )
}
