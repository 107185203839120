import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'

export const getPushSettings = createAsyncThunk('pushSettings/getPushSettings', async () => {
  const response = await fetch(`${baseUrl}/api/master-club-push-types/list`, {
    method: 'GET',
    headers: headersDefault()
  })

  if (response.status !== 200) throw new Error((response.message || (await response.json()).error) ?? 'GenericError')

  return await response.json()
})

export const pushUserSettings = createAsyncThunk('pushSettings/pushUserSettings', async newSettings => {
  const settingsSelected = newSettings.filter(n => n.user_setting).map(n => n.id)

  const response = await fetch(`${baseUrl}/api/master-club-push-user-settings`, {
    method: 'POST',
    body: JSON.stringify(settingsSelected),
    headers: headersDefault()
  })

  if (response.status !== 200) throw new Error((response.message || (await response.json()).error) ?? 'GenericError')

  return { newSettings }
})
