import React, { useState } from 'react'
import { Divider } from 'primereact/divider'
import { InputSwitch } from 'primereact/inputswitch'
import config from '../../../config'

async function sendUserData(setInputState, value, key) {
  setInputState(oldValue => {
    let aux = { ...oldValue }
    aux[key] = value
    return aux
  })

  let adoc_p = JSON.parse(localStorage.getItem('adoc_p'))

  let data = {}
  data[key] = value
  try {
    let backUrl = config.env[config.env.mode].url
    let headers = {
      'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
    let body = JSON.stringify(data)

    let response = await fetch(`${backUrl}/api/master-club-subscribers/1`, {
      method: 'PATCH',
      body,
      headers
    })
    if (response.status !== 200) throw new Error(response.message)
    localStorage.setItem('adoc_p', JSON.stringify(adoc_p))
    // esto esta seteando mal la data en localstorage
  } catch (e) {
    setInputState(oldValue => {
      let aux = { ...oldValue }
      aux[key] = !value
      return aux
    })
    adoc_p.master_club_subscriber[key] = !value
    localStorage.setItem('adoc_p', JSON.stringify(adoc_p))
    return e.message
  }
}

function SettingsPage() {
  let { gluten_free, keep_weight, vegetarian, subscription } = JSON.parse(localStorage.getItem('adoc_p')).master_club_subscriber
  const [inputState, setInputState] = useState({ gluten_free, keep_weight, vegetarian })

  return (
    <>
      <div className="text-900 font-semibold text-lg">Impostazioni</div>
      <Divider />
      <div className="grid">
        {subscription && (
          <div className={`col-12 ${subscription ? 'lg:col-4' : 'lg:col-6'} p-3`}>
            <div className="shadow-2 border-round surface-50 mb-3 h-full flex-column justify-content-between flex">
              <div className="p-4">
                <div className="text-900 my-3 text-xl font-medium">Desideri ricevere menu per una dieta di mantenimento?</div>
                <p className="mt-0 mb-2 text-700 line-height-3">Indicando SI riceverai solo menu consapevoli per il mantenimento.</p>
              </div>
              <div className="px-4 py-3 surface-200 text-right">
                <InputSwitch checked={inputState.keep_weight} onChange={e => sendUserData(setInputState, e.value, 'keep_weight')} />
              </div>
            </div>
          </div>
        )}
        <div className={`col-12 ${subscription ? 'lg:col-4' : 'lg:col-6'} p-3`}>
          <div className="shadow-2 border-round surface-50 mb-3 h-full flex-column justify-content-between flex">
            <div className="p-4">
              <div className="text-900 my-3 text-xl font-medium">Desideri ricevere la dieta con menu Vegetariano?</div>
              <p className="mt-0 mb-2 text-700 line-height-3">Indicando SI riceverai solo il menu per vegetariani. NO, solo il menu per onnivori</p>
            </div>
            <div className="px-4 py-3 surface-200 text-right">
              <InputSwitch checked={inputState.vegetarian} onChange={e => sendUserData(setInputState, e.value, 'vegetarian')} />
            </div>
          </div>
        </div>
        <div className={`col-12 ${subscription ? 'lg:col-4' : 'lg:col-6'} p-3`}>
          <div className="shadow-2 border-round surface-50 mb-3 h-full flex-column justify-content-between flex">
            <div className="p-4">
              <div className="text-900 my-3 text-xl font-medium">Desideri ricevere la dieta con menu senza Glutine e Lattosio?</div>
              <p className="mt-0 mb-2 text-700 line-height-3">Indicando SI riceverai solo il menu per intolleranze al glutine e lattosio.</p>
            </div>
            <div className="px-4 py-3 surface-200 text-right">
              <InputSwitch checked={inputState.gluten_free} onChange={e => sendUserData(setInputState, e.value, 'gluten_free')} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export { SettingsPage }
