import React from 'react'
import { Dialog } from 'primereact/dialog'
import { TabView, TabPanel } from 'primereact/tabview'
import UpdateUserImage from './UpdateUserImage'
import './styles.css'
import UpdateUserAvatar from './UpdateUserAvatar'

export default function ModalUpdateUserAvatar({ isModalOpen, setIsModalOpen }) {
  return (
    <Dialog className="w-10 ModalUpdateUserAvatar" visible={isModalOpen} onHide={() => setIsModalOpen(false)}>
      <TabView className="p-0 TabViewUpdateUserAvatar">
        <TabPanel header="Foto">
          <UpdateUserImage setIsModalOpen={setIsModalOpen} />
        </TabPanel>
        <TabPanel header="Avatar">
          <UpdateUserAvatar setIsModalOpen={setIsModalOpen} />
        </TabPanel>
      </TabView>
    </Dialog>
  )
}
