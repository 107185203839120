import React from 'react'

import { Chart } from 'primereact/chart'
import { Card } from 'primereact/card'

export default function BalanceWellnessIndex({ wellnessData }) {
  const wellnessJson = {
    labels: ["Un po' giu", 'Così così', 'Bene', 'Felice', 'Alla grande'],
    datasets: [
      {
        data: wellnessData,
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
      }
    ]
  }

  const wellnessOptions = {
    plugins: {
      legend: {
        labels: {
          color: '#495057'
        }
      }
    }
  }

  return (
    <Card>
      <div className="flex justify-content-center flex-wrap">
        {wellnessData.reduce((a, c) => a + c) === 0 ? (
          <div>Ancora non abbiamo dati disponibili</div>
        ) : (
          <Chart
            type="doughnut"
            data={wellnessJson}
            options={wellnessOptions}
            className="w-12 sm:w-4"
            // style={{ position: 'relative', width: '40%' }}
          />
        )}
      </div>
    </Card>
  )
}
