import React from "react"

export default function Comment({comment}){
    return (
        <li className="py-5 border-top-1 surface-border">
            <div className="flex align-items-center justify-content-between mb-3">
                <div className="flex align-items-center">
                    <img src={comment.img} alt="profile user" className="w-3rem h-3rem flex-shrink-0 mr-3" />
                    <div className="flex flex-column">
                        <span className="text-900 font-medium mb-1">{comment.name}</span>
                        <span className="text-500 font-medium text-sm">{comment.date}</span>
                    </div>
                </div>
            </div>
            <p className="text-600 p-0 m-0 line-height-3">{comment.comment}</p>
        </li>
    )
    
}