import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import { InputNumber } from 'primereact/inputnumber'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import config from '../../../../config'
import { Button } from 'primereact/button'

const initialStateError = { status: false, type: null }
async function checkOTP(otp, telephone, freeRouteStart) {
	try {
		const queryIsSubscriber =
			freeRouteStart === null ||
			freeRouteStart === undefined ||
			freeRouteStart === ''
				? '&isSubscriber=true'
				: ''
		let response = await fetch(
			`${
				config.env[config.env.mode].url
			}/api/master-club-otp/${telephone}?import=true${queryIsSubscriber}`,
			{
				method: 'PATCH',
				body: JSON.stringify({ token: otp }),
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
					Authorization: `Bearer ${localStorage.getItem('adoc_t')}`,
				},
			}
		)
		response = await response.json()
		return response
	} catch (e) {
		return { message: 'Api error' }
	}
}
function isAValidInput(response) {
	return response.message === 'Codice corretto'
}
async function nextPage({
	otp,
	telephone,
	freeRouteStart,
	setError,
	setDataCompleted,
}) {
	setError(initialStateError)
	let response = await checkOTP(otp, telephone, freeRouteStart)

	if (!isAValidInput(response)) {
		console.log(response)
		setError({ type: response.message, status: true })
		setTimeout(() => {
			setError(initialStateError)
		}, 5000)
		return
	}
	setDataCompleted(true)
}

export default function CheckOTPPage({
	setStep,
	telephone,
	freeRouteStart,
	setDataCompleted,
}) {
	const [error, setError] = useState(initialStateError)
	const [otp, setOTP] = useState(null)

	const inputClass = classNames('align-items-center mx-auto w-8', {
		'p-invalid': error.status,
	})
	const pageClass = classNames('flex-1 flex-column min-h-full flex')
	return (
		<div className={pageClass}>
			<BackButton
				setStep={setStep}
				prevPage="PhonePage"
			/>
			<div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">
				inserisci il codice inviato al tuo numero di cellulare
			</div>
			<div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center justify-content-center">
				{telephone}
			</div>
			<InputNumber
				className={inputClass}
				useGrouping={false}
				inputId="otp"
				value={otp}
				onValueChange={(e) => setOTP(e.value)}
				maxFractionDigits={0}
				maxLength={4}
				min={0}
			/>
			{error.type &&
			error.type ===
				"Non si trova l'abbonamento. Puoi iniziare un percorso free tornando indietro e selezionando una data d'inizio." ? (
				<label className="mx-auto text-center mt-2">
					Non si trovano abbonati associati a questo telefono.
					<p className="justify-content-center flex align-items-center">
						Riprova con un altro numero o
						<Button
							className="ml-1 p-1 backgraund"
							onClick={() => {
								setStep('SelectRoutePage')
							}}>
							Inizia un percorso free
						</Button>
					</p>
				</label>
			) : (
				<label className="mx-auto text-center mt-2">{error.type}</label>
			)}
			<NextButton
				onClickFunction={nextPage}
				onCLickData={{
					otp,
					telephone,
					freeRouteStart,
					setError,
					setDataCompleted,
				}}
			/>
		</div>
	)
}
