import React, { useEffect, useState } from 'react'
import config from '../../../../config'
import { Button } from 'primereact/button'
import userAvatar from '../../../../assets/images/user-avatar.png'

const initialStateError = { type: null, message: null }
const maxSizeBites = 1048576

function isAValidInput(image, setError) {
  if (image === undefined || image === null) {
    setError({ type: 'imageEmpty', message: 'Devi inserire un file valido' })
    return false
  }
  return image.size <= maxSizeBites
}

async function uploadImage(image, setError, setIsModalOpen) {
  try {
    const body = new FormData()
    body.append('utenti', image)

    let response = await fetch(`${config.env[config.env.mode].url}/api/file-upload/utenti`, {
      body: body,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
    response = await response.json()
    if (response.code !== 200 || response.status !== 'success') throw new Error(response.message)

    let user = JSON.parse(localStorage.getItem('adoc_p')).user
    let userBody = {
      name: user.name ?? '',
      surname: user.surname ?? '',
      telephone: user.telephone ?? '',
      avatar: response.filename
    }

    response = await fetch(`${config.env[config.env.mode].url}/api/profilo-utenti`, {
      method: 'POST',
      body: JSON.stringify(userBody),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
    response = await response.json()
    if (response.message !== 'Succefully updated') throw new Error(response.message)

    let adoc_p = JSON.parse(localStorage.getItem('adoc_p'))
    adoc_p.user = { ...adoc_p.user, avatar: userBody.avatar }
    localStorage.setItem('adoc_p', JSON.stringify(adoc_p))

    setIsModalOpen(false)
  } catch (e) {
    setError({ type: 'api error', message: e.message })
  }
}

async function updateImage(image, setError, setIsModalOpen) {
  if (isAValidInput(image, setError)) {
    await uploadImage(image, setError, setIsModalOpen)
  }
}

export default function UpdateUserImage({ setIsModalOpen }) {
  const [image, setImage] = useState(null)
  const [preview, setPreview] = useState(null)
  const [error, setError] = useState(initialStateError)

  useEffect(() => {
    if (!image) {
      setPreview(null)
      return
    }
    const reader = new FileReader()
    reader.onloadend = () => {
      setPreview(reader.result.toString())
    }
    reader.readAsDataURL(image)
  }, [image])

  return (
    <div className="flex-1 flex-column min-h-65rem flex">
      <label htmlFor="image_uploads" className="mx-auto p-2 p-button bg-cyan-300 border-cyan-300 p-button-rounded">
        Seleziona il file
      </label>
      <input
        style={{ opacity: 0 }}
        id="image_uploads"
        accept="image/png,image/jpeg"
        type="file"
        onChange={e => {
          if (e.target.files[0] === undefined) {
            setError(initialStateError)
            setImage(null)
            return
          }
          if (e.target.files.length > 1) {
            setError({ type: 'multipleElements', message: 'Devi caricare un solo file' })
            setImage(null)
            return
          }
          if (e.target.files[0].size > maxSizeBites) {
            setError({ type: 'maxSize', message: 'Il file supera la dimensione massima di 1 MB' })
            setImage(null)
            return
          }
          if (error) setError(initialStateError)
          setImage(e.target.files[0])
        }}
      />
      <label className="mx-auto text-center mt-3 h-1rem">{error.message}</label>
      <img alt="profileImage" src={preview ?? userAvatar} className="mx-auto mt-3 mb-2" style={{ width: '200px', height: '200px', objectFit: 'cover', borderRadius: '100%' }} />
      <Button
        rounded
        className="bg-cyan-500 border-cyan-500 mt-auto"
        label="Carica"
        onClick={() => {
          updateImage(image, setError, setIsModalOpen)
        }}
      />
    </div>
  )
}
