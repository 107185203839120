import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import { RadioButton } from 'primereact/radiobutton'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'

const initialStateError = { status: false, type: null }
function isAValidInput(gender, setError) {
  if (gender === '' || gender === null || gender === undefined) {
    setError({ status: true, type: "è necessario selezionare un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (!['male', 'female'].includes(gender)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, gender, setError }) {
  if (isAValidInput(gender, setError)) {
    setStep('GoalsPage')
  }
}

export default function GenderPage({ setStep, gender, setData }) {
  const [error, setError] = useState(initialStateError)
  const inputClass = classNames('p-inputtext-lg', { 'p-invalid': error.status })
  const pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage="UserNamePage" />
      <div className=" text-4xl my-8 text-center">Sei uomo o donna?</div>

      <div className="flex align-items-center ml-6 text-2xl">
        <RadioButton className={inputClass} inputId="male" value="male" onChange={e => setData(data => ({ ...data, sex: e.value }))} checked={gender === 'male'} />
        <label htmlFor="male" className="ml-2 capitalizeFirstLetter">
          Uomo
        </label>
      </div>
      <div className="flex align-items-center ml-6 mt-2 text-2xl">
        <RadioButton className={inputClass} inputId="female" value="female" onChange={e => setData(data => ({ ...data, sex: e.value }))} checked={gender === 'female'} />
        <label htmlFor="female" className="ml-2 capitalizeFirstLetter">
          Donna
        </label>
      </div>
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, gender, setError }} />
    </div>
  )
}
