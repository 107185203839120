import notificationsSlice from './NotificationsSlice'
import dietPlanSlice from './dietPlanSlice'
import pushSettingsSLice from './pushSettingsSlice'
import toastSlice from './toastSlice'
import userSlice from './userSlice'

const rootReducer = {
  user: userSlice,
  toast: toastSlice,
  dietPlan: dietPlanSlice,
  pushSettings: pushSettingsSLice,
  notifications: notificationsSlice
}

export default rootReducer
