import { Button } from "primereact/button"
import React from "react"

export default function NextButton({onClickFunction, onCLickData, canSkip}) {
    return (
            <Button
            className={`mb-3 mx-auto  w-7 bg-cyan-500 border-cyan-500 transition-colors transition-duration-200 hover:bg-cyan-600 hover:border-cyan-700 ${canSkip ? "mt-3" : "mt-auto"}` }
            label="Avanti"
            rounded
            onClick={() => onClickFunction(onCLickData)}
            />
    )
}
