import React from 'react'
import { classNames } from 'primereact/utils'
import { InputSwitch } from 'primereact/inputswitch'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'

function nextPage({ setStep }) {
  setStep('GoalWeightPage')
}

export default function FoodPreferencePage({ setStep, data, setData }) {
  const pageClass = classNames('flex-1 flex-column min-h-full flex')

  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage="GoalsPage" />
      <div className=" text-4xl my-8 w-9 mx-auto text-center">quali sono le tue preferenze alimentari?</div>
      <div className="grid formgrid p-fluid m-0">
        <div className="grid w-full m-0">
          {data.subscription && (
            <div className={`col-12 ${data.subscription ? 'lg:col-4' : 'lg:col-6'} p-3`}>
              <div className="shadow-2 border-round surface-50 mb-3 h-full flex-column justify-content-between flex">
                <div className="p-4">
                  <div className="text-900 my-3 text-xl font-medium">Desideri ricevere menu per una dieta di mantenimento?</div>
                  <p className="mt-0 mb-2 text-700 line-height-3">Indicando SI riceverai solo menu consapevoli per il mantenimento.</p>
                </div>
                <div className="px-4 py-3 surface-200 text-right">
                  <InputSwitch checked={data.keep_weight} onChange={e => setData({ ...data, keep_weight: e.value })} />
                </div>
              </div>
            </div>
          )}
          <div className={`col-12 ${data.subscription ? 'lg:col-4' : 'lg:col-6'} p-3`}>
            <div className="shadow-2 border-round surface-50 mb-3 h-full flex-column justify-content-between flex">
              <div className="p-4">
                <div className="text-900 my-3 text-xl font-medium">Desideri ricevere la dieta con menu Vegetariano?</div>
                <p className="mt-0 mb-2 text-700 line-height-3">Indicando SI riceverai solo il menu per vegetariani. NO, solo il menu per onnivori</p>
              </div>
              <div className="px-4 py-3 surface-200 text-right">
                <InputSwitch checked={data.vegetarian} onChange={e => setData({ ...data, vegetarian: e.value })} />
              </div>
            </div>
          </div>
          <div className={`col-12 ${data.subscription ? 'lg:col-4' : 'lg:col-6'} p-3`}>
            <div className="shadow-2 border-round surface-50 mb-3 h-full flex-column justify-content-between flex">
              <div className="p-4">
                <div className="text-900 my-3 text-xl font-medium">Desideri ricevere la dieta con menu senza Glutine e Lattosio?</div>
                <p className="mt-0 mb-2 text-700 line-height-3">Indicando SI riceverai solo il menu per intolleranze al glutine e lattosio.</p>
              </div>
              <div className="px-4 py-3 surface-200 text-right">
                <InputSwitch checked={data.gluten_free} onChange={e => setData({ ...data, gluten_free: e.value })} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep }} />
    </div>
  )
}
