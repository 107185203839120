import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import { InputNumber } from 'primereact/inputnumber'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'

const initialStateError = { status: false, type: null }

function isAValidInput(actualWeight, setError) {
  if (actualWeight === null || actualWeight === undefined) {
    setError({ status: true, type: 'è necessario inserire un peso' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (actualWeight < 30 || actualWeight > 500) {
    setError({ status: true, type: 'il peso inserito non è valido' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, actualWeight, setError }) {
  if (isAValidInput(actualWeight, setError)) {
    setStep('BirthDatePage')
  }
}

export default function ActualWeightPage({ setStep, actualWeight, setData }) {
  const [error, setError] = useState(initialStateError)
  const inputClass = classNames('align-items-center mx-auto w-8 inputNumberActualWeight', { 'p-invalid': error.status })
  const pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage="HeightPage" />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Qual è il tuo peso attuale?</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Non è necessario essere precisi</div>
      <InputNumber
        onFocus={e => {
          setTimeout(() => {
            e.target.setSelectionRange(0, 0)
          }, 10)
        }}
        className={inputClass}
        inputId="actualWeight"
        value={actualWeight}
        onValueChange={e => setData(data => ({ ...data, initial_weight: e.value }))}
        minFractionDigits={2}
        maxFractionDigits={2}
        suffix=" kg"
      />
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, actualWeight, setError }} />
    </div>
  )
}
