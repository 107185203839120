import React from "react"
import { Icon } from "@iconify/react"
import "../styles.css"

export default function Header() {
    return (
        <>
			<Icon
				icon="material-symbols:menu-book-rounded"
				width="25"
				style={{
					margin: '0 3px -6px 0',
				}}
			/>
			Menu
		</>
    )
}