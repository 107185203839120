import { Routes, Route } from 'react-router-dom'
import { Header } from './layouts/Header'

// import { AuthProvider, AuthRoute } from './auth'
import './assets/css/App.css'

import { LoginPage } from './pages/Login'
import { GetTokenPage } from './pages/Login/GetToken/'
import { InitialConfigPage } from './pages/Login/InitialConfig/'
import { GetTokenPageAdmin } from './pages/Login/GetToken/getAdmin'
import { HomePage } from './pages/Home'
import { MeasuresDiary } from './pages/MeasuresDiary'
import { DietPlansPage } from './pages/DietPlans'
import { RecipesPage } from './pages/Recipes'
import { RecipePage } from './pages/Recipes/Recipe'
import { BalancePage } from './pages/Balance'
import { ProConsPage } from './pages/ProCons'
import { TripNotesPage } from './pages/TripNotes'
import { NewsPage } from './pages/News'
import { NewPage } from './pages/News/New'
import { UserPage } from './pages/User'
import { NotFoundPage } from './pages/404'
import { settings } from './settings'
import { ConnectionToast } from './features/ConnectionToast'
import { GoToInstalled } from './features/GoToInstalled'
import { InstallApp } from './features/InstallApp'
import { NotificationCheck } from './features/NotificationCheck'

import { ProfilePage } from './pages/User/Profile'
import { SubscriptionsPage } from './pages/User/Subscriptions'
import { SettingsPage } from './pages/User/Settings'
import { NotificationsPage } from './pages/User/Notifications'

import './assets/css/theme.css' //theme
import 'primereact/resources/primereact.min.css' //core css
import 'primeicons/primeicons.css' //icons
import 'primeflex/primeflex.css' //icons
import LoggedRoutes from './features/MiddlewaresRoutes/LoggedRoutes'
import NoLoggedRoutes from './features/MiddlewaresRoutes/NoLoggedRoutes'
import { Toast } from 'primereact/toast'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { SettingsNotificationsPage } from './pages/User/SettingsNotifications'
import { getUserInfo } from './features/redux/actions'
import { newSubscribeUser } from './serviceWorkerRegistration'

function App() {
  settings()
  const dispatch = useDispatch()
  const isLoading = useSelector(state => state.user.getUserIsLoading)
  const getUserError = useSelector(state => state.user.getUserError)
  const [dispatched, setDispatched] = useState(false)

  useEffect(() => {
    if (dispatched && !isLoading && !getUserError) {
      if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
        setDispatched(false)
        setTimeout(() => {
          newSubscribeUser()
        }, 1000)
      }
    }
  }, [dispatched, isLoading, getUserError])

  useEffect(() => {
    function setUserInfo() {
      if (localStorage.getItem('adoc_t')) {
        dispatch(getUserInfo())
        setDispatched(true)
      }
    }
    setUserInfo()
    setInterval(setUserInfo, 300000)
  }, [dispatch])

  const toast = useRef(null)
  const toastGeneric = useSelector(state => state.toast.toast)

  useEffect(() => {
    toast.current.show(toastGeneric)
  }, [toastGeneric])

  return (
    <>
      <Header />
      <div className="mt-7 w-full" />
      <Toast ref={toast} />
      <ConnectionToast />
      <InstallApp />
      <GoToInstalled />
      <NotificationCheck />
      <Routes>
        <Route element={<NoLoggedRoutes />}>
          <Route exact path="/" element={<LoginPage />} />
          <Route path="/login/:token" element={<GetTokenPage />} />
          <Route path="/impersonate/:token" element={<GetTokenPageAdmin />} />
        </Route>
        <Route element={<LoggedRoutes />}>
          <Route path="/impostazioni-iniziali" element={<InitialConfigPage />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/utente" element={<UserPage />}>
            <Route path="profilo" element={<ProfilePage />} />
            <Route path="abbonamenti" element={<SubscriptionsPage />} />
            <Route path="impostazioni" element={<SettingsPage />} />
            <Route path="notifiche" element={<NotificationsPage />} />
            <Route path="impostaNotifiche" element={<SettingsNotificationsPage />} />
          </Route>
          <Route path="/diario-dimagrante" element={<MeasuresDiary />} />
          <Route path="/menu-e-sostituzioni" element={<DietPlansPage />} />
          <Route path="/ricette-gourmand" element={<RecipesPage />} />
          <Route path="/ricette-gourmand/:recipeId" element={<RecipePage />} />
          <Route path="/bilancio" element={<BalancePage />} />
          <Route path="/tabella-pro-e-contro" element={<ProConsPage />} />
          <Route path="/appunti-di-viaggio" element={<TripNotesPage />} />
          <Route path="/magazine" element={<NewsPage />} />
          <Route path="/magazine/:id" element={<NewPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  )
}

export default App
