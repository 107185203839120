import React, { useState } from 'react'
import { InputSwitch } from 'primereact/inputswitch'
import { useDispatch, useSelector } from 'react-redux'
import { pushUserSettings } from '../../../features/redux/actions'

export default function UserNotification({ notification }) {
  const isLoading = useSelector(state => state.pushSettings.pushUserSettings.isLoading)
  const pushSettings = useSelector(state => state.pushSettings.getPushSettings.data)
  const [tempChecked, setTempChecked] = useState(notification.user_setting)
  const dispatch = useDispatch()

  function updatePushSettings(value) {
    if (value !== notification.user_setting && !isLoading) {
      const newSettings = pushSettings.map(n => {
        return { ...n, user_setting: n.id === notification.id ? !n.user_setting : n.user_setting }
      })
      dispatch(pushUserSettings(newSettings))
    }
    setTempChecked(value)
  }

  return (
    <div className="col-12 lg:col-4 p-3">
      <div className="shadow-2 border-round surface-50 mb-3 h-full flex-column justify-content-between flex">
        <div className="p-4">
          <div className="text-900 my-3 text-xl font-medium">{notification.name}</div>
          <p className="mt-0 mb-2 text-700 line-height-3">{notification.description}</p>
        </div>
        <div className="px-4 py-3 surface-200 text-right">
          <InputSwitch disabled={isLoading} checked={isLoading ? tempChecked : notification.user_setting} onChange={e => updatePushSettings(e.value)} />
        </div>
      </div>
    </div>
  )
}
