import React from 'react'
import { DayMenu } from './DayMenu'
import '../styles.css'
import { useSelector } from 'react-redux'

export default function DaysMenus({ setShowRecipePage, setRecipeId }) {
  const dietPlan = useSelector(state => state.dietPlan.dietPlan)
  const isLoading = useSelector(state => state.dietPlan.isLoading)

  return (
    <div className="dayMenus">
      {!isLoading &&
        dietPlan?.map((menu, index) => {
          return (
            <div key={index} className="container-Day-Menu">
              <DayMenu day={menu} index={index} setShowRecipePage={setShowRecipePage} setRecipeId={setRecipeId} />
            </div>
          )
        })}
    </div>
  )
}
