import React, { useState } from 'react'
import { Card } from 'primereact/card'
import { Heading } from '../../layouts/PageHeading'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { Notes } from './Notes'
import { Icon } from '@iconify/react'
import { Tooltip } from 'primereact/tooltip'

function TripNotesPage() {
	const [note, setNote] = useState()
	const [notes, setNotes] = useState([])
	// const [noteSet, setNoteSet] = useState([])

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!note) return
		setNotes([note, ...notes])
		setNote('')
		// var size = 3
		// var arrayOfArrays = []
		// notes.map((note, i) => {
		// 	arrayOfArrays.push(notes.slice(i, i + size))
		// })

		// setNoteSet(arrayOfArrays)
	}

	return (
		<>
			<Heading
				icon="ic:baseline-luggage"
				title="Appunti di viaggio"
				description="Registra qui tutte le cose belle che ti stanno accadendo e che potrai
					rileggere per auto-motivarti"
			/>

			<div className="page-wrapper">
				<Card className="mx-3 mt-6 sm:mx-8 surface-600 fadeindown animation-duration-300 p-0">
					<h2 className="text-white t-shadow text-center block mt-0 mb-2">
						Scrivi qui i tuoi pensieri
					</h2>
					<form
						onSubmit={handleSubmit}
						className="p-inputgroup">
						<InputTextarea
							placeholder="Grazie a questa app sto mangiando meglio..."
							autoResize={true}
							rows={3}
							id="note-input"
							value={note}
							className="border-round-left text-lg"
							onChange={(e) => {
								setNote(e.target.value)
							}}
						/>
						<Button
							icon={
								<i
									className="pi pi-arrow-right m-2"
									style={{ fontSize: '2em' }}></i>
							}
							tooltip="Salvare"
							tooltipOptions={{
								position: 'left',
								className: 'tooltip-primary z-0',
							}}
							type="submit"
							className="bg-primary px-5 z-0"
						/>
					</form>
				</Card>

				<Notes notes={notes} />
				{/* {noteSet.map((notes, i) => {
					return (
						<Notes
							key={i}
							notes={notes}
						/>
					)
				})} */}
			</div>
		</>
	)
}

export { TripNotesPage }
