import React, { useState } from 'react'
import { InputText } from 'primereact/inputtext'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'

const initialStateError = { status: false, type: null }
function isAValidInput(surname, setError) {
  if (surname === '' || surname === null || surname === undefined) {
    setError({ status: true, type: 'è necessario inserire un cognome' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (!/^(([a-zA-Z]|[À-ÖØ-öø-ÿ Ÿœ])+){3,30}$/.test(surname)) {
    setError({ status: true, type: 'il cognome inserito non è valido' })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, surname, setError }) {
  if (isAValidInput(surname, setError)) {
    setStep('UserNamePage')
  }
}

export default function UserLastNamePage({ setStep, surname, setData }) {
  const [error, setError] = useState(initialStateError)

  var inputClass = classNames('p-inputtext-lg mx-auto w-8', {
    'p-invalid': error.status
  })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <div className=" text-4xl my-8 text-center">Qual è il tuo cognome?</div>
      <InputText
        className={inputClass}
        autoComplete="off"
        id="userLastName"
        type="text"
        value={surname}
        onChange={e =>
          setData(data => {
            return { ...data, surname: e.target.value }
          })
        }
      />
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, surname, setError }} />
    </div>
  )
}
