import { Button } from "primereact/button"
import React from "react"

export default function SkipButton({onClickFunction, onCLickData}) {
    return (
            <Button
            className="mb-3 mx-auto mt-auto w-7 bg-red-300 border-red-300"
            label="Salta"
            rounded
            onClick={() => onClickFunction(onCLickData)}
            />
    )
}