import React, { useEffect, useState } from 'react'
import { TabView, TabPanel } from 'primereact/tabview'
import { InputTextarea } from 'primereact/inputtextarea'
import { Button } from 'primereact/button'
import { classNames } from 'primereact/utils'
import { Tooltip } from 'primereact/tooltip'
import { Icon } from '@iconify/react'
import { Heading } from '../../../layouts/PageHeading'
import { Overlay } from '../../../components/Overlay'
import userAvatar from '../../../assets/images/user-avatar.png'
import { useParams } from 'react-router-dom'
import imgError from '../../../assets/images/default-image.jpg'
import config from '../../../config'

function RecipePage({ hideHeading, propId }) {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [reviewTextarea, setReviewTextarea] = useState('')
  const [showHeading] = useState(!!hideHeading)
  const [liked1, setLiked1] = useState(false)
  const [overlayOpened, setOverlayOpened] = useState(false)

  let recipeId = useParams().recipeId ?? propId

  let [recipe, setRecipe] = useState({})

  useEffect(() => {
    fetch(`${config.env[config.env.mode].url}/api/ricette/${recipeId}/fetch`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
      }
    })
      .then(resp => resp.json())
      .then(json => {
        setRecipe(json)
      })
  }, [recipeId])

  return (
    <>
      {!showHeading && <Heading icon="ic:baseline-ramen-dining" title="Ricetta Gourmand" description="Cucina e mangia bene" />}

      <div className="page-wrapper surface-section px-4 py-4 md:px-6 lg:px-8">
        <div className="grid mb-2">
          <div className="col-12 lg:col-6">
            <div className="flex">
              <div className="flex flex-column w-2 justify-content-between">
                {recipe?.images?.map((image, i) => {
                  return (
                    <img
                      alt=""
                      src={image.url}
                      key={i}
                      className={classNames('w-full cursor-pointer border-2 border-round border-transparent transition-colors transition-duration-150', { 'border-primary': selectedImageIndex === i })}
                      onClick={() => setSelectedImageIndex(i)}
                    />
                  )
                })}
              </div>
              <div className="pl-3 w-10">
                <img
                  src={recipe?.images && recipe.images[selectedImageIndex] !== undefined ? recipe.images[selectedImageIndex].url : imgError}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null
                    currentTarget.src = imgError
                  }}
                  className="w-full"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-12 lg:col-6 py-3 lg:pl-6">
            <div className="flex align-items-center text-xl font-medium text-900 mb-4">
              {recipe.name ?? 'Agnello al prezzemolo'}
              <Tooltip target=".like-tooltip" content="Preferita" />
              <i
                className={classNames('pi text-xl cursor-pointer ml-3 like-tooltip', {
                  'pi-heart text-600': !liked1,
                  'pi-heart-fill primary-color': liked1
                })}
                onClick={() => setLiked1(prevState => !prevState)}></i>
            </div>
            <div
              className="flex align-items-center justify-content-between mb-5 cursor-pointer"
              onClick={() => {
                setOverlayOpened(true)
              }}>
              <div className="flex align-items-center">
                <span className="mr-3">
                  <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                  <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                  <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                  <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                  <i className="pi pi-star text-700 mr-1"></i>
                </span>
                <span className="text-sm">
                  <b className="text-900 mr-1">24</b>
                  <span className="text-500"></span>recenzioni
                </span>
              </div>
            </div>

            <ul className="list-none p-0 m-0 text-sm text-600">
              <li className="flex align-items-center white-space-nowrap w-full block mr-2 mb-3">
                <Icon icon="carbon:skill-level-intermediate" className="w-1rem mr-2" />
                <b className="mr-1">Difficoltà:</b> {recipe.difficulty}
              </li>
              <li className="flex align-items-center white-space-nowrap w-full block mr-2 mb-3">
                <Icon icon="icon-park-solid:chart-proportion" className="w-1rem mr-2" />
                <b className="mr-1">Porzioni:</b> {recipe.portion}
              </li>
              <li className="flex align-items-center white-space-nowrap w-full block mb-3 mr-2">
                <Icon icon="ic:baseline-pending-actions" width="16" className="mr-2" />
                <b className="mr-1">Preparazione:</b>
                {recipe.preparation_time ? `${recipe.preparation_time} minuti` : ''}
              </li>
              <li className="flex align-items-center white-space-nowrap w-full block mb-3 mr-2">
                <Icon icon="mdi:fire" width="18" className="mr-2" />
                <b className="mr-1">Cottura:</b>
                {recipe.cooking_time ? `${recipe.cooking_time} minuti` : ''}
              </li>
              <li className="flex align-items-center white-space-nowrap w-full block mb-3 mr-2">
                <Icon icon="tabler:clock-pause" width="17" className="mr-2" />
                <b className="mr-1">Riposo:</b>
                {recipe.resting_time ? `${recipe.resting_time} minuti` : ''}
              </li>
            </ul>
          </div>
        </div>

        <TabView>
          <TabPanel header="Ingredienti">
            <div className="grid">
              <ul className="py-0 pl-3 m-0 text-700 mb-3">
                {recipe?.recipe_foods?.map((food, index) => (
                  <li key={index} className="mb-2">
                    {food.book_text} {food.quantity} {food.quantity === 1 ? food.measure.name : food.measure.plural} (
                    {food.food.measure_conversions.map(measure => (measure.measure_id === food.measure.id ? measure.grams_value * food.quantity : undefined))}
                    g)
                  </li>
                ))}
              </ul>
            </div>
          </TabPanel>
          <TabPanel header="Procedimento">
            <ol className=" p-0 m-0">
              {recipe?.steps?.map((step, index) => (
                <li key={index} className={`p${index === 0 ? 'b' : 'y'}-5 border-bottom-1 surface-border`}>
                  {step.procedure}
                </li>
              ))}
            </ol>
          </TabPanel>
        </TabView>
      </div>
      {overlayOpened && (
        <Overlay title={recipe.name} setOverlayOpened={setOverlayOpened}>
          <div className="mx-3 sm:mx-8 my-4">
            <div className="grid">
              <div className="col-12 lg:col-3 sm:mt-6 flex align-items-start justify-content-center py-5 lg:py-0">
                <div>
                  <span className="text-5xl text-900 font-bold mr-2">190</span>
                  <span className="text-5xl text-600">Recenzioni</span>
                  <div className="mt-3 text-center">
                    <i className="pi pi-star-fill text-yellow-500 text-2xl mr-1"></i>
                    <i className="pi pi-star-fill text-yellow-500 text-2xl mr-1"></i>
                    <i className="pi pi-star-fill text-yellow-500 text-2xl mr-1"></i>
                    <i className="pi pi-star-fill text-yellow-500 text-2xl mr-1"></i>
                    <i className="pi pi-star-fill text-300 text-2xl "></i>
                  </div>
                </div>
              </div>
              <div className="col-12 lg:col-5 sm:mt-6 sm:px-6">
                <ul className="list-none p-0 m-0">
                  <li className="flex align-items-center mb-2">
                    <span className="text-900 font-medium mr-1 w-1rem">5</span>
                    <i className="pi pi-star-fill text-yellow-500 mr-2"></i>
                    <div style={{ height: '7px' }} className="border-round overflow-hidden flex-auto surface-300">
                      <div className="h-full bg-yellow-500 w-9 border-round"></div>
                    </div>
                    <span className="text-500 font-medium ml-2">75%</span>
                  </li>
                  <li className="flex align-items-center mb-2">
                    <span className="text-900 font-medium mr-1 w-1rem">4</span>
                    <i className="pi pi-star-fill text-yellow-500 mr-2"></i>
                    <div style={{ height: '7px' }} className="border-round overflow-hidden flex-auto surface-300">
                      <div className="h-full bg-yellow-500 w-6"></div>
                    </div>
                    <span className="text-500 font-medium ml-2">50%</span>
                  </li>
                  <li className="flex align-items-center mb-2">
                    <span className="text-900 font-medium mr-1 w-1rem">3</span>
                    <i className="pi pi-star-fill text-yellow-500 mr-2"></i>
                    <div style={{ height: '7px' }} className="border-round overflow-hidden flex-auto surface-300">
                      <div className="h-full bg-yellow-500 w-2"></div>
                    </div>
                    <span className="text-500 font-medium ml-2">20%</span>
                  </li>
                  <li className="flex align-items-center mb-2">
                    <span className="text-900 font-medium mr-1 w-1rem">2</span>
                    <i className="pi pi-star-fill text-yellow-500 mr-2"></i>
                    <div style={{ height: '7px' }} className="border-round overflow-hidden flex-auto surface-300">
                      <div className="h-full bg-yellow-500 w-4"></div>
                    </div>
                    <span className="text-500 font-medium ml-2">40%</span>
                  </li>
                  <li className="flex align-items-center mb-2">
                    <span className="text-900 font-medium mr-1 w-1rem">1</span>
                    <i className="pi pi-star-fill text-yellow-500 mr-2"></i>
                    <div style={{ height: '7px' }} className="border-round overflow-hidden flex-auto surface-300">
                      <div className="h-full bg-yellow-500 w-3"></div>
                    </div>
                    <span className="text-500 font-medium ml-2">30%</span>
                  </li>
                </ul>
              </div>
              <div className="col-12 lg:col-4">
                <div className="font-medium text-xl text-900 mb-3">Condividi la tua esperienza</div>
                <p className="text-600 p-0 mt-0 mb-3 line-height-3">Dici cosa ti piace di piu su questa ricetta</p>
                <InputTextarea rows={5} cols={30} value={reviewTextarea} onChange={e => setReviewTextarea(e.target.value)} className="w-12 mb-3" />
                <Button label="Invia recenzione" className="p-button-rounded" />
              </div>
            </div>
            <div className="mt-5">
              <ul className="list-none m-0 p-0">
                <li className="py-5 border-top-1 surface-border">
                  <div className="flex align-items-center justify-content-between mb-3">
                    <div className="flex align-items-center">
                      <img alt="" src={userAvatar} className="w-3rem h-3rem flex-shrink-0 mr-3" />
                      <div className="flex flex-column">
                        <span className="text-900 font-medium mb-1">Robert Fox</span>
                        <span className="text-500 font-medium text-sm">February 3, 2022</span>
                      </div>
                    </div>
                    <div className="flex align-items-center">
                      <span className="mr-2">
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500"></i>
                      </span>
                      <span className="font-medium">5</span>
                    </div>
                  </div>
                  <p className="text-600 p-0 m-0 line-height-3">
                    Scelerisque varius morbi enim nunc. Arcu bibendum at varius vel pharetra vel turpis nunc eget. Elit ut aliquam purus sit amet luctus. Aliquam etiam erat velit scelerisque in.
                  </p>
                </li>
                <li className="py-5 border-top-1 surface-border">
                  <div className="flex align-items-center justify-content-between mb-3">
                    <div className="flex align-items-center">
                      <img alt="" src={userAvatar} className="w-3rem h-3rem flex-shrink-0 mr-3" />
                      <div className="flex flex-column">
                        <span className="text-900 font-medium mb-1">Savannah Williams</span>
                        <span className="text-500 font-medium text-sm">February 4, 2022</span>
                      </div>
                    </div>
                    <div className="flex align-items-center">
                      <span className="mr-2">
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500"></i>
                      </span>
                      <span className="font-medium">5</span>
                    </div>
                  </div>
                  <p className="text-600 p-0 m-0 line-height-3">
                    Orci porta non pulvinar neque laoreet suspendisse interdum consectetur. Vitae elementum curabitur vitae nunc sed velit dignissim sodales ut.
                  </p>
                </li>
                <li className="py-5 border-top-1 surface-border">
                  <div className="flex align-items-center justify-content-between mb-3">
                    <div className="flex align-items-center">
                      <img alt="" src={userAvatar} className="w-3rem h-3rem flex-shrink-0 mr-3" />
                      <div className="flex flex-column">
                        <span className="text-900 font-medium mb-1">Kathryn Murphy</span>
                        <span className="text-500 font-medium text-sm">February 5, 2022</span>
                      </div>
                    </div>
                    <div className="flex align-items-center">
                      <span className="mr-2">
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500 mr-1"></i>
                        <i className="pi pi-star-fill text-yellow-500"></i>
                      </span>
                      <span className="font-medium">5</span>
                    </div>
                  </div>
                  <p className="text-600 p-0 m-0 line-height-3">
                    Sollicitudin nibh sit amet commodo nulla facilisi nullam vehicula. Fringilla est ullamcorper eget nulla facilisi etiam dignissim diam quis.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Overlay>
      )}
    </>
  )
}

export { RecipePage }
