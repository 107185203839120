import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setToast } from '../../features/redux/reducer/toastSlice'
import { postWellnessIndex } from '../../features/redux/actions'

import { RadioButton } from 'primereact/radiobutton'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { Icon } from '@iconify/react'
import { resetPostWellnessIndexError } from '../../features/redux/reducer/userSlice'

const errorToastMessage = {
  severity: 'error',
  summary: 'Informazioni non salvate',
  detail: 'Abbiamo riscontrato un errore. Riprova più tardi',
  life: 2000
}
const wellnessIcons = [
  {
    text: 'Alla grande',
    icon: 'sparkle',
    value: '5'
  },
  {
    text: 'Felice',
    icon: 'laugh',
    value: '4'
  },
  {
    text: 'Bene',
    icon: 'smile-slight',
    value: '3'
  },
  {
    text: 'Così così',
    icon: 'sad-slight',
    value: '2'
  },
  {
    text: "Un po' giù",
    icon: 'sad',
    value: '1'
  }
]
function WellnessIndex() {
  const masterClubSubscriber = useSelector(state => state.user.master_club_subscriber)
  const { error, isLoading, completed } = useSelector(state => state.user.postWellnessIndex)
  const [showDialogWellnessIndex, setShowDialogWellnessIndex] = useState(isVisible(masterClubSubscriber))
  const [feeling, setFeeling] = useState(null)
  const dispatch = useDispatch()

  function wellnessIndexIsCompletedToday() {
    return new Date().toISOString().slice(0, 10) === masterClubSubscriber?.wellness_index?.date
  }
  function isVisible() {
    if (localStorage.getItem('_i') === 'true') return false
    return !wellnessIndexIsCompletedToday()
  }
  async function sendWellnessIndex() {
    dispatch(postWellnessIndex(feeling, new Date().toISOString().slice(0, 10)))
  }
  useEffect(() => {
    if (!completed || !showDialogWellnessIndex) return
    setShowDialogWellnessIndex(false)
  }, [showDialogWellnessIndex, completed, dispatch])
  useEffect(() => {
    if (!error || !showDialogWellnessIndex) return
    setShowDialogWellnessIndex(false)
    dispatch(resetPostWellnessIndexError())
    dispatch(setToast([errorToastMessage]))
  }, [showDialogWellnessIndex, error, dispatch])

  return (
    <Dialog
      baseZIndex={10}
      header={<h3 className="m-0 text-center">Indice del tuo benessere</h3>}
      visible={showDialogWellnessIndex}
      onHide={() => setShowDialogWellnessIndex(false)}
      draggable={false}
      closeOnEscape={true}
      closable={false}
      className="mx-2"
      footer={<Button disabled={isLoading} label="OK" className="w-full" onClick={() => feeling && sendWellnessIndex()} />}>
      <div className="surface-0 text-center">
        <h4 className="mt-0">Come ti senti oggi?</h4>
        <ul className="list-none p-0 m-0">
          {wellnessIcons.map(icon => {
            return (
              <li key={icon.value} className={'p-2 ' + (icon.value !== 5 ? 'border-top-1 border-300' : '')}>
                <label htmlFor={'feeling' + icon.value} className="flex justify-content-between flex-wrap">
                  <Icon icon={'fluent:emoji-' + icon.icon + '-24-regular'} width="30" />
                  <div className="text-500 font-medium">{icon.text}</div>
                  <RadioButton disabled={isLoading} inputId={'feeling' + icon.value} value={icon.value} name="feeling" onChange={e => setFeeling(e.value)} checked={feeling === icon.value} />
                </label>
              </li>
            )
          })}
        </ul>
      </div>
    </Dialog>
  )
}

export default WellnessIndex
