import React from 'react'

function Overlay({ setOverlayOpened, canBeClosed = true, title, children }) {
  return (
    <>
      <div className="surface-200 pt-7 fadeinleft animation-duration-200" id="menu-overlay">
        <div className={'flex flex-wrap px-3 py-1 shadow-1 z-2  ' + (canBeClosed ? 'justify-content-between' : 'justify-content-center')}>
          {canBeClosed && (
            <i
              className="pi pi-arrow-left cursor-pointer primary-colr-hover text-xl m-1"
              onClick={() => {
                setOverlayOpened(false)
              }}></i>
          )}
          <b className="flex text-color m-0 text-lg sm:text-xl">{title}</b>
          <b className="flex text-color m-0 text-lg mt-1 sm:text-xl"></b>
        </div>
        <div className="page-wrapper">{children}</div>
      </div>
    </>
  )
}

export { Overlay }
