import React, { useState } from 'react'
import { Card } from 'primereact/card'

import { Button } from 'primereact/button'
import { InputTextarea } from 'primereact/inputtextarea'
import { Icon } from '@iconify/react'
import { Heading } from '../../layouts/PageHeading'
import proImg from '../../assets/images/pro.png'
import conImg from '../../assets/images/con.png'

function ProConsPage() {
	const [inputVal, setInputVal] = useState()
	const [proCons, setProCons] = useState([])

	const handleClick = (type) => {
		if (!inputVal) return
		const newValue = {
			value: inputVal,
			type: type,
		}
		setProCons([newValue, ...proCons])
		setInputVal('')
	}

	return (
		<>
			<Heading
				icon="ic:outline-theater-comedy"
				title="Tabella Pro e Contro"
				description="Annota qui successi e insuccessi del tuo percorso"
			/>

			<div className="page-wrapper">
				<Card className="mx-3 mt-6 sm:mx-8 surface-600 fadeindown animation-duration-300">
					<InputTextarea
						value={inputVal}
						placeholder="Scrivi qui i tuoi pensieri..."
						autoResize={true}
						rows={3}
						className="border-round-top w-12 mb-0"
						onChange={(e) => setInputVal(e.target.value)}
					/>
					<span className="p-buttonset mt-0">
						<Button
							label="Pro"
							icon={
								<Icon
									icon="heroicons-solid:thumb-up"
									className="text-white"
									width="25"
								/>
							}
							onClick={() => {
								handleClick('pro')
							}}
							className="p-button-success w-6 z-0"
						/>
						<Button
							label="Contro"
							icon={
								<Icon
									icon="heroicons-solid:thumb-down"
									className="text-white"
									width="25"
								/>
							}
							onClick={() => {
								handleClick('con')
							}}
							className="p-button-danger border-radius-top-0 w-6 z-0"
						/>
					</span>
				</Card>

				<div className="px-4 pt-6 md:px-6 lg:px-8">
					{proCons.map((proCon, i) => {
						return (
							<div
								className="mb-5 flex fadeindown animation-duration-300"
								key={i}>
								<div className="flex flex-column align-items-center w-2rem">
									<img
										src={proImg}
										alt="Pro"
										width="60"
										className={
											'border-circle bg-white p-1 shadow-2 ' +
											(proCon.type === 'con' ? 'opacity-20' : '')
										}
									/>
									<img
										src={conImg}
										alt="Contro"
										width="60"
										className={
											'border-circle bg-white p-1 shadow-2 ' +
											(proCon.type === 'pro' ? 'opacity-20' : '')
										}
									/>
								</div>
								<div className="ml-5 surface-card shadow-2 border-round p-3 flex-auto">
									<div className="mb-3">
										<i className="text-500 text-sm">
											Martedì 10 Settembre 2022
										</i>
									</div>
									<div className="line-height-3 text-700 mb-3">
										{proCon.value}
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}

export { ProConsPage }
