import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import { RadioButton } from 'primereact/radiobutton'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'

const options = [
  'Si, mi piace e dedico molto tempo alla cucina',
  'Mi piace cucinare ma non ho tempo',
  'Non mi piace cucinare ma lo faccio quotidianamente',
  'Acquisto cibo pronto da asporto, anche online',
  'Uso spesso cibi pronti'
]
const initialStateError = { status: false, type: null }

function isAValidInput(cooking, setError) {
  if (cooking === '' || cooking === null || cooking === undefined) {
    setError({ status: true, type: "Devi selezionare un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!options.includes(cooking)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  return true
}
function nextPage({ setStep, cooking, setError }) {
  if (isAValidInput(cooking, setError)) {
    setStep('EatingOutPage')
  }
}

export default function CookingPreferencesPage({ setStep, cooking, setData }) {
  const [error, setError] = useState(initialStateError)
  const inputClass = classNames({ 'p-invalid': error.status })
  const pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage="SelectCityPage" />
      <div className=" text-4xl my-8 text-center w-9 mx-auto">Ti piace cucinare?</div>
      {options.map((option, index) => (
        <div key={`${option + index}`} className="my-2 flex align-items-center ml-6 text-2xl">
          <RadioButton
            className={inputClass}
            inputId={`input${option}`}
            name={option}
            value={option}
            onChange={e =>
              setData(data => ({
                ...data,
                extras: { ...data.extras, cooking: e.value }
              }))
            }
            checked={error.status ? false : option === cooking}
          />
          <label htmlFor={`input${option}`} className="ml-2 capitalizeFirstLetter">
            {option}
          </label>
        </div>
      ))}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, cooking, setError }} />
    </div>
  )
}
