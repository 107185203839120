import { Button } from "primereact/button"
import React from "react"
import fake1 from "./fakeAvatars/avatar-f-2.png"
import fake2 from "./fakeAvatars/avatar-f-3.png"
import fake3 from "./fakeAvatars/avatar-m-1.png"
import Comment from "./Comment"

const comments = [
    {
        img: fake3,
        name: "Robert Fox",
        date: "February 3, 2022",
        comment: "Mantiene ciò che promette, spero che cose come questa continuino a crescere."
    },
    {
        img: fake2,
        name: "Savannah Williams",
        date: "February 4, 2022",
        comment: "Non mi è sembrato molto buono, la scorsa settimana è andata molto meglio, dovrebbero migliorare."
    },
    {
        img: fake1,
        name: "Kathryn Murphy",
        date: "February 5, 2022",
        comment: "Adoro questa pubblicazione, mi ha toccato il cuore ed è molto interessante, spero di leggere presto qualcosa di simile sulla pagina!!."
    }
]

export default function CommentSection() {
    return (
        <div className="surface-section px-4 py-8 md:px-6 lg:px-8">
    <div className="grid">
        <div className="col-12">
            <div className="font-medium text-xl text-900 mb-3">Share your opinion</div>
            <p className="text-600 p-0 mt-0 mb-3 line-height-3">Here we can put a subtitle.</p>
            <Button label="Write a coment" className="p-button-rounded" />
        </div>
    </div>
    <div className="mt-5">
        <ul className="list-none m-0 p-0">
            {comments.map((comment, index) => <Comment key={index} comment={comment}/>)}
        </ul>
    </div>
</div>
    )
}