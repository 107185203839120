import { Calendar } from 'primereact/calendar'
import React from 'react'
import { Heading } from '../../layouts/PageHeading'
import { Button } from 'primereact/button'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

dayjs.extend(utc)

export default function BalanceDatePickerPage({ setBalanceDates, balanceDates }) {
  let UserCreatedAt = JSON.parse(localStorage.getItem('adoc_p')).master_club_subscriber.created_at

  return (
    <>
      <Heading icon="ic:baseline-network-check" title="Bilancio" description="Statistiche di progresso" />

      <div className="page-wrapper flex justify-content-center">
        <div
          className="mt-3 sm:mt-5 shadow-2 border-round w-11 sm:w-6 p-3 fadeindown animation-duration-300"
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: 'max-content'
          }}>
          <h2 htmlFor="basic" className="text-white t-shadow m-1 text-center" id="menu-dates-label">
            Selezionare le date
          </h2>
          <Calendar
            id="menu-dates"
            dateFormat="dd/mm/yy"
            selectionMode="range"
            inline={false}
            inputClassName="text-center"
            minDate={new Date(UserCreatedAt)}
            maxDate={new Date()}
            showIcon
            panelClassName="w-12"
            iconPos="left"
            value={balanceDates.length !== 0 ? balanceDates : null}
            onChange={e => setBalanceDates(e.value)}
            readOnlyInput
          />
          <p className="text-center">
            <Button
              label="Oppure guarda il bilancio generale"
              onClick={() => {
                setBalanceDates([dayjs(UserCreatedAt).utc(true).toDate(), new Date()])
              }}></Button>
          </p>
        </div>
      </div>
    </>
  )
}
