import { createSlice } from '@reduxjs/toolkit'
import { getNotificationList, notificationReading, getNotificationListUnread } from '../actions'

const initialState = {
  activeNotifications: null,
  getNotificationList: {
    data: null,
    isLoading: false,
    error: false,
    extra: null
  },
  getNotificationListUnread: {
    quantity: 0,
    data: null,
    isLoading: false,
    error: false
  },
  notificationReading: {
    isLoading: false,
    error: false
  }
}

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    resetGetNotificationListError: state => {
      state.getNotificationList.error = false
    },
    resetNotificationReadingError: state => {
      state.notificationReading.error = false
    },
    updateActiveNotifications: (state, payload) => {
      state.activeNotifications = payload.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getNotificationList.pending, state => {
      state.getNotificationList.isLoading = true
      state.getNotificationList.error = false
    })
    builder.addCase(getNotificationList.fulfilled, (state, action) => {
      state.getNotificationList.isLoading = false
      state.getNotificationList.error = false
      state.getNotificationList.data =
        state.getNotificationList.data && action.payload.notifications.current_page > 1 ? [...state.getNotificationList.data, ...action.payload.notifications.data] : action.payload.notifications.data
      state.getNotificationList.extra = action.payload
    })
    builder.addCase(getNotificationList.rejected, (state, action) => {
      state.getNotificationList.isLoading = false
      state.getNotificationList.error = action.error.message
    })

    builder.addCase(getNotificationListUnread.pending, state => {
      state.getNotificationListUnread.isLoading = true
      state.getNotificationListUnread.error = false
    })
    builder.addCase(getNotificationListUnread.fulfilled, (state, action) => {
      state.getNotificationListUnread.isLoading = false
      state.getNotificationListUnread.error = false
      state.getNotificationListUnread.quantity = action.payload.data.unread
      state.getNotificationListUnread.data = action.payload.notifications.data
    })
    builder.addCase(getNotificationListUnread.rejected, (state, action) => {
      state.getNotificationListUnread.isLoading = false
      state.getNotificationListUnread.error = action.error.message
    })

    builder.addCase(notificationReading.pending, (state, action) => {
      state.notificationReading.isLoading = true
      state.notificationReading.error = false
      state.getNotificationListUnread.quantity--
      state.getNotificationListUnread.data = state.getNotificationListUnread.data.filter(n => n.id !== action.meta.arg.id)
    })
    builder.addCase(notificationReading.fulfilled, state => {
      state.notificationReading.isLoading = false
      state.notificationReading.error = false
    })
    builder.addCase(notificationReading.rejected, (state, action) => {
      state.notificationReading.isLoading = false
      state.notificationReading.error = action.error.message
      state.getNotificationListUnread.quantity++
      state.getNotificationListUnread.data.push(action.meta.arg)
    })
  }
})

export const { resetGetNotificationListError, resetNotificationReadingError, updateActiveNotifications } = notificationsSlice.actions
export default notificationsSlice.reducer
