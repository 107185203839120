import config from '../../../config'

export const baseUrl = config.env[config.env.mode].url
export const baseNode = config.env[config.env.mode].node

export const headersDefault = () => {
  return { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Bearer ${localStorage.getItem('adoc_t')}` }
}

export * from './userActions'
export * from './dietPlanActions'
export * from './pushSettingsActions'
export * from './NotificationsActions'
