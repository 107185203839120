import React from 'react'
import { Outlet } from 'react-router-dom'
import { Heading } from '../../layouts/PageHeading'
import { Card } from 'primereact/card'
import UserConfigNavbar from './UserConfigNavbar'

function UserPage() {
  return (
    <div style={{ minHeight: '100vh' }}>
      <Heading icon="mdi:shield-account" title="Area personale" description="Aggiorna tuoi dati" />
      <div className="page-wrapper">
        <div className="px-0 pt-4 md:px-6 lg:px-8">
          <div className="p-fluid flex flex-column lg:flex-row">
            <UserConfigNavbar />
            <Card className="w-full">
              <Outlet />
            </Card>
          </div>
        </div>
      </div>
    </div>
  )
}

export { UserPage }
