import React, { useEffect, useState } from 'react'
import './styles.css'

import SelectorOfTheWeek from './SelectorOfTheWeek'
import TabViewDiets from './TabViewDiets'

import { Overlay } from '../../components/Overlay'
import { RecipePage } from '../Recipes/Recipe'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { getDietPlan } from '../../features/redux/actions'

dayjs.extend(utc)

function DietPlansPage() {
  const [menusPageCanBeClosed, setMenusPageCanBeClosed] = useState(true)
  const [showRecipePage, setShowRecipePage] = useState(false)
  const [showMenusPage, setShowMenusPage] = useState(false)
  const [recipeId, setRecipeId] = useState()
  const [dietPlanDate] = useState([])

  const master_club_subscriber = useSelector(state => state.user.master_club_subscriber)
  const dietPlanDates = useSelector(state => state.dietPlan.dates)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!master_club_subscriber?.subscription) {
      dispatch(getDietPlan(new Date()))
      setMenusPageCanBeClosed(false)
      setShowMenusPage(true)
    }
  }, [dispatch, master_club_subscriber])

  function updatePlanDate(date) {
    let dateString = dayjs(date).utc(true).toISOString().slice(0, 10)
    let begin = dietPlanDates[0] ? dietPlanDates[0].slice(0, 10) : ''
    let end = dietPlanDates[0] ? dietPlanDates[1].slice(0, 10) : ''

    if (begin > dateString || dateString > end) {
      dispatch(getDietPlan(date))
    }
    setShowMenusPage(true)
  }

  return (
    <>
      {!showMenusPage ? (
        <SelectorOfTheWeek updatePlanDate={updatePlanDate} dietPlanDate={dietPlanDate[0]} />
      ) : (
        <>
          <TabViewDiets canBeClosed={menusPageCanBeClosed} setOverlayOpened={setShowMenusPage} setShowRecipePage={setShowRecipePage} setRecipeId={setRecipeId} />
          {showRecipePage && (
            <Overlay canBeClosed={true} title="Ricetta" setOverlayOpened={setShowRecipePage}>
              <RecipePage hideHeading propId={recipeId} />
            </Overlay>
          )}
        </>
      )}
    </>
  )
}

export { DietPlansPage }
