import React from 'react'
import BackButton from '../buttons/BackButton'
import { classNames } from 'primereact/utils'
import { Button } from 'primereact/button'

function nextPage({ setStep }) {
  setStep('FreeOrPaidPage')
}

export default function WeAreDonePage({ setStep }) {
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'NickNamePage'} />
      <div className="mb-3 mt-auto mx-auto text-4xl w-9 text-center">Abbiamo terminato!</div>
      <Button className={`mb-auto mt-3 mx-auto text-8xl w-7 bg-cyan-500 border-cyan-500`} label="ENJOY DIETA SOCIAL" rounded onClick={() => nextPage({ setStep })} />
    </div>
  )
}
