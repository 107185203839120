import React from 'react'
import { Card } from 'primereact/card'
import notFoundImg from '../../assets/images/404.png'

function NotFoundPage() {
	return (
		<>
			<div className="page-wrapper">
				<div className="col-12">
					<Card className="m-3 fadeinleft animation-duration-500 text-center">
						<h1>Ups</h1>
						<img
							src={notFoundImg}
							alt="Page not found"
							className="max-w-17rem"
						/>
						<h1>Non troviamo quello che cerchi</h1>
					</Card>
				</div>
			</div>
		</>
	)
}

export { NotFoundPage }
