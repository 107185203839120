import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import SkipButton from '../buttons/SkipButton'
import { classNames } from 'primereact/utils'
import { RadioButton } from 'primereact/radiobutton'

let options = [
  { name: 'Si, la carico ora', nextPage: 'InstertAPicturePage' },
  { name: 'No, non voglio caricarla', nextPage: 'NickNamePage' },
  { name: 'Scelgo un avatar', nextPage: 'SelectAAvatarPage' },
  { name: 'Deciderò successivamente', nextPage: 'NickNamePage' }
]

const initialStateError = { status: false, type: null }

function isAValidInput(optionSelected, setError) {
  if (optionSelected === '' || optionSelected === null || optionSelected === undefined) {
    setError({ status: true, type: `Devi selezionare un'opzione per continuare oppure puoi selezionare "salta"` })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!options.map(option => option.name).includes(optionSelected.name)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  return true
}

function skipPage({ setStep }) {
  setStep('NickNamePage')
}

function setPage({ setStep, optionSelected, setError }) {
  if (isAValidInput(optionSelected, setError)) {
    setStep(optionSelected.nextPage)
  }
}

export default function WantToInstertAPicturePage({ setStep }) {
  const [optionSelected, setOption] = useState(null)
  const [error, setError] = useState(initialStateError)
  var inputClass = classNames({ 'p-invalid': error.status })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'MotivationPage'} />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Vuoi inserire ora la tua foto?</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Deciderai in un secondo momento se la vorrai rendere visibile agli altri</div>
      {options.map((option, index) => {
        return (
          <div key={index} className="my-2 flex align-items-center ml-6 text-2xl">
            <RadioButton
              className={inputClass}
              inputId={'input' + option.name}
              name={option.name}
              value={option}
              onChange={e => setOption(e.value)}
              checked={error.status || !optionSelected ? false : option.name === optionSelected.name}
            />
            <label htmlFor={'input' + option.name} className="ml-2 capitalizeFirstLetter">
              {option.name}
            </label>
          </div>
        )
      })}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <SkipButton onClickFunction={skipPage} onCLickData={{ setStep }} />
      <NextButton canSkip={true} onClickFunction={setPage} onCLickData={{ setStep, optionSelected, setError }} />
    </div>
  )
}
