import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { RadioButton } from 'primereact/radiobutton'

let options = ['meno di 4 ore', 'più di 4 ore', 'più di 5 ore', 'più di 6 ore', 'più di 7 ore', 'più di 8 ore']
const initialStateError = { status: false, type: null }

function isAValidInput(sleepDuration, setError) {
  if (sleepDuration === '' || sleepDuration === null || sleepDuration === undefined) {
    setError({ status: true, type: "Devi selezionare un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  if (!options.includes(sleepDuration)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }
  return true
}
function nextPage({ setStep, sleep, setError }) {
  if (isAValidInput(sleep, setError)) {
    setStep('IntestinalFuncionalityPage')
  }
}

export default function SleepDurationPage({ setStep, sleep, setData }) {
  const [error, setError] = useState(initialStateError)
  var inputClass = classNames({ 'p-invalid': error.status })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'ImpedimentsPhysicalPage'} />
      <div className="text-4xl my-8 text-center w-9 mx-auto">Quante ore riesci a dormire?</div>
      {options.map((option, index) => {
        return (
          <div key={index} className="my-2 flex align-items-center ml-6 text-2xl">
            <RadioButton
              className={inputClass}
              inputId={'input' + option}
              name={option}
              value={option}
              onChange={e =>
                setData(data => {
                  return { ...data, extras: { ...data.extras, sleep: e.value } }
                })
              }
              checked={error.status ? false : option === sleep}
            />
            <label htmlFor={'input' + option} className="ml-2 capitalizeFirstLetter">
              {option}
            </label>
          </div>
        )
      })}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, sleep, setError }} />
    </div>
  )
}
