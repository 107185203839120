import React, { useEffect } from 'react'
import { Divider } from 'primereact/divider'
import { useDispatch, useSelector } from 'react-redux'
import { getPushSettings } from '../../../features/redux/actions'
import UserNotification from './NotificationSetting'

function SettingsNotificationsPage() {
  const dispatch = useDispatch()
  const pushSettings = useSelector(state => state.pushSettings.getPushSettings.data)
  const pushSettingsIsLoading = useSelector(state => state.pushSettings.getPushSettings.isLoading)

  useEffect(() => {
    if (!pushSettings) {
      dispatch(getPushSettings())
    }
  }, [pushSettings, dispatch])

  return (
    <>
      <div className="text-900 font-semibold text-lg">Imposta Notifiche</div>
      <Divider></Divider>
      <div className="grid">
        {pushSettingsIsLoading && <div className="justify-content-center flex align-items-center text-center w-full h-5rem">Caricando...</div>}
        {pushSettings && pushSettings.map(notification => <UserNotification key={notification.id} notification={notification} />)}
      </div>
    </>
  )
}

export { SettingsNotificationsPage }
