import React from 'react'
import ProfileUserForm from './userForm'
import { Divider } from 'primereact/divider'
import ProfileUserAvatar from './profileUserAvatar/index.js'

function ProfilePage() {
  return (
    <>
      <div className="text-900 font-semibold text-lg">Profilo</div>
      <Divider></Divider>
      <div className="flex gap-5 flex-column-reverse md:flex-row">
        <ProfileUserForm />
        <ProfileUserAvatar />
      </div>
    </>
  )
}

export { ProfilePage }
