import React, { useState, useRef, useEffect } from 'react'
import { Calendar } from 'primereact/calendar'
import { Divider } from 'primereact/divider'
import { Heading } from '../../layouts/PageHeading'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import Measures from './measures'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'
import config from '../../config'
import LoadingMeasures from './loadingMeasures'

dayjs.extend(relativeTime)

const showSuccess = t => {
  t.current.show({
    severity: 'success',
    summary: 'Informazioni salvate',
    detail: 'Ricordati di salvare le misure speso',
    life: 3000
  })
}
const showError = t => {
  t.current.show({
    severity: 'error',
    summary: 'Informazioni non salvate',
    detail: 'Abbiamo riscontrato un errore. Riprova più tardi',
    life: 3000
  })
}
const showErrorLoad = t => {
  t.current.show({
    severity: 'error',
    summary: 'Informazioni non caricate',
    detail: 'Abbiamo riscontrato un errore. Riprova più tardi',
    life: 3000
  })
}
function isToday(date) {
  return date.toDateString() === new Date().toDateString()
}
const initialState = {
  weight: 0,
  neck_circumference: 0,
  umbilical_circumference: 0,
  hips_circumference: 0,
  middle_thigh_circumference: 0,
  arm_circumference: 0
}
function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}
let actualMeasures = initialState
async function getMeasureDiary(setMeasuresData, setDate, date, toast) {
  let dateQuery = dayjs(date).utc(true).toISOString().slice(0, 10)
  try {
    let adoc_t = localStorage.getItem('adoc_t')
    let response = await fetch(`${config.env[config.env.mode].url}/api/master-club-measure-diary/${dateQuery}/fetch`, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${adoc_t}`
      }
    })
    response = await response.json()
    setMeasuresData(response)
    if (isToday(date)) actualMeasures = response
  } catch (e) {
    showErrorLoad(toast)
    setMeasuresData(initialState)
  }

  setDate(date)
}
function checkData(data) {
  let aux = initialState
  for (let key in data) {
    aux[key] = data[key]
  }
  return aux
}
async function postMeasureDiary(data, toast) {
  if (shallowEqual(data, actualMeasures)) return
  let dataCheced = checkData(data)
  try {
    let adoc_t = localStorage.getItem('adoc_t')
    await fetch(`${config.env[config.env.mode].url}/api/master-club-measure-diary`, {
      method: 'POST',
      body: JSON.stringify(dataCheced),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${adoc_t}`
      }
    })
    showSuccess(toast)
  } catch (e) {
    showError(toast)
  }
}

function MeasuresDiary() {
  const [date, setDate] = useState(new Date())
  const toast = useRef(null)
  const [measuresData, setMeasuresData] = useState(null)
  let adoc_p = JSON.parse(localStorage.getItem('adoc_p'))

  useEffect(() => {
    getMeasureDiary(setMeasuresData, setDate, new Date(), toast)
  }, [])

  return (
    <>
      <Toast ref={toast} />
      <Heading icon="ic:twotone-book" title="Diario dimagrante" description="Inserisci le misure giornaliere" />
      <div className="page-wrapper">
        <div className="grid shadow-2 border-round surface-card m-0 sm:mx-8 sm:my-6 px-1 py-4 sm:p-4">
          <div className="flex justify-content-center w-12">
            <Calendar
              id="basic"
              value={date}
              minDate={new Date(adoc_p?.master_club_subscriber.created_at)}
              maxDate={new Date()}
              onChange={e => {
                setMeasuresData(null)
                getMeasureDiary(setMeasuresData, setDate, e.value, toast)
              }}
              showIcon
            />
          </div>
          <Divider />
          {measuresData ? <Measures date={date} measuresData={measuresData} setMeasuresData={setMeasuresData} /> : <LoadingMeasures />}
          <Divider />
          {isToday(date) && (
            <div className="text-center w-12">
              <Button label="Invia" className="w-6rem" onClick={() => postMeasureDiary(measuresData, toast)} />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export { MeasuresDiary }
