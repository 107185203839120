import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setToast } from '../redux/reducer/toastSlice'

function ConnectionToast() {
  const dispatch = useDispatch()

  useEffect(() => {
    function handleOnline() {
      dispatch(
        setToast({
          severity: 'success',
          summary: 'Sei nuevamente online',
          detail: 'Ora potrai salvare informazioni',
          life: 3000
        })
      )

      window.removeEventListener('online', handleOnline)
      window.addEventListener('offline', handleOffline)
    }

    function handleOffline() {
      dispatch(
        setToast({
          severity: 'error',
          summary: 'Sei offline',
          detail: "Puoi usare l'app ma non salvare informazioni",
          life: 3000
        })
      )

      window.removeEventListener('offline', handleOffline)
      window.addEventListener('online', handleOnline)
    }

    if (window.navigator.onLine) {
      window.addEventListener('offline', handleOffline)
    } else {
      window.addEventListener('online', handleOnline)
    }

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [dispatch])

  return null
}

export { ConnectionToast }
