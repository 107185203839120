import React, { useState } from 'react'
import config from '../../config'
import { Heading } from '../../layouts/PageHeading'
import { AutoComplete } from 'primereact/autocomplete'
import { Overlay } from '../../components/Overlay'
import { RecipePage } from './Recipe'
import imgError from '../../assets/images/default-image.jpg'
import './styles.css'

function RecipesPage() {
  const [recipeName, setRecipeName] = useState(null)
  const [recipeId, setRecipeId] = useState(null)
  const [overlayOpened, setOverlayOpened] = useState(false)
  const [filteredCountries, setFilteredCountries] = useState(null)

  const searchRecipeInBackend = async (event = []) => {
    fetch(
      `${
        config.env[config.env.mode].url
      }/api/ricette/list?draw=1&columns[0][data]=name&columns[0][name]=name&columns[0][searchable]=true&columns[0][orderable]=true&columns[0][search][value]=&columns[0][search][regex]=false&order[0][dir]=asc&start=0&length=10&search[value]=${
        event.query ? event.query : ''
      }&search[regex]=false`,
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
        }
      }
    )
      .then(resp => resp.json())
      .then(json => setFilteredCountries(json.data))
  }

  const itemTemplate = item => {
    return (
      <div className="grid">
        <div className="col-3">
          <img
            alt={item.name}
            src={item.url ?? imgError}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null
              currentTarget.src = imgError
            }}
            className="w-12"
          />
        </div>
        <div className="col-9 white-space-normal">{item.name}</div>
      </div>
    )
  }

  return (
    <>
      <Heading icon="ic:baseline-ramen-dining" title="Ricette Gourmand" description="Cucina e mangia bene" />
      <div className="page-wrapper flex justify-content-center">
        <div
          className="mt-3 sm:mt-5 shadow-2 border-round w-11 sm:w-6 p-3 fadeindown animation-duration-300"
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: 'max-content'
          }}>
          <h2 htmlFor="basic" className="text-white t-shadow m-1 text-center" id="menu-dates-label">
            Cerca tra le ricette del nostro DB
          </h2>
          <AutoComplete
            value={recipeName}
            suggestions={filteredCountries}
            completeMethod={searchRecipeInBackend}
            placeholder="Scrivi qui la ricetta..."
            field="name"
            onChange={e => {
              console.log(e.value)
              setRecipeName(e.value)
            }}
            onSelect={e => {
              setRecipeId(e.value.recipe_id)
              setOverlayOpened(true)
            }}
            autoFocus
            aria-label="Recipes"
            dropdownAriaLabel="Selezionare Recipe"
            className="w-12"
            showEmptyMessage
            emptyMessage={'Non si trovano ricette che contengono "' + recipeName + '"'}
            inputClassName="w-12"
            dropdown
            forceSelection
            itemTemplate={itemTemplate}
          />
        </div>
      </div>
      {overlayOpened && (
        <Overlay title="Ricetta" canBeClosed={true} setOverlayOpened={setOverlayOpened}>
          <RecipePage hideHeading propId={recipeId} />
        </Overlay>
      )}
    </>
  )
}

export { RecipesPage }
