import React from "react"
import '../styles.css'

import { Card } from 'primereact/card'

export default function Sostituzioni() {
    return (
		<Card>
			<div className="surface-section px-4 py-3 md:px-6">
				<div className="text-2xl font-bold mb-2">
					TUTTE LE SOSTITUZIONI
				</div>
				<div className="text-700 line-height-3">
					Quando è presente il consumo della pasta senza glutine è
					sempre consigliato a pranzo. Durante la settimana devi
					portare in tavola gli alimenti per il numero di volte
					indicato nei menu settimanali.
				</div>
				<div className="grid mt-6">
					<div className="col-12 md:col-6 text-lg line-height-3">
						<b className="primary-color">COLAZIONE</b>
						<p>
							A colazione devi consumare cinque alimenti nell’ordine
							indicato nel piano. Puoi sceglierli tra le opzioni
							riportate nella tabella in basso. Se decidi di far
							colazione con la crema Budwig LGF o con il Porridge LGF,
							vale come piatto unico.
						</p>
					</div>
					<div className="col-12 md:col-6 text-700 line-height-3">
						<ul>
							<li>
								Tè verde o altro tipo di tè, limonata calda, tisana a
								base di eucalipto, menta, iperico, mirto. Evitare
								l’orzo in quanto contiene glutine
							</li>
							<li>
								Una tazza di latte vegetale o un bicchiere di golden
								milk o 1 yogurt bianco vegetale o 4-5 fette di
								affettato senza glutine (bresaola, fesa di tacchino,
								prosciutto cotto o prosciutto crudo magro) o 1 uovo
								sodo o 1 frittatina di due albumi o una porzione di
								formaggio vegetale fresco (100 g) o un pezzetto di
								grana o parmigiano (30 g)
							</li>
							<li>
								2 cucchiai di cereali senza glutine (come fiocchi di
								mais, fiocchi di grano saraceno, riso soffiato,
								amaranto soffiato ...) o 2 gallette di riso o di mais
								o di altri cereali senza glutine o 2 fette biscottate
								integrali senza glutine o 3-4 biscotti secchi
								integrali senza glutine e lattosio o 1 fettina di pane
								senza glutine (come pane di grano saraceno) o 1
								cucchiaio di miele
							</li>
							<li>Un frutto o una piccola macedonia di frutta</li>
							<li>
								3 noci o 6 nocciole o 6 mandorle o 1 cucchiaio di
								pinoli o di semi oleosi misti o di frutti rossi o un
								quadratino di cioccolato fondente (maggiore del 75%)
							</li>
						</ul>
					</div>
				</div>
				<hr className="my-3 mx-0 border-top-1 border-none surface-border" />
				<div className="grid">
					<div className="col-12 md:col-6 text-lg line-height-3">
						<b className="primary-color">
							SPUNTINI DI METÀ MATTINA E METÀ POMERIGGIO
						</b>
					</div>
					<div className="col-12 md:col-6 text-700 line-height-3">
						<ul>
							<li>
								Una bevanda calda senza zucchero come: tè verde o
								altro tipo di tè, caffè, tisana + 1 frutto di stagione
								a scelta o spremuta di agrumi o un bicchiere di
								macedonia o centrifugato di verdura (80%) e frutta
								(20%) o uno yogurt bianco vegetale
							</li>
						</ul>
					</div>
				</div>
				<hr className="my-3 mx-0 border-top-1 border-none surface-border" />
				<div className="grid">
					<div className="col-12 md:col-6 text-lg line-height-3">
						<b className="primary-color">PRANZO</b>
						<p>
							Ai pasti devi consumare quattro alimenti nell’ordine
							riportato.
						</p>
					</div>
					<div className="col-12 md:col-6 text-700 line-height-3">
						<ul>
							<li>
								Una insalata mista o altra insalata o ortaggi di
								stagione a scelta (peperoni, pomodori, cetrioli,
								iceberg...)
							</li>
							<li>
								Un primo piatto glucidico come 80 g di pasta senza
								glutine (100 g per gli uomini)* o 80 g di cereali
								integrali senza glutine come riso, grano saraceno,
								miglio, amaranto, quinoa, sorgo, teff... (100 g per
								gli uomini) o 80 g di legumi (100 g per gli uomini) o
								40 g di cereali senza glutine + 40 g di legumi (100 g
								in totale per gli uomini). I legumi secchi in cottura
								triplicano il loro peso. Se sono freschi o surgelati
								bisogna consumarne 250 g
							</li>
							<li>
								Una porzione di verdura cotta di stagione a scelta
							</li>
						</ul>
					</div>
				</div>
				<hr className="my-3 mx-0 border-top-1 border-none surface-border" />
				<div className="grid">
					<div className="col-12 md:col-6 text-lg line-height-3">
						<b className="primary-color">CENA</b>
						<p>
							Il pane deve essere consumato insieme alla verdura
							cotta.
						</p>
					</div>
					<div className="col-12 md:col-6 text-700 line-height-3">
						<ul>
							<li>
								Una insalata mista o altra insalata di stagione a
								scelta
							</li>
							<li>
								Un alimento proteico come pesce a scelta (salmone,
								alici, sgombro, sarde, orata...), o formaggio
								vegetale/stagionato/delattosato (formaggio vegetale
								fresco o delattosato -in base alla tollerabilità- o
								formaggio stagionato a pasta dura come parmigiano
								stagionato 36 mesi, grana, emmenthal, pecorino
								stagionato 36 mesi, provolone…) o affettati
								(prosciutto cotto o crudo, affettato di tacchino,
								bresaola...) o carne magra (agnello, tacchino,
								vitello, manzo...) o tofu o 2 uova
							</li>
							<li>Una porzione di verdura cotta di stagione</li>
							<li>
								Pane senza glutine quando indicato (una porzione da 40
								g per le donne e da 60 g per gli uomini) o 2 piccole
								patate o 30 g di cereali integrali senza glutine (50 g
								per gli uomini) come riso, grano saraceno, miglio,
								quinoa, amaranto, sorgo, teff o 30 g di legumi (50 g
								per gli uomini) o 40 g di polenta integrale
							</li>
						</ul>
					</div>
				</div>
			</div>
		</Card>
	)
}