import React, { useEffect } from 'react'
import { Calendar } from 'primereact/calendar'
import { Heading } from '../../../layouts/PageHeading'
import '../styles.css'

import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { getDatesToDisable } from '../../../features/redux/actions'

dayjs.extend(utc)

export default function SelectorOfTheWeek({ updatePlanDate, dietPlanDate }) {
  const daysLimit = useSelector(state => state.dietPlan.getDatesToDisable.daysLimit)
  const disableDates = useSelector(state => state.dietPlan.getDatesToDisable.disableDates)
  const dispatch = useDispatch()

  useEffect(() => {
    if (daysLimit[0] === daysLimit[1]) {
      dispatch(getDatesToDisable())
    }
  }, [dispatch, daysLimit])

  return (
    <>
      <Heading icon="ic:baseline-ramen-dining" title="Menu e Sostituzioni" description="Ecco i menu di ogni settimana" />
      <div className="page-wrapper flex justify-content-center" id="menu-page-wrapper">
        <div
          className="mt-3 sm:mt-5 shadow-2 border-round w-11 sm:w-6 p-3 fadeindown animation-duration-300"
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            height: 'max-content'
          }}>
          <h2 htmlFor="basic" className="text-white t-shadow m-1 text-center" id="menu-dates-label">
            Selezionare la settimana
          </h2>
          <Calendar
            id="menu-dates"
            dateFormat="dd/mm/yy"
            inline={false}
            minDate={new Date(daysLimit[0])}
            maxDate={new Date(daysLimit[1])}
            disabledDates={disableDates.map(date => new Date(date))}
            inputClassName="text-center"
            showIcon
            panelClassName="w-12"
            iconPos="left"
            value={dietPlanDate}
            onChange={e => {
              if (e.value === null) return
              updatePlanDate(e.value)
            }}
            readOnlyInput
          />
        </div>
      </div>
    </>
  )
}
