import React from 'react'
import './styles.css'

import { ScrollPanel } from 'primereact/scrollpanel'
import { Divider } from 'primereact/divider'
import { Card } from 'primereact/card'
import he from 'he'

let foodDays = ['Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato', 'Domenica']
let foodMeal = ['COLAZIONE', 'SPUNTINO MATTINA', 'PRANZO', 'SPUNTINO POMERIGGIO', 'CENA']

function DayMenu({ day, index, setShowRecipePage, setRecipeId }) {
  return (
    <>
      <Card className="p-0 bg-primary t-shadow text-white m-1 day-card text-center" title={foodDays[index]} />
      <ScrollPanel style={{ width: '100%', height: 'calc(100vh - 17rem)' }} className="custom">
        {day.map((meal, i) => {
          return (
            <Card key={i} title={foodMeal[meal.meal_id - 1]} className="m-3 mr-2">
              {JSON.parse(meal.tables).parent.map((foodRecipe, i) => {
                return foodRecipe.recipe_id !== undefined ? (
                  <span
                    key={i}
                    onClick={() => {
                      setRecipeId(foodRecipe.recipe_id)
                      setShowRecipePage(true)
                    }}
                    className="cursor-pointer primary-color-hover">
                    {he.decode(foodRecipe.name)}
                    {foodRecipe.quantity ? ` (${foodRecipe.quantity}g)` : ''}
                    <Divider className="m-2" />
                  </span>
                ) : (
                  <div key={i}>
                    {foodRecipe.best_measure}
                    <Divider className="m-2" />
                  </div>
                )
              })}
            </Card>
          )
        })}
      </ScrollPanel>
    </>
  )
}

export { DayMenu }
