import React from "react"
import { Icon } from "@iconify/react"

export default function Header() {
    return (
        <>
            <Icon
                icon="humbleicons:exchange-horizontal"
                width="25"
                style={{
                    margin: '0 3px -6px 0',
                }}
            />
            Sostituzioni
        </>
    )
}