import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { RadioButton } from 'primereact/radiobutton'

let options = ['Si, ho bisogno di tenere alta la motivazione', 'No, se mi pongo un obiettivo riesco a raggiungerlo da solo/a', 'Alcune volte ne sentoil bisogno']
const initialStateError = { status: false, type: null }

function isAValidInput(motivation, setError) {
  if (motivation === '' || motivation === null || motivation === undefined) {
    setError({ status: true, type: "Devi selezionare un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!options.includes(motivation)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  return true
}
function nextPage({ setStep, motivation, setError }) {
  if (isAValidInput(motivation, setError)) {
    setStep('WantToInstertAPicturePage')
  }
}

export default function MotivationPage({ setStep, motivation, setData }) {
  const [error, setError] = useState(initialStateError)
  var inputClass = classNames({ 'p-invalid': error.status })
  var pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'EatingOutPage'} />
      <div className=" text-4xl my-8 text-center w-9 mx-auto">Per raggiungere i tuoi obiettivi hai bisogno di essere motivato/a?</div>
      {options.map((option, index) => {
        return (
          <div key={index} className="my-2 flex align-items-center ml-6 text-2xl">
            <RadioButton
              className={inputClass}
              inputId={'input' + option}
              name={option}
              value={option}
              onChange={e =>
                setData(data => {
                  return {
                    ...data,
                    extras: { ...data.extras, motivation: e.value }
                  }
                })
              }
              checked={error.status ? false : option === motivation}
            />
            <label htmlFor={'input' + option} className="ml-2 capitalizeFirstLetter">
              {option}
            </label>
          </div>
        )
      })}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, motivation, setError }} />
    </div>
  )
}
