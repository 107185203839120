import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'

export const getNotificationList = createAsyncThunk('notification/getNotificationList', async (page = 1) => {
  const query = '?'.concat(
    new URLSearchParams({
      filter: 'all',
      fullLayout: true,
      app: true,
      page
    })
  )
  const response = await fetch(`${baseUrl}/api/centro-notifiche/list${query}`, {
    method: 'GET',
    headers: headersDefault()
  })

  if (response.status !== 200) throw new Error((response.message || (await response.json()).error) ?? 'GenericError')

  return await response.json()
})

export const getNotificationListUnread = createAsyncThunk('notification/getNotificationListUnread', async () => {
  const query = '?'.concat(
    new URLSearchParams({
      filter: 'unread',
      fullLayout: false,
      app: true
    })
  )
  const response = await fetch(`${baseUrl}/api/centro-notifiche/list${query}`, {
    method: 'GET',
    headers: headersDefault()
  })

  if (response.status !== 200) throw new Error((response.message || (await response.json()).error) ?? 'GenericError')

  return await response.json()
})

export const notificationReading = createAsyncThunk('notification/notificationReading', async notification => {
  const query = '?'.concat(
    new URLSearchParams({
      app: true
    })
  )

  const response = await fetch(`${baseUrl}/api/centro-notifiche/${notification.id}/fetch${query}`, {
    method: 'GET',
    headers: headersDefault()
  })

  if (response.status !== 200) throw new Error((response.message || (await response.json()).error) ?? 'GenericError')

  return
})
