import React, { useEffect } from 'react'
import { Divider } from 'primereact/divider'
import { useDispatch, useSelector } from 'react-redux'
import { getNotificationList } from '../../../features/redux/actions'
import UserNotification from './Notification'
import { Button } from 'primereact/button'

function NotificationsPage() {
  const dispatch = useDispatch()
  const notificationsList = useSelector(state => state.notifications.getNotificationList.data)
  const extra = useSelector(state => state.notifications.getNotificationList.extra)
  useEffect(() => {
    if (!notificationsList) {
      dispatch(getNotificationList())
    }
  }, [dispatch, notificationsList])

  return (
    <>
      <div className="text-900 font-semibold text-lg">Notifiche</div>
      <Divider></Divider>
      <div className="grid">
        {notificationsList?.map(notification => (
          <UserNotification key={notification.id} notification={notification} />
        ))}
      </div>
      {notificationsList?.length !== extra?.data.all && (
        <Button
          text
          label="Carica ancora"
          onClick={() => {
            dispatch(getNotificationList(extra.notifications.current_page + 1))
          }}
        />
      )}
    </>
  )
}

export { NotificationsPage }
