import React from 'react'
import ThinkCloudImage from '../../../assets/images/think-cloud.png'

function Notes({ notes }) {
	if (!notes) return
	return (
		<>
			<div className="text-center mx-3 sm:mx-7 my-4">
				<div className="grid">
					{/* <div className="flex flex-column md:flex-row"> */}
					{notes.map((note, i) => {
						return (
							<div
								key={i}
								className="fadeindown animation-duration-300 col-12 sm:col-4 sm:my-4 p-4">
								{/* <div className="flex flex-grow-1 mb-4 md:mb-0 md:px-4 py-4 md:py-0 fadeindown animation-duration-300"> */}
								<div className="shadow-2 surface-card border-round relative">
									<img
										src={ThinkCloudImage}
										width={100}
										height={100}
										className="absolute left-50"
										style={{ marginLeft: '-50px', top: '-50px' }}
										alt="testimonial-1"
									/>
									<div className="px-4 pb-4 pt-8 relative">
										<p className="text-900 font-italic line-height-3 text-lg m-0 pb-4 border-bottom-1 surface-border">
											“{note}”
										</p>

										<div className="text-900 font-semibold line-height-3 mt-4"></div>
										<i className="text-600 line-height-3 text-sm">
											Sabato 14 Novembre 2022
										</i>
									</div>
								</div>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}

export { Notes }
