import { createSlice } from '@reduxjs/toolkit'
import { getDietPlan, getDatesToDisable } from '../actions'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

dayjs.extend(utc)

let today = dayjs().utc(true).toISOString().slice(0, 10)
const initialState = {
  dietPlan: null,
  isLoading: false,
  error: false,
  dates: [null, null],
  getDatesToDisable: {
    isLoading: false,
    error: false,
    daysLimit: [today, today],
    disableDates: [today]
  }
}

const dietPlanSlice = createSlice({
  name: 'dietPlan',
  initialState,
  reducers: {
    resetGetDietPlanError: state => {
      state.error = false
    },
    resetGetDatesToDisable: state => {
      state.getDatesToDisable.error = false
    }
  },
  extraReducers: builder => {
    builder.addCase(getDietPlan.pending, state => {
      state.isLoading = true
      state.error = false
      state.dates = initialState.dates
      state.dietPlan = initialState.dietPlan
    })
    builder.addCase(getDietPlan.fulfilled, (state, action) => {
      state.isLoading = false
      state.error = false
      state.dates = action.payload.dates
      state.dietPlan = action.payload.dietPlan
    })
    builder.addCase(getDietPlan.rejected, (state, action) => {
      state.isLoading = false
      state.error = action.error.message
    })
    builder.addCase(getDatesToDisable.pending, state => {
      state.getDatesToDisable.isLoading = true
      state.getDatesToDisable.error = false
    })
    builder.addCase(getDatesToDisable.fulfilled, (state, action) => {
      state.getDatesToDisable.isLoading = false
      state.getDatesToDisable.error = false
      state.getDatesToDisable.daysLimit = action.payload.daysLimit
      state.getDatesToDisable.disableDates = action.payload.disableDates
    })
    builder.addCase(getDatesToDisable.rejected, (state, action) => {
      state.getDatesToDisable.isLoading = false
      state.getDatesToDisable.error = action.error.message
    })
  }
})

export const { resetGetDietPlanError, resetGetDatesToDisable } = dietPlanSlice.actions
export default dietPlanSlice.reducer
