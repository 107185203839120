import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import React, { useEffect, useState } from 'react'
import './styles.css'

function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
export function InstallApp() {
  const [installPromptEvent, setInstallPromptEvent] = useState(null)

  useEffect(() => {
    function saveBeforeInstallPrompt(event) {
      setInstallPromptEvent(event)
    }

    window.addEventListener('beforeinstallprompt', saveBeforeInstallPrompt)
  }, [])

  function showInstallPrompt() {
    installPromptEvent.prompt()
  }
  useEffect(() => {
    if (!installPromptEvent) return
    installPromptEvent.userChoice.then(choice => {
      if (choice.outcome === 'accepted') {
      }
      setInstallPromptEvent(null)
    })
  }, [installPromptEvent])

  const [close, setClose] = useState(false)

  if (installPromptEvent && !close) {
    return (
      <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 99 }} className="w-screen h-screen fixed top-0 bottom-0 right-0 left-0 flex align-items-center justify-content-center">
        <Card className="w-11 installAppCard">
          {isMobileDevice() ? (
            <h3>Per utilizzare correttamente questa applicazione e garantire il funzionamento, è necessaria l'installazione.</h3>
          ) : (
            <div className="flex justify-content-between">
              <h3>Per utilizzare correttamente questa applicazione e garantire il funzionamento, è necessaria l'installazione.</h3>
              <Button
                rounded
                text
                raised
                icon="pi pi-times"
                onClick={() => {
                  setClose(true)
                }}
              />
            </div>
          )}
          <Button className="mt-auto" onClick={showInstallPrompt} label="INSTALLARE" />
        </Card>
      </div>
    )
  }
}
