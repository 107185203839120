import { Icon } from '@iconify/react'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import React from 'react'
import './styles.css'

const measures = [
  {
    title: 'Peso corporeo',
    measure: 'kg',
    icon: 'healthicons:weight',
    color: 'indigo',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 surface-border',
    state_name: 'weight'
  },
  {
    title: 'Circonferenza collo',
    measure: 'cm',
    icon: 'game-icons:necklace-display',
    color: 'pink',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 surface-border',
    state_name: 'neck_circumference'
  },
  {
    title: 'Circonferenza ombellicale',
    measure: 'cm',
    icon: 'mingcute:body-line',
    color: 'indigo',
    measuresDiaryClass: 'col-12 md:col-4 border-bottom-1 surface-border',
    state_name: 'umbilical_circumference'
  },
  {
    title: 'Circonferenza fianchi',
    measure: 'cm',
    icon: 'mingcute:body-fill',
    color: 'pink',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 md:border-bottom-none surface-border',
    state_name: 'hips_circumference'
  },
  {
    title: 'Circonferenza coscia',
    measure: 'cm',
    icon: 'game-icons:leg',
    color: 'indigo',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 md:border-bottom-none surface-border',
    state_name: 'middle_thigh_circumference'
  },
  {
    title: 'Circonferenza braccio',
    measure: 'cm',
    icon: 'healthicons:arm',
    color: 'pink',
    measuresDiaryClass: 'col-12 md:col-4',
    state_name: 'arm_circumference'
  }
]

export default function LoadingMeasures() {
  return measures.map((measure, index) => {
    return (
      <div key={index} className={measure.measuresDiaryClass}>
        <div className="m-3">
          <div className="flex justify-content-between mb-3">
            <div className="mr-4">
              <span className="block text-500 font-medium mb-3">{measure.title}</span>
              <InputNumber placeholder="Caricando..." disabled={true} className="inputNumberMeasureDiary" />
            </div>
            <div className={'flex align-items-center justify-content-center border-round w-6rem h-6rem bg-' + measure.color + '-100'}>
              <Icon icon={measure.icon} width="60" className={'text-' + measure.color + '-500'} />
            </div>
          </div>
        </div>
      </div>
    )
  })
}
