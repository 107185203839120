// This optional code is used to register a service worker.
// register() is not called by default.

import { postNotificationsSubscription } from './features/redux/actions'
import { updateActiveNotifications } from './features/redux/reducer/NotificationsSlice'
import store from './features/redux/store'
// This lets the app load faster on subsequent visits in production, and gives
// it offline capabilities. However, it also means that developers (and users)
// will only see deployed updates on subsequent visits to a page, after all the
// existing tabs open on the page have been closed, since previously cached
// resources are updated in the background.

// To learn more about the benefits of this model and instructions on how to
// opt-in, read https://cra.link/PWA
export const PUBLIC_KEY = 'BFJyTnNDARLWhWcVTQM2s9viA0Sj6Buk-n8Ztwxo7_riYsvqQEfxz9w0VJUIOMKPzsrPr32iwnKrUyXxIxgHRb4'
function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export function newSubscribeUser() {
  navigator.serviceWorker
    .getRegistration()
    .catch(error => console.log(error))
    .then(registration => {
      const adoc_t = localStorage.getItem('adoc_t')
      const adoc_p = localStorage.getItem('adoc_p')
      if (adoc_t && adoc_p) {
        registration.pushManager
          .getSubscription()
          .catch(error => console.log(error))
          .then(subscription => {
            const pushSubscriptions = JSON.parse(adoc_p).master_club_subscriber.push_subscriptions
            let subscriptionsEndpoints = null
            if (pushSubscriptions && pushSubscriptions.length > 0) {
              subscriptionsEndpoints = pushSubscriptions.map(s => JSON.parse(s.subscription_key).endpoint)
            }
            if (subscriptionsEndpoints && subscription && subscriptionsEndpoints.includes(subscription.endpoint)) return
            registration.pushManager
              .permissionState({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY)
              })
              .then(state => {
                if (state === 'denied' || state === 'prompt') {
                  store.dispatch(updateActiveNotifications(state))
                } else if (state === 'prompt') {
                  store.dispatch(updateActiveNotifications(state))
                } else if (state === 'granted')
                  // aca estan garantizados asi que hacemos todo el camino perfecto
                  registration.pushManager
                    .subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY)
                    })
                    .catch(() =>
                      registration.pushManager
                        .permissionState({
                          userVisibleOnly: true,
                          applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY)
                        })
                        .then(state => store.dispatch(updateActiveNotifications(state)))
                    )
                    .then(newSubscription => {
                      if (newSubscription) {
                        // hay una subscription
                        store.dispatch(updateActiveNotifications(state))
                        if (!subscriptionsEndpoints || !subscriptionsEndpoints.includes(newSubscription.endpoint)) {
                          // la subscripcion no existe en la db
                          if (!store.getState().user.postNotificationsSubscription.isLoading) {
                            // la subscripcion no esta siendo cargada
                            store.dispatch(postNotificationsSubscription(newSubscription))
                          }
                        }
                      }
                    })
              })
          })
      }
    })
}

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets; see https://github.com/facebook/create-react-app/issues/2374
      return
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorkerForLocalHost(swUrl, config)

        // Add some additional logging to localhost, pointing developers to the
        // service worker/PWA documentation.
        // navigator.serviceWorker.ready.then(() => {
        // console.log(
        //   'This web app is being served cache-first by a service ' +
        //     'worker. To learn more, visit https://cra.link/PWA'
        // );
        // })
      } else {
        // Is not localhost. Just register service worker
        registerValidSW(swUrl, config)
      }
    })
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => {
      newSubscribeUser()

      registration.onupdatefound = () => {
        const installingWorker = registration.installing
        if (installingWorker == null) {
          return
        }
        installingWorker.onstatechange = () => {
          if (installingWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.
              console.log('New content is available and will be used when all tabs for this page are closed. See https://cra.link/PWA.')

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration)
              }
            } else {
              // At this point, everything has been precached.
              // It's the perfect time to display a
              // "Content is cached for offline use." message.
              console.log('Content is cached for offline use.')

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration)
              }
            }
          }
        }
      }
    })
    .catch(error => {
      console.error('Error during service worker registration:', error)
    })
}

function checkValidServiceWorkerForLocalHost(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
    .then(response => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get('content-type')
      if (response.status === 404 || (contentType != null && contentType.indexOf('javascript') === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then(registration => {
          registration.unregister().then(() => {
            window.location.reload()
          })
        })
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config)
      }
    })
    .catch(() => {
      console.log('No internet connection found. App is running in offline mode.')
    })
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        registration.unregister()
      })
      .catch(error => {
        console.error(error.message)
      })
  }
}
