import React, { useEffect, useRef, useState } from 'react'
import config from '../../config'
import logo from '../../assets/images/logo-dieta-social.svg'
import ReCAPTCHA from 'react-google-recaptcha'
import firebase from '../../settings/firebaseConfig'
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider } from 'firebase/auth'

import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { useNavigate } from 'react-router-dom'
import { Dialog } from 'primereact/dialog'
import LoadingSPinner from '../../components/LoadingSpinner'
import { setAdoc_p, setAdoc_t } from '../../features/redux/reducer/userSlice'
import { useDispatch } from 'react-redux'

function updateCaptcha(setCaptchaIsValid, reCaptchaToken) {
  if (!reCaptchaToken.current.getValue()) {
    setCaptchaIsValid(false)
    return
  }

  fetch(`${config.env[config.env.mode].url}/api/recaptcha-verify?g-recaptcha-response=${reCaptchaToken.current.getValue()}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
    .catch(_error => setCaptchaIsValid(false))
    .then(resp => resp.json())
    .then(json => setCaptchaIsValid(json.success))
}
function sendEmailRequest(email) {
  fetch(`${config.env[config.env.mode].url}/api/login-app/${email}`, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  })
    .catch(e => console.log(e))
    .then(a => console.log(a))
}
function verifyData(email, captchaIsValid) {
  let errorsToast = []
  let isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)
  if (!isValidEmail)
    errorsToast.push({
      life: 3000,
      summary: 'Error',
      severity: 'error',
      detail: 'Si prega di inserire una email valida'
    })
  if (!captchaIsValid)
    errorsToast.push({
      life: 3050,
      summary: 'Error',
      severity: 'error',
      detail: 'Spuntare la verifica'
    })

  return errorsToast
}

function LoginPage() {
  const [captchaIsValid, setCaptchaIsValid] = useState(false)
  const reCaptchaToken = useRef(null)
  const toast = useRef(null)
  const [email, setEmail] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visibleModalLoginSuccess, setVisibleModalLoginSuccess] = useState(false)
  const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleSocialLogin = provider => {
    const auth = getAuth()
    signInWithPopup(auth, provider)
      .then(async result => {
        // The signed-in user info.
        // const user = result.user

        setIsLoading(true)
        const response = await fetch(`${config.env[config.env.mode].url}/api/social-login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            token: result.user.accessToken
          })
        })

        if (response.ok) {
          const data = await response.json()
          dispatch(setAdoc_t(window.atob(data.message)))
          setToken(window.atob(data.message))
        }
      })
      .catch(error => {
        // Handle Errors here.
        const errorCode = error.code
        const errorMessage = error.message
        // The email of the user's account used.
        const email = error.customData.email

        console.log('login error')
        console.log(error)
        console.log(errorCode)
        console.log(errorMessage)
        console.log(email)
        // The credential that was used.
        const credential = OAuthProvider.credentialFromError(error)
        console.log(credential)

        // ...
      })
  }

  async function submit(e) {
    e.preventDefault()
    let errors = verifyData(email, captchaIsValid)
    if (errors.length !== 0) {
      toast.current.show(errors)
      return
    }
    sendEmailRequest(email)
    setVisibleModalLoginSuccess(true)
    setEmail('')
  }

  useEffect(() => {
    if (token) {
      fetch(`${config.env[config.env.mode].url}/api/profilo-utenti/1/fetch `, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      })
        .then(resp => resp.json())
        .then(json => {
          if (json.message === 'Unauthenticated.') throw new Error(json.message)
          localStorage.setItem('adoc_t', token)
          localStorage.setItem('adoc_p', JSON.stringify(json))
          dispatch(setAdoc_p(json))
          return json
        })
        .then(json => {
          window.location.href = json?.master_club_subscriber.last_login ? '/#/home' : '/#/impostazioni-iniziali'
        })
        .catch(_ => (window.location.href = '/'))
    }
  }, [token, dispatch])

  const footerContent = (
    <Button
      style={{ margin: 0 }}
      label="Riprova"
      onClick={() => {
        setVisibleModalLoginSuccess(false)
      }}
    />
  )

  return (
    <>
      <LoadingSPinner loading={isLoading} />
      <Dialog visible={visibleModalLoginSuccess} onHide={() => setVisibleModalLoginSuccess(false)} footer={footerContent}>
        Abbiamo inviato un’email per eseguire l’accesso
      </Dialog>
      <Toast ref={toast} />
      <div className="flex align-items-center justify-content-center">
        <div className="surface-card p-4 shadow-2 border-round w-full lg:w-6">
          <div className="text-center mb-5">
            <img src={logo} alt="hyper" height={50} className="mb-3" />
            <div className="text-900 text-3xl font-medium mb-3">Accedi al tuo account</div>
          </div>
          <form action="POST" onSubmit={e => submit(e, toast, email, captchaIsValid)}>
            <div>
              <label htmlFor="email" className="block text-900 font-medium mb-2">
                Email
              </label>
              <InputText
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
                id="email"
                type="text"
                className="w-full mb-3"
              />
              <ReCAPTCHA
                ref={reCaptchaToken}
                style={{ width: '304px', height: '78px' }}
                className="w-full mb-3"
                sitekey="6Ler_BImAAAAAAlC9J5aheA543DsbLnmXRUuXhZI"
                onChange={() => updateCaptcha(setCaptchaIsValid, reCaptchaToken)}
              />
              <Button type="submit" label="Accedi" icon="pi pi-user" className="w-full" />
            </div>
          </form>
          <div className="">
            <Button
              onClick={() => {
                handleSocialLogin(new GoogleAuthProvider())
              }}
              type="button"
              label="Accedere con Google"
              icon="pi pi-google"
              className="mt-1 w-full"
              style={{
                background: '#c54238',
                border: '1px solid #c54238',
                color: '#fff'
              }}
            />
          </div>
          <div>
            <Button
              onClick={() => {
                handleSocialLogin(new OAuthProvider('apple.com'))
              }}
              type="button"
              label="Accedere con Apple"
              icon="pi pi-apple"
              className="mt-1 w-full"
              style={{
                background: '#000',
                border: '1px solid #000',
                color: '#fff'
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export { LoginPage }
