import React, { useEffect } from 'react'
import { Icon } from '@iconify/react'
import { NavLink } from 'react-router-dom'
import './styles.css'

function Menu() {
	useEffect(() => {
		document.body.classList.add('no-scroll')
		return () => {
			document.body.classList.remove('no-scroll')
		}
	}, [])

	const routes = []
	routes.push({
		to: '/diario-dimagrante',
		text: 'Diario Dimagrante',
		private: false,
		icon: (
			<Icon
				icon="ic:twotone-book"
				width="50"
			/>
		),
	})
	routes.push({
		to: '/menu-e-sostituzioni',
		text: 'Menu e Sostituzioni',
		private: false,
		icon: (
			<Icon
				icon="ic:baseline-restaurant-menu"
				width="50"
			/>
		),
	})
	routes.push({
		to: '/ricette-gourmand',
		text: 'Ricette Gourmand',
		private: false,
		icon: (
			<Icon
				icon="ic:baseline-ramen-dining"
				width="50"
			/>
		),
	})
	routes.push({
		to: '/bilancio',
		text: 'Bilancio',
		private: false,
		icon: (
			<Icon
				icon="ic:baseline-network-check"
				width="50"
			/>
		),
	})
	routes.push({
		to: '/appunti-di-viaggio',
		text: 'Appunti di viaggio',
		private: false,
		icon: (
			<Icon
				icon="ic:baseline-luggage"
				width="50"
			/>
		),
	})
	routes.push({
		to: '/tabella-pro-e-contro',
		text: 'Tabella Pro e Contro',
		private: false,
		icon: (
			<Icon
				icon="ic:outline-theater-comedy"
				width="50"
			/>
		),
	})

	return (
		<>
			<div className="surface-100 text-center fixed top-0 left-0 w-screen h-screen z-4  vertical-align-middle menu-wrapper bg-isometric">
				<div className="grid vertical-align-text-bottom mt-7 sm:mt-0">
					{routes.map((route) => {
						return (
							<div
								key={route.to}
								className={
									'col-6 md:col-4 mb-4 px-5 fadeinleft animation-duration-300'
								}>
								<NavLink to={route.to}>
									<span
										className="p-3 shadow-4 mb-3 inline-block main-menu-button"
										style={{ borderRadius: '10px' }}>
										{route.icon}
									</span>
									<div className="text-900 mb-3 font-medium">{route.text}</div>
								</NavLink>
							</div>
						)
					})}
				</div>
			</div>
		</>
	)
}

export { Menu }
