import React from 'react'
import { notificationReading, getNotificationListUnread } from '../../features/redux/actions'
import { useDispatch, useSelector } from 'react-redux'

import { format, register } from 'timeago.js'
import { Divider } from 'primereact/divider'
import { Button } from 'primereact/button'
import './styles.css'

function itLocale(number, index, totalSec) {
  return [
    ['poco fa', 'fra poco'],
    ['%s secondi fa', 'fra %s secondi'],
    ['un minuto fa', 'fra un minuto'],
    ['%s minuti fa', 'fra %s minuti'],
    ["un'ora fa", "fra un'ora"],
    ['%s ore fa', 'fra %s ore'],
    ['un giorno fa', 'fra un giorno'],
    ['%s giorni fa', 'fra %s giorni'],
    ['una settimana fa', 'fra una settimana'],
    ['%s settimane fa', 'fra %s settimane'],
    ['un mese fa', 'fra un mese'],
    ['%s mesi fa', 'fra %s mesi'],
    ['un anno fa', 'fra un anno'],
    ['%s anni fa', 'fra %s anni']
  ][index]
}

register('itLocale', itLocale)

export default function NotificationsList({ notificationsList }) {
  const notificationsListQuantity = useSelector(state => state.notifications.getNotificationListUnread.quantity)
  const dispatch = useDispatch()
  function readNotification(notification) {
    dispatch(notificationReading(notification))

    if (notificationsList.length <= 3 && notificationsListQuantity >= 4) {
      dispatch(getNotificationListUnread())
    }
  }

  return notificationsList.map((n, index) => (
    <div key={index}>
      <div style={{ scrollSnapAlign: 'start' }} key={n.id} className="justify-content-between border-round flex align-items-center mx-3 my-2">
        <div className="flex justify-content-between flex-column text-overflow-ellipsis overflow-hidden w-9">
          <div className="text-900  text-l font-medium">{n.title}</div>
          <p className="description-notification my-0 text-700 text-m line-height-3" dangerouslySetInnerHTML={{ __html: n.description }} />
          <small style={{ color: '#b1b1b1', fontStyle: 'italic', margin: '10px 0' }}>{format(n.created_at, 'itLocale')}</small>
        </div>
        <Button
          text
          icon="pi text-color pi-times"
          onClick={e => {
            e.stopPropagation()
            readNotification(n)
          }}></Button>
      </div>
      <Divider className="mb-3 mt-0 mx-0" />
    </div>
  ))
}
