import { createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl, headersDefault } from '.'

import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

dayjs.extend(utc)

function orderDietRoutes(dietRoute1, dietRoute2) {
  if (dietRoute1 < dietRoute2) return -1
  if (dietRoute1 > dietRoute2) return 1
  return 0
}
function correctDates([begin, end], index, dietRoutes) {
  dietRoutes[index] = [dayjs(begin).utc(true).toDate().toISOString().slice(0, 10), dayjs(end).utc(true).subtract(1, 'day').toDate().toISOString().slice(0, 10)]
}
function isRouteToDisable(posibleDate, dates) {
  for (let date of dates) {
    if (posibleDate >= date[0] && posibleDate <= date[1]) return false
  }
  return true
}
function getRoutesToDisable(routes) {
  let dateBegin = routes[0][1]
  let dateEnd = routes[routes.length - 1][0]
  let routesToDisable = []
  for (dateBegin; dateBegin < dateEnd; dateBegin = dayjs(dateBegin).utc(true).add(1, 'day').toISOString().slice(0, 10)) {
    if (isRouteToDisable(dateBegin, routes)) routesToDisable.push(dateBegin)
  }
  return routesToDisable
}

export const getDietPlan = createAsyncThunk('dietPlan/getDietPlan', async date => {
  let newDietPlan = [[], [], [], [], [], [], []]
  let dateQuery = ''

  if (date) {
    date = dayjs(date).utc(true).toISOString().slice(0, 10)
    dateQuery = '?' + new URLSearchParams({ date }).toString()
  }

  const response = await fetch(`${baseUrl}/api/master-club-route/subscriber-route` + dateQuery, {
    headers: headersDefault()
  })
  if (response.status !== 200) throw new Error((response.message || (await response.json()).error) ?? 'GenericError')

  const data = await response.json()
  data.master_club_plan.cycles.forEach(food => (newDietPlan[food.day - 1][food.meal_id - 1] = food))

  return { dietPlan: newDietPlan, dates: [dayjs(data.master_club_route.start).utc(true).toISOString(), dayjs(data.master_club_route.end).utc(true).subtract(1, 'day').toISOString()] }
})

export const getDatesToDisable = createAsyncThunk('dietPlan/getDatesToDisable', async () => {
  const response = await fetch(`${baseUrl}/api/master-club-route/subscriber-routes`, {
    headers: headersDefault()
  })

  if (response.status !== 200) throw new Error((response.message || (await response.json()).error) ?? 'GenericError')

  const data = await response.json()
  if (data.length === 0) throw new Error('Empty Array')

  let dietRoutes = data.map(dietRoute => [dietRoute.route.start, dietRoute.route.end])
  //todo let dietRoutes = [["2023-05-22", "2023-05-29"],["2023-05-01", "2023-05-08"],["2023-05-15", "2023-05-22"]]

  dietRoutes.sort(orderDietRoutes)
  dietRoutes.forEach(correctDates)

  let minDate = dietRoutes[0][0]
  let maxDate = dietRoutes[dietRoutes.length - 1][1]

  let routesToDisable = getRoutesToDisable(dietRoutes)

  return { daysLimit: [minDate, maxDate], disableDates: routesToDisable }
})
