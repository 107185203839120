import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Button } from 'primereact/button'
import { Menu } from '../MenuOverlay'
import logo from '../../assets/images/logo-dieta-social.svg'
import { useSelector } from 'react-redux'
import { Badge } from 'primereact/badge'
import FloatingNotifications from './floatingNotifications'
import { StyleClass } from 'primereact/styleclass'
// import { useAuth } from './auth';

function Header() {
  const notificationsListQuantity = useSelector(state => state.notifications.getNotificationListUnread.quantity)

  //   const auth = useAuth();

  // const items = []

  // routes.map(route => {
  //   items.push({
  //     label: route.text,
  //     icon: route.icon,
  //     command: (event) => {
  //         window.location.hash = route.to;
  //     }
  //   });
  // })

  const [openMenu, setOpenMenu] = useState('')
  const showFloatingNotifications = useRef(null)
  let location = useLocation()

  useEffect(() => {
    setOpenMenu('')
  }, [location])

  if (location.pathname === '/' || location.pathname === '/impostazioni-iniziali' || location.pathname.slice(0, 6) === '/login') return

  return (
    <>
      <div className={`flex justify-content-between flex-wrap fixed w-full top-0 bg-white m-0 p-2 shadow-1 ${false ? 'z-5' : 'z-3'}`} id="header">
        <div className=" flex align-items-center justify-content-center">
          <NavLink to="/home">
            <img className="logo" src={logo} alt="logo" />
          </NavLink>
        </div>
        <div className="flex align-items-center justify-content-center ">
          <StyleClass
            nodeRef={showFloatingNotifications}
            selector="@next"
            enterClassName="hidden"
            enterActiveClassName="scalein"
            leaveToClassName="hidden"
            leaveActiveClassName="fadeout"
            hideOnOutsideClick>
            <Button text className="text-color bg-white" ref={showFloatingNotifications}>
              <i className="pi pi-bell p-overlay-badge">
                <Badge
                  style={{ backgroundColor: 'transparent' }}
                  className={`text-white ${notificationsListQuantity > 0 ? 'bg-primary' : ''}`}
                  value={notificationsListQuantity > 0 ? notificationsListQuantity : null}
                />
              </i>
            </Button>
          </StyleClass>
          <FloatingNotifications />
          <NavLink className="text-color p-2" to="/utente/profilo">
            <i className="pi pi-user"></i>
          </NavLink>

          <Button
            type="button"
            icon="pi pi-bars"
            className="bg-white text-color border-white"
            label=""
            onClick={() => {
              setOpenMenu(<Menu />)
            }}></Button>
        </div>
      </div>
      {openMenu}
    </>
  )
}

export { Header }
