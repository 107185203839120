import React from 'react'
import notFoundImg from '../../assets/images/notificationsReminder.png'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'primereact/card'
import { Button } from 'primereact/button'
import { PUBLIC_KEY } from '../../serviceWorkerRegistration'
import { updateActiveNotifications } from '../redux/reducer/NotificationsSlice'
import { postNotificationsSubscription } from '../redux/actions'

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}
function isUsingDietaSocial() {
  if ('serviceWorker' in navigator && 'caches' in window) {
    if (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true) {
      return true
    }
  }

  return false
}
function isMobileDevice() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
}
function AceptNotifications() {
  const dispatch = useDispatch()
  const activeNotifications = useSelector(state => state.notifications.activeNotifications)
  return (
    <div className="page-wapper fixed surface-100 z-5 top-0 left-0 w-screen h-screen">
      <div className="col-12">
        <Card className="m-3 fadeinleft animation-duration-500 text-center">
          <img src={notFoundImg} alt="Page not found" className="max-w-17rem" />
          <h1>Per cotinuare attiva le notifiche</h1>
          {activeNotifications === 'prompt' && (
            <Button
              label="Attiva"
              onClick={() => {
                navigator.serviceWorker.getRegistration().then(registration => {
                  registration.pushManager
                    .subscribe({
                      userVisibleOnly: true,
                      applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY)
                    })
                    .catch(() =>
                      registration.pushManager
                        .permissionState({
                          userVisibleOnly: true,
                          applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY)
                        })
                        .then(state => dispatch(updateActiveNotifications(state)))
                    )
                    .then(newSubscription => {
                      if (newSubscription) {
                        registration.pushManager
                          .permissionState({
                            userVisibleOnly: true,
                            applicationServerKey: urlBase64ToUint8Array(PUBLIC_KEY)
                          })
                          .then(state => {
                            dispatch(updateActiveNotifications(state))
                            dispatch(postNotificationsSubscription(newSubscription))
                          })
                      }
                    })
                })
              }}
            />
          )}
        </Card>
      </div>
    </div>
  )
}

function ActiveNotifications() {
  return (
    <div className="page-wapper fixed surface-100 z-5 top-0 left-0 w-screen h-screen">
      <div className="col-12">
        <Card className="m-3 fadeinleft animation-duration-500 text-center">
          <h1>Ups</h1>
          <img src={notFoundImg} alt="Page not found" className="max-w-17rem" />
          <h1>Per cotinuare attiva le notifiche</h1>
        </Card>
      </div>
    </div>
  )
}
function NotificationCheck() {
  const activeNotifications = useSelector(state => state.notifications.activeNotifications)
  if (activeNotifications === 'prompt' && (isUsingDietaSocial() || !isMobileDevice())) return <AceptNotifications />
  if (activeNotifications === 'denied' && isUsingDietaSocial()) return <ActiveNotifications />
}

export { NotificationCheck }
