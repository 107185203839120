import React, { useState } from 'react'
import { classNames } from 'primereact/utils'
import { RadioButton } from 'primereact/radiobutton'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'

const options = ['Mai', 'Ogni tanto', 'sempre a colazione', 'sempre a pranzo', 'sempre a cena', 'solo nei weekend']
const initialStateError = { status: false, type: null }

function isAValidInput(eatingOut, setError) {
  if (eatingOut === '' || eatingOut === null || eatingOut === undefined) {
    setError({ status: true, type: "Devi selezionare un'opzione per continuare" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  if (!options.includes(eatingOut)) {
    setError({ status: true, type: "l'opzione selezionata non è valida" })
    setTimeout(() => {
      setError(initialStateError)
    }, 2000)
    return false
  }

  return true
}
function nextPage({ setStep, eatingOut, setError }) {
  if (isAValidInput(eatingOut, setError)) {
    setStep('MotivationPage')
  }
}

export default function EatingOutPage({ setStep, eatingOut, setData }) {
  const [error, setError] = useState(initialStateError)
  const inputClass = classNames({ 'p-invalid': error.status })
  const pageClass = classNames('flex-1 flex-column min-h-full flex')
  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage="CookingPreferencesPage" />
      <div className=" text-4xl my-8 text-center w-9 mx-auto">Mangi spesso fuori?</div>
      {options.map((option, index) => (
        <div key={`${option + index}`} className="my-2 flex align-items-center ml-6 text-2xl">
          <RadioButton
            className={inputClass}
            inputId={`input${option}`}
            name={option}
            value={option}
            onChange={e =>
              setData(data => ({
                ...data,
                extras: { ...data.extras, eating_out: e.value }
              }))
            }
            checked={error.status ? false : option === eatingOut}
          />
          <label htmlFor={`input${option}`} className="ml-2 capitalizeFirstLetter">
            {option}
          </label>
        </div>
      ))}
      {error.status && <label className="mx-auto text-center mt-2">{error.type}</label>}
      <NextButton onClickFunction={nextPage} onCLickData={{ setStep, eatingOut, setError }} />
    </div>
  )
}
