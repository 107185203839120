import React from 'react'

import Measure from './measure'
import './styles.css'

const measures = [
  {
    title: 'Peso corporeo',
    measure: 'kg',
    icon: 'healthicons:weight',
    color: 'indigo',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 surface-border',
    state_name: 'weight'
  },
  {
    title: 'Circonferenza collo',
    measure: 'cm',
    icon: 'game-icons:necklace-display',
    color: 'pink',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 surface-border',
    state_name: 'neck_circumference'
  },
  {
    title: 'Circonferenza ombellicale',
    measure: 'cm',
    icon: 'mingcute:body-line',
    color: 'indigo',
    measuresDiaryClass: 'col-12 md:col-4 border-bottom-1 surface-border',
    state_name: 'umbilical_circumference'
  },
  {
    title: 'Circonferenza fianchi',
    measure: 'cm',
    icon: 'mingcute:body-fill',
    color: 'pink',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 md:border-bottom-none surface-border',
    state_name: 'hips_circumference'
  },
  {
    title: 'Circonferenza coscia',
    measure: 'cm',
    icon: 'game-icons:leg',
    color: 'indigo',
    measuresDiaryClass: 'col-12 md:col-4 md:border-right-1 border-bottom-1 md:border-bottom-none surface-border',
    state_name: 'middle_thigh_circumference'
  },
  {
    title: 'Circonferenza braccio',
    measure: 'cm',
    icon: 'healthicons:arm',
    color: 'pink',
    measuresDiaryClass: 'col-12 md:col-4',
    state_name: 'arm_circumference'
  }
]

function Measures({ date, setMeasuresData, measuresData }) {
  return measures.map((measure, index) => {
    return <Measure key={index} date={date} measure={measure} setMeasuresData={setMeasuresData} measuresData={measuresData} />
  })
}

export default Measures
