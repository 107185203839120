import React, { useEffect, useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import { Calendar } from 'primereact/calendar'
import config from '../../../../config'

import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'

dayjs.extend(utc)
let today = dayjs().utc(true).toISOString().slice(0, 10)

function deleteOldRouteStart(freeRouteStart, data, setData) {
  if (freeRouteStart === undefined) {
    let _data = data
    delete _data.free_route_start
    setData(_data)
  }
}
function orderDietRoutes(dietRoute1, dietRoute2) {
  if (dietRoute1 < dietRoute2) return -1
  if (dietRoute1 > dietRoute2) return 1
  return 0
}
function correctEndDate([begin, end], index, dietRoutes) {
  dietRoutes[index] = [begin, dayjs(end).utc(true).subtract(1, 'day').toDate().toISOString().slice(0, 10)]
}
function isRouteToDisable(posibleDate, dates) {
  for (let date of dates) {
    if (posibleDate >= date[0] && posibleDate <= date[1]) return false
  }
  return true
}
function getRoutesToDisable(routes) {
  let dateBegin = routes[0][1]
  let dateEnd = routes[routes.length - 1][0]
  let routesToDisable = []
  for (dateBegin; dateBegin < dateEnd; dateBegin = dayjs(dateBegin).utc(true).add(1, 'day').toISOString().slice(0, 10)) {
    if (isRouteToDisable(dateBegin, routes)) routesToDisable.push(new Date(dateBegin))
  }
  return routesToDisable
}
async function getPossibleRoutes(keep_weight, subscription, vegetarian, gluten_free, setDaysLimit, setDisableDates) {
  let params = {
    keep_weight: subscription ? (keep_weight ? 1 : 0) : 0,
    vegetarian: vegetarian ? 1 : 0,
    gluten_free: gluten_free ? 1 : 0
  }
  params = '?' + new URLSearchParams(params).toString()

  let response = await fetch(config.env[config.env.mode].url + '/api/master-club-route/subscriber-routes' + params, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('adoc_t')}`
    }
  })
  response = await response.json()

  if (response.length === 0 || response.message === 'Unauthenticated.') return

  let dietRoutes = response.map(dietRoute => [dietRoute.route.start, dietRoute.route.end])
  //todo let dietRoutes = [["2023-05-22", "2023-05-29"],["2023-05-01", "2023-05-08"],["2023-05-15", "2023-05-22"]]
  dietRoutes.sort(orderDietRoutes)
  dietRoutes.forEach(correctEndDate)

  let minDate = today < dietRoutes[0][0] ? dietRoutes[0][0] : today
  let maxDate = dietRoutes[dietRoutes.length - 1][1]

  setDaysLimit([minDate, maxDate])

  let routesToDisable = getRoutesToDisable(dietRoutes)
  setDisableDates(routesToDisable)
}
function isAValidInput(disableDates, daysLimit, date) {
  let disabledDatesString = disableDates.map(date => dayjs(date).utc(true).toISOString().slice(0, 10))
  return date !== null && date !== '' && !disabledDatesString.includes(date) && date >= daysLimit[0] && date <= daysLimit[1]
}
function nextPage({ setStep, setData, disableDates, daysLimit, freeRouteStart, setError }) {
  if (!isAValidInput(disableDates, daysLimit, freeRouteStart)) {
    setError(true)
    setTimeout(() => {
      setError(null)
    }, 2000)
    return
  }
  setData(data => {
    return { ...data, free_route_start: freeRouteStart }
  })
  setStep('PhonePage')
}

export default function SelectRoutePage({ setStep, data, setData }) {
  const [daysLimit, setDaysLimit] = useState([today, today])
  const [freeRouteStart, setFreeRouteStart] = useState()
  const [disableDates, setDisableDates] = useState([new Date(today)])
  const [error, setError] = useState(null)

  let pageClass = classNames('flex-1 flex-column min-h-full flex')
  let inputClass = classNames('w-9 mx-auto', { 'p-invalid': error })

  useEffect(() => {
    deleteOldRouteStart(freeRouteStart, data, setData)
  }, [freeRouteStart, data, setData])

  useEffect(() => {
    getPossibleRoutes(data.keep_weight, data.subscription, data.vegetarian, data.gluten_free, setDaysLimit, setDisableDates)
  }, [data.keep_weight, data.subscription, data.vegetarian, data.gluten_free])

  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'FreeOrPaidPage'} />
      <div className=" text-4xl my-8 text-center w-9 mx-auto">Quando vorresti iniziare la tua dieta? Ti consigliamo di scegliere un lunedì, ma puoi scegliere il giorno che desideri {':)'}</div>
      <Calendar
        className={inputClass}
        minDate={new Date(daysLimit[0])}
        maxDate={new Date(daysLimit[1])}
        value={new Date(freeRouteStart)}
        disabledDates={disableDates}
        onChange={e => {
          if (e.value === null) return
          setFreeRouteStart(dayjs(e.value).utc(true).toISOString().slice(0, 10))
        }}
        id="percorso"
        dateFormat="dd/mm/yy"
        showIcon
      />
      <NextButton
        onClickFunction={nextPage}
        onCLickData={{
          setStep,
          setData,
          disableDates,
          daysLimit,
          freeRouteStart,
          setError
        }}
      />
    </div>
  )
}
