import React, { useState } from 'react'
import BackButton from '../buttons/BackButton'
import NextButton from '../buttons/NextButton'
import { classNames } from 'primereact/utils'
import avatars from '../../../../assets/images/avatars'

const initialStateError = { type: null, message: null }

function isAValidInput(image, setError) {
  if (image === undefined || image === null || image === '') {
    setError({ type: 'imageEmpty', message: 'devi selezionare un avatar' })
    return false
  }
  let [avatar, number] = image.split('-')
  number = +number
  if (avatar !== 'avatar' || number < 1 || number > 16) {
    setError({ type: 'imageEmpty', message: 'devi selezionare un avatar valido' })
    return false
  }
  setError(initialStateError)
  return true
}

async function setPage({ setStep, avatarSelected, setError, setData }) {
  if (isAValidInput(avatarSelected, setError)) {
    setData(data => {
      return { ...data, avatar: `${avatarSelected}.jpg` }
    })
    setStep('NickNamePage')
  }
}

export default function SelectAAvatarPage({ setStep, setData }) {
  const [avatarSelected, setAvatarSelected] = useState(null)
  const [error, setError] = useState(initialStateError)
  let pageClass = classNames('flex-1 flex-column min-h-full flex')

  return (
    <div className={pageClass}>
      <BackButton setStep={setStep} prevPage={'WantToInstertAPicturePage'} />
      <div className=" text-4xl mt-8 mb-1 w-9 mx-auto text-center">Inserisci la tua foto</div>
      <div className=" text-xl mb-6 mt-1 w-9 mx-auto text-center">Deciderai in un secondo momento se la vorrai rendere visibile agli altri</div>
      {error.type && <label className="mx-auto text-center mt-3">{error.message}</label>}
      <section className="grid w-11 mx-auto">
        {avatars?.map(avatar => {
          let styles = { width: '100px', height: '100px', objectFit: 'cover', borderRadius: '100%', cursor: 'pointer' }
          let name = avatar.slice(14, 23)
          if (name === avatarSelected) styles = { ...styles, borderColor: 'var(--pink-500)', borderWidth: '5px', borderStyle: 'solid' }
          return <img key={name} alt={name} src={avatar} className="mx-auto mt-3 mb-2" style={styles} onClick={() => setAvatarSelected(name)} />
        })}
      </section>
      <NextButton onClickFunction={setPage} onCLickData={{ avatarSelected, setStep, setError, setData }} />
    </div>
  )
}
