import React from 'react'

import { TabPanel, TabView } from 'primereact/tabview'
import { Overlay } from '../../components/Overlay'
import { Icon } from '@iconify/react'

import BalanceWellnessIndex from './BalanceWellnessIndex'
import BalancePanel from './BalancePanel'

export default function BalanceOverlay({ fromTo, setOverlayOpened, balanceData, wellnessData }) {
  return (
    <Overlay title={fromTo} setOverlayOpened={setOverlayOpened}>
      <TabView>
        <TabPanel
          header={
            <>
              <Icon
                icon="ic:baseline-network-check"
                width="25"
                style={{
                  margin: '0 3px -6px 0'
                }}
              />
              Bilancio
            </>
          }>
          <BalancePanel balanceData={balanceData} />
        </TabPanel>
        <TabPanel
          header={
            <>
              <Icon
                icon="ri:mental-health-fill"
                width="25"
                style={{
                  margin: '0 3px -6px 0'
                }}
              />
              Indice di benessere
            </>
          }>
          <BalanceWellnessIndex wellnessData={wellnessData} />
        </TabPanel>
      </TabView>
    </Overlay>
  )
}
