import React, { useRef, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import config from '../../../config'

let initialStateErrors = { surname: false, name: false, email: false, telephone: false }
function setUserInitialState() {
  let adoc_p = JSON.parse(localStorage.getItem('adoc_p'))
  return {
    name: adoc_p.user.name ?? '',
    surname: adoc_p.user.surname ?? '',
    email: adoc_p.user.email ?? '',
    telephone: adoc_p.user.telephone ?? ''
  }
}
const checkData = user => {
  let newErrors = initialStateErrors
  if (!/^(([a-zA-Z]|[À-ÖØ-öø-ÿ Ÿœ])+){3,30}$/.test(user.name)) newErrors = { ...newErrors, name: true }
  if (!/^(([a-zA-Z]|[À-ÖØ-öø-ÿ Ÿœ])+){3,30}$/.test(user.surname)) newErrors = { ...newErrors, surname: true }
  return newErrors
}
async function sendUser(user, showSuccess, showError, toast) {
  try {
    let adoc_t = localStorage.getItem('adoc_t')
    let response = await fetch(`${config.env[config.env.mode].url}/api/profilo-utenti`, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${adoc_t}`
      }
    })
    response = await response.json()
    if (response.message !== 'Succefully updated') throw new Error(response.message)
    showSuccess(toast)
    let adoc_p = JSON.parse(localStorage.getItem('adoc_p'))
    adoc_p.user = { ...adoc_p.user, ...user }
    localStorage.setItem('adoc_p', JSON.stringify(adoc_p))
  } catch {
    showError(toast)
  }
}
function updateProfile(user, setErrors, showSuccess, showError, toast) {
  let newErrors = checkData(user)
  if (Object.values(newErrors).includes(true)) {
    setErrors(newErrors)
    setTimeout(() => {
      setErrors(initialStateErrors)
    }, 2000)
  } else {
    sendUser(user, showSuccess, showError, toast)
  }
}

const showSuccess = t => {
  t.current.show({
    severity: 'success',
    summary: 'Informazioni salvate',
    detail: 'Ricordati di salvare le misure speso',
    life: 3000
  })
}
const showError = t => {
  t.current.show({
    severity: 'error',
    summary: 'Informazioni non salvate',
    detail: 'Abbiamo riscontrato un errore. Riprova più tardi',
    life: 3000
  })
}

export default function ProfileUserForm() {
  const [errors, setErrors] = useState(initialStateErrors)
  const [user, setUser] = useState(setUserInitialState())
  const toast = useRef(null)

  return (
    <div className="flex-auto p-fluid">
      <Toast ref={toast} />
      <div className="mb-4">
        <label htmlFor="name" className="block font-medium text-900 mb-2">
          Nome
        </label>
        <InputText className={`${errors.name ? 'p-invalid' : ''}`} id="name" type="text" value={user.name} onChange={e => setUser({ ...user, name: e.target.value })} />
      </div>
      <div className="mb-4">
        <label htmlFor="surname" className="block font-medium text-900 mb-2">
          Cognome
        </label>
        <InputText className={`${errors.surname ? 'p-invalid' : ''}`} id="surname" type="text" value={user.surname} onChange={e => setUser({ ...user, surname: e.target.value })} />
      </div>
      <div className="mb-4">
        <label htmlFor="email" className="block font-medium text-900 mb-2">
          E-Mail
        </label>
        <InputText disabled id="email" type="email" value={user.email} />
      </div>
      <div className="mb-4">
        <label htmlFor="telephone" className="block font-medium text-900 mb-2">
          Telefono
        </label>
        <InputText disabled id="telephone" value={` ${user.telephone}`} />
      </div>
      <div>
        <Button label="Aggiorna Profilo" className="p-ripple w-auto" onClick={() => updateProfile(user, setErrors, showSuccess, showError, toast)} />
      </div>
    </div>
  )
}
